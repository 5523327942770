import * as React from 'react';
import { SvgIcon } from '@mui/material';

export default function LightningIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <g
                id="icon-/-error-/-como-/-lighting"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path
                    d="M17.1982998,11.3857778 C17.3708639,11.1052517 17.3782532,10.7533435 17.2176168,10.465821 C17.0569804,10.1782984 16.7534303,10.0001094 16.4240775,10 L13.3334109,10 L13.3334109,2 L6.80185531,12.6151111 C6.62914174,12.8955015 6.62169463,13.2474052 6.78239142,13.5348513 C6.94308821,13.8222975 7.24676206,14.000269 7.57607753,14 L10.6667442,14 L10.6667442,22 L17.1982998,11.3857778 Z"
                    id="Path-Copy-2"
                    stroke="currentColor"
                    strokeWidth="1.25"
                ></path>
            </g>
        </SvgIcon>
    );
}
