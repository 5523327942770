import * as React from 'react';
import { SvgIcon } from '@mui/material';

export default function DisabledIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <g
                id="icon-/-error-/-como-/-disable"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <path
                    d="M18.617494,5.36746857 C18.612494,5.36163524 18.6116607,5.35496857 18.6058273,5.34913524 C18.599994,5.3433019 18.5933273,5.34246857 18.5883273,5.33746857 C14.9234041,1.72150337 9.02753866,1.73984244 5.385181,5.37853691 C1.74282333,9.01723139 1.7185517,14.9130754 5.33082733,18.5816352 C5.33832733,18.5891352 5.34082733,18.5999686 5.34832733,18.6074686 C5.35582733,18.6149686 5.36582733,18.6166352 5.37416066,18.6241352 C9.03527962,22.2788903 14.9656328,22.2748191 18.6217303,18.6150407 C22.2778278,14.9552623 22.2759314,9.02490794 18.617494,5.36746857 Z M11.977494,5.72828938 C13.092194,5.72906498 14.1860937,6.03017528 15.1441607,6.59996857 L6.59999399,15.1449686 C5.45623689,13.2153253 5.4368508,10.8201034 6.54922441,8.87219985 C7.66159802,6.92429627 9.73435261,5.7238163 11.977494,5.72828938 L11.977494,5.72828938 Z M11.977494,18.2283106 C10.8627498,18.2270403 9.76890872,17.9256583 8.81082733,17.3558019 L17.3558273,8.81163524 C18.4985258,10.7415579 18.5172662,13.1363519 17.404911,15.0839214 C16.2925557,17.031491 14.2203376,18.232033 11.977494,18.2283106 L11.977494,18.2283106 Z"
                    id="Shape"
                    stroke="currentColor"
                    strokeWidth="1.25"
                />
            </g>
        </SvgIcon>
    );
}
