import { Box, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import Footer from '../../components/layout/Footer';
import Seo from '../../components/layout/Seo';
import SideBanner from './SideBanner';
import FormContact from './forms/FormContact';
import FormLogin from './forms/FormLogin';
import Alerts from '../app/components/layout/Alerts';
import { AuthInfoDTO } from '../app/dto/auth.dto';
import { useQuery } from '../app/utils/api';
import Spinner from '../app/common/Spinner';
import FetchError from '../app/common/FetchError';

export default function LoginPage() {
    const [form, setForm] = useState(0);
    const authInfoDTO = useMemo(() => new AuthInfoDTO(), []);
    const { data: authInfo, error, isLoading } = useQuery(authInfoDTO);

    if (isLoading) return <Spinner />;
    if (error) return <FetchError message={error.message} />;

    const isSplunkAdminMode = authInfo.auth_mode === 'splunk';
    const pageTitle = authInfo.auth_mode === 'splunk' ? 'Interne Anmeldung' : 'Anmeldung';

    return (
        <>
            <Alerts />
            <Seo title="Login" />
            <Box
                sx={{
                    height: '100vH',
                    flexGrow: 1,
                    display: 'flex',
                    flexWrap: { xs: 'wrap', md: 'no-wrap' },
                }}
            >
                <Box sx={{ flexBasis: { xs: '100%', md: '60%', lg: '70%' } }}>
                    <SideBanner />
                </Box>

                <Box
                    sx={{
                        flexBasis: { xs: '100%', md: '40%', lg: '30%' },
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            p: 8,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ width: '100%' }}>
                            <Typography
                                variant="zep-typo--display-4"
                                mb={2}
                                component="p"
                                textAlign="center"
                            >
                                {form === 0 ? pageTitle : 'Registrieren'}
                            </Typography>

                            {isSplunkAdminMode ? (
                                <Typography
                                    variant="zep-typo--body-default"
                                    mb={4}
                                    component="p"
                                    textAlign="center"
                                    sx={{ color: (theme) => theme.color.black[60] }}
                                >
                                    Geben Sie zur Anmeldung Ihr Zeppelin Kürzel und Windows Passwort
                                    ein
                                </Typography>
                            ) : (
                                <Typography
                                    variant="zep-typo--body-default"
                                    mb={4}
                                    component="p"
                                    textAlign="center"
                                    sx={{ color: (theme) => theme.color.black[60] }}
                                >
                                    {form === 0
                                        ? 'Geben Sie zur Anmeldung Ihre Zeppelin Kundenportal Log-In Daten ein'
                                        : 'Geben Sie unten Ihre Daten ein'}
                                </Typography>
                            )}

                            {form === 0 ? (
                                <FormLogin
                                    authInfo={authInfo}
                                    handleFormChange={() => setForm(1)}
                                />
                            ) : (
                                <FormContact handleFormChange={() => setForm(0)} />
                            )}
                        </Box>
                    </Box>
                    <Footer />
                </Box>
            </Box>
        </>
    );
}
