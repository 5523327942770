import * as React from 'react';
import { Container, Typography } from '@mui/material';
import ReactMarkdown from 'markdown-to-jsx';
import rechtlicheHinweise from './rechtlicheHinweise.md';

export default function TermsConditions() {
    const [mdText, setMdText] = React.useState('');

    React.useEffect(() => {
        fetch(rechtlicheHinweise)
            .then((response) => {
                if (response.ok) {
                    return response.text();
                }
                return Promise.reject(new Error("Didn't fetch text correctly"));
            })
            .then((text) => {
                setMdText(text);
            })
            .catch((error) => console.error(error));
    });

    return (
        <Container maxWidth="xl" sx={{ pt: 2, pb: 4, minHeight: 'calc(100vH - 92px)' }}>
            <Typography
                variant="zep-typo--display-6"
                component="p"
                sx={{ mb: 2, display: { xs: 'block', sm: 'none' } }}
            >
                Rechtliche Hinweise
            </Typography>
            <ReactMarkdown>{mdText}</ReactMarkdown>
        </Container>
    );
}
