import * as React from 'react';
import PropTypes from 'prop-types';
import { Popover, Typography } from '@mui/material';

import ErrorIcon from '../icons/Error';
import InfoIcon from '../icons/Info';
import prioData from '../../utils/prioData';
import UnknownIcon from '../icons/Unknown';
import WarningIcon from '../icons/Warning';

const icons = {
    0: (
        <UnknownIcon
            sx={{
                color: (theme) => theme.other.prio0.main,
            }}
        />
    ),
    1: (
        <ErrorIcon
            sx={{
                color: (theme) => theme.other.prio1.main,
            }}
        />
    ),
    2: (
        <WarningIcon
            sx={{
                color: (theme) => theme.other.prio2.main,
            }}
        />
    ),
    3: (
        <InfoIcon
            sx={{
                color: (theme) => theme.other.prio3.main,
            }}
        />
    ),
};

export default function TableHeaderIcon({ variant }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Typography
                aria-owns={anchorEl ? `mouse-over-popover-${variant}` : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{ display: 'flex', alignItems: 'center' }}
            >
                {icons[variant]}
            </Typography>
            <Popover
                id={`mouse-over-popover-${variant}`}
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography
                    sx={{
                        p: 1,
                        color: (theme) => theme.color.global.white,
                        background: (theme) => theme.color.global.almostBlack,
                    }}
                >
                    {prioData[variant].label}
                </Typography>
            </Popover>
        </>
    );
}

TableHeaderIcon.propTypes = {
    variant: PropTypes.oneOf([1, 2, 3, 0]).isRequired,
};
