import 'regenerator-runtime/runtime';
import ComoRoutes from './ComoRoutes';
import { AuthContextProvider } from './contexts/AuthContext';
import { CustomerSelectContextProvider } from './contexts/CustomerSelectContext';
import { LayoutContextProvider } from './contexts/LayoutContext';
import { BranchListContextProvider } from './contexts/BranchListContext';

export default function ComoApp() {
    return (
        <AuthContextProvider>
            <CustomerSelectContextProvider>
                <LayoutContextProvider>
                    <BranchListContextProvider>
                        <ComoRoutes />
                    </BranchListContextProvider>
                </LayoutContextProvider>
            </CustomerSelectContextProvider>
        </AuthContextProvider>
    );
}
