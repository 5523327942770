import React from 'react';
import { Box, IconButton, Typography, Button, useMediaQuery } from '@mui/material';
import LeftIcon from '@zlab-de/zel-react-icons/ArrowChevronLeft';
import RightIcon from '@zlab-de/zel-react-icons/ArrowChevronRight';

function SideBanner() {
    const [value, setValue] = React.useState(0);
    const breakpointSm = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const breakpointMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const breakpointLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    const slides = [
        {
            title: 'Die Digitale Plattform für Ihre Flotte',
            text: 'Maschinenüberwachung und Instandhaltung so einfach und komfortabel wie noch nie!',
            link: null,
            img: '/banner1.png',
        },
        {
            title: 'kundenportal',
            text: 'COMO steht allen Nutzern im Zeppelin Kundenportal, der Online-Plattform für die Bestellung von Ersatz- und Verschleißteilen, zur Verfügung.',
            link: 'https://kundenportal.zeppelin.com/',
            img: '/banner2.png',
        },
    ];

    const showImage = !breakpointSm ? true : !!(breakpointMd && !breakpointLg);

    return (
        <Box sx={{ background: 'linear-gradient(180deg, #B9B8B8 0%, #F0F0F1 30%, #FFFFFF 100%)' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    backgroundSize: { xs: 0, sm: '60%', md: 0, lg: '65%' },
                    backgroundImage: `url(${slides[value].img})`,
                    backgroundPosition: '100% 60%',
                    backgroundRepeat: 'no-repeat',
                    height: { xs: '100%', sm: '70vH', md: '100vH' },
                    pt: { xs: 4, lg: 0 },
                }}
            >
                <Box
                    sx={{
                        flexBasis: '20%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            flexBasis: { xs: '100%', sm: '30%', md: '80%', lg: '30%' },
                            textAlign: 'center',
                        }}
                    >
                        <Box
                            component="img"
                            src="/logo.svg"
                            sx={{ width: { xs: 200, sm: 250 } }}
                        />
                    </Box>
                    <Box sx={{ flexBasis: { xs: '0%', sm: '50%', md: '0%', lg: '50%' } }} />
                </Box>
                <Box
                    sx={{
                        flexBasis: '40%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Box sx={{ flexBasis: { xs: '100%', sm: '30%', md: '80%', lg: '30%' } }}>
                        {showImage && (
                            <Box sx={{ width: '100%', mb: 2, textAlign: 'center' }}>
                                <Box
                                    component="img"
                                    src={slides[value].img}
                                    sx={{ width: '80%' }}
                                />
                            </Box>
                        )}
                        <Typography
                            sx={{
                                textTransform: 'uppercase',
                                mb: 3,
                                fontWeight: 700,
                                fontSize: { xs: 16, md: 28 },
                                textAlign: 'center',
                            }}
                        >
                            {slides[value].title}
                        </Typography>
                        <Typography textAlign="center">{slides[value].text}</Typography>
                        {slides[value].link && (
                            <Button
                                fullWidth
                                variant="contained"
                                href={slides[value].link}
                                sx={{ my: 4 }}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Mehr Infos
                            </Button>
                        )}
                    </Box>
                    <Box
                        sx={{
                            flexBasis: { xs: '0%', sm: '50%', md: '0%', lg: '50%' },
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    />
                </Box>
                <Box sx={{ px: 4, py: { xs: 2, sm: 8 } }}>
                    <IconButton
                        aria-label="früher"
                        onClick={() => setValue(0)}
                        disabled={value === 0}
                        sx={{
                            width: 50,
                            height: 50,
                            mr: 6,
                            border: (theme) => `2px solid ${theme.color.global.lightGray}`,
                        }}
                    >
                        <LeftIcon
                            sx={{
                                color: (theme) => theme.color.global.lightGray,
                                width: 35,
                                height: 35,
                            }}
                        />
                    </IconButton>
                    <IconButton
                        aria-label="nächste"
                        onClick={() => setValue(1)}
                        disabled={value === 1}
                        sx={{
                            width: 50,
                            height: 50,
                            border: (theme) => `2px solid ${theme.color.global.lightGray}`,
                        }}
                    >
                        <RightIcon
                            sx={{
                                color: (theme) => theme.color.global.lightGray,
                                width: 35,
                                height: 35,
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
}

export default SideBanner;
