import * as React from 'react';
import { useLoadScript } from '@react-google-maps/api';
import Map from './Map';

function MapLoader({ ...props }) {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyB_yU804NtXBkZjYm8OPSio2hLG4Qda3VE',
    });

    return <>{isLoaded && <Map {...props} />}</>;
}

export default MapLoader;
