import { errorMessages } from '../constants/error';
import { DTO } from './base.dto';

export class MachineListDTO extends DTO {
    constructor(customerId) {
        super({
            apiRoute: `api/v1/customers/machines/${encodeURIComponent(customerId)}`,
            fetchErrorMessage: errorMessages.fetchMachines,
        });
    }
}

export class MachineDetailDTO extends DTO {
    constructor(serialNumber) {
        super({
            apiRoute: `api/v1/events/${encodeURIComponent(serialNumber)}`,
            fetchErrorMessage: errorMessages.fetchSelectedMachine,
        });
    }
}

export class MachineHistoryEventListDTO extends DTO {
    constructor(serialNumber) {
        super({
            apiRoute: `api/v1/events/history/${encodeURIComponent(serialNumber)}`,
            fetchErrorMessage: errorMessages.fetchSelectedMachineHistory,
        });
    }
}

export class MachineOilReportListDTO extends DTO {
    constructor(serialNumber) {
        super({
            apiRoute: `api/v1/machines/oil/${encodeURIComponent(serialNumber)}`,
            fetchErrorMessage: errorMessages.fetchSelectedMachineOilReports,
        });
    }
}
