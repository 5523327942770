import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, IconButton, Typography } from '@mui/material';
import EditIcon from '@zlab-de/zel-react-icons/EssentialEdit';
import { useState } from 'react';
import { useEventCodeList } from '../../contexts/EventCodeListContext';
import { useAlert } from '../../contexts/AlertContext';
import { ErrorUpdateDTO } from '../../dto/error.dto';
import { useMutation } from '../../utils/api';
import errorCodeData from '../../utils/errorCodeData';
import PrioRecommendation from '../prio/PrioRecommendation';
import PrioTitle from '../prio/PrioTitle';
import EditorPrio from './EditorPrio';
import EditorText from './EditorText';

export default function EventCodeEditor() {
    const { openAlert } = useAlert();
    const [saveError, { isLoading }] = useMutation();
    const { refetch, selectedCode, setSelectedCode } = useEventCodeList();
    const [code, setCode] = useState(selectedCode);
    const [isEditing, setIsEditing] = useState(false);

    const data = ['possible_causes', 'possible_consequences', 'recommendations'];

    const handleChange = (name, value) => {
        setCode({ ...code, [name]: value });
    };

    const handleSave = async () => {
        await saveError(new ErrorUpdateDTO(code), {
            onSuccess: () => {
                openAlert({
                    message: 'Der Fehlercode wurde erfolgreich gespeichert',
                    severity: 'success',
                });
                setSelectedCode(null);
                refetch();
            },
            onError: (error) => {
                openAlert({
                    message: error.message,
                    severity: 'error',
                });
            },
        });
    };

    const handleCancel = () => {
        setIsEditing(false);
        setCode(selectedCode);
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: '4px 4px 0px 0px',
                    background: (theme) => theme.color.global.almostWhite,
                    height: 40,
                }}
            >
                <Typography variant="zep-typo--display-6" sx={{ pl: 2 }}>
                    {code.id}
                </Typography>
                {!isEditing && (
                    <IconButton aria-label="edit" onClick={() => setIsEditing(true)}>
                        <EditIcon />
                    </IconButton>
                )}
            </Box>
            <Box sx={{ p: 4, background: (theme) => theme.color.global.white, mb: 6 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="zep-typo--display-5">
                        {code.formattedDescription}
                    </Typography>
                    {isEditing ? (
                        <EditorPrio handleChange={handleChange} prio={code.prio} />
                    ) : (
                        <PrioTitle prio={code.prio} />
                    )}
                </Box>
                {data.map((elem) => (
                    <Box sx={{ mb: 2, px: 2 }} key={elem}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            {errorCodeData[elem].icon}
                            <Typography variant="zep-typo--display-6" component="p" sx={{ ml: 2 }}>
                                {errorCodeData[elem].label}
                            </Typography>
                        </Box>
                        {isEditing ? (
                            <EditorText handleChange={handleChange} text={code[elem]} name={elem} />
                        ) : (
                            <Typography
                                variant="zep-typo--body-default"
                                component="p"
                                sx={{ ml: 5 }}
                            >
                                {code[elem]}
                            </Typography>
                        )}
                    </Box>
                ))}
                <PrioRecommendation prio={code.prio} />
            </Box>
            {isEditing && (
                <Box>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleSave}
                        loading={isLoading}
                        sx={{ mr: 2 }}
                    >
                        Speichern
                    </LoadingButton>
                    <Button variant="outlined" color="primary" size="large" onClick={handleCancel}>
                        Abbrechen
                    </Button>
                </Box>
            )}
        </>
    );
}
