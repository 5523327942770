import { errorMessages } from '../constants/error';
import { DTO } from './base.dto';


export class MaintenanceCreateDTO extends DTO {
    constructor(userId, body) {
        super({
            apiRoute: `api/v1/maintenance/${encodeURIComponent(userId)}`,
            fetchErrorMessage: errorMessages.createMaintenance,
            fetchInit: {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            },
        });
    }
}