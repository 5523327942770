import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Link } from 'react-scroll';

import ErrorIcon from '../icons/Error';
import InfoIcon from '../icons/Info';
import UnknownIcon from '../icons/Unknown';
import WarningIcon from '../icons/Warning';

const icons = {
    0: (
        <UnknownIcon
            sx={{
                color: (theme) => theme.other.prio0.main,
                height: 'auto',
                width: {
                    xs: 32,
                    sm: 40,
                    lg: 48,
                },
            }}
        />
    ),
    1: (
        <ErrorIcon
            sx={{
                color: (theme) => theme.other.prio1.main,
                height: 'auto',
                width: {
                    xs: 32,
                    sm: 40,
                    lg: 48,
                },
            }}
        />
    ),
    2: (
        <WarningIcon
            sx={{
                color: (theme) => theme.other.prio2.main,
                height: 'auto',
                width: {
                    xs: 32,
                    sm: 40,
                    lg: 48,
                },
            }}
        />
    ),
    3: (
        <InfoIcon
            sx={{
                color: (theme) => theme.other.prio3.main,
                height: 'auto',
                width: {
                    xs: 32,
                    sm: 40,
                    lg: 48,
                },
            }}
        />
    ),
};

export default function PrioCardMinimal({ numEvents, prio }) {
    return (
        <Link
            style={{ flexBasis: '23%', cursor: numEvents === 0 ? 'default' : 'pointer' }}
            activeClass="active"
            to={prio}
            spy={true}
            smooth={true}
            duration={250}
            offset={-60}
        >
            <Box
                sx={{
                    cursor: numEvents === 0 ? 'default' : 'pointer',
                    background: (theme) => theme.other.gray[100],
                    borderRadius: 1,
                    p: 2,
                    flexBasis: '23%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                {icons[prio]}
                <Typography
                    component="p"
                    variant="zep-typo--body-default"
                    sx={{
                        color: (theme) => theme.color.font,
                        fontWeight: 500,
                        fontSize: 28,
                        letterSpacing: '0.48px',
                        lineHeight: '23px',
                    }}
                >
                    {numEvents}
                </Typography>
            </Box>
        </Link>
    );
}

PrioCardMinimal.propTypes = {
    numEvents: PropTypes.number.isRequired,
    prio: PropTypes.string.isRequired,
};
