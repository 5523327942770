import {
    Box,
    Button,
    Container,
    Divider,
    Drawer,
    IconButton,
    Stack,
    Toolbar,
    Typography,
} from '@mui/material';
import MenuIcon from '@zlab-de/zel-react-icons/EssentialMenu';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeConfig } from '../../../../routes';
import logo from '../../assets/logo/como_bildwortmarke.svg';
import { useCustomerSelect } from '../../contexts/CustomerSelectContext';
import { useLayout } from '../../contexts/LayoutContext';
import Navigation from '../navigation/Navigation';
import UserNavigation from '../navigation/UserNavigation';
import OverviewDateFilter from '../overviewPage/OverviewDateFilter';
import { useAuth } from '../../contexts/AuthContext';
import { isUser } from "../../constants/role";

export default function Header({ pageTitle }) {
    const location = useLocation();
    const navigate = useNavigate();
    const { breakpointSm, breakpointXl } = useLayout();
    const { user } = useAuth();
    const { customerName } = useCustomerSelect();
    const [drawer, setDrawer] = useState(false);

    const isOverviewPage = location.pathname === routeConfig.app.dashboard.start;

    const backToHome = () => {
        navigate(routeConfig.app.index);
    };

    return (
        <Toolbar
            sx={{ background: (theme) => theme.color.global.white, minHeight: { xs: 72, md: 96 } }}
            disableGutters
        >
            <Container
                sx={{ display: 'flex', justifyContent: 'space-between' }}
                disableGutters={breakpointXl}
            >
                <Stack direction="row" alignItems="center">
                    {breakpointSm ? (
                        <Typography variant="zep-typo--display-5" sx={{ lineHeight: '56px' }}>
                            {pageTitle}
                            {!isUser(user.role) && customerName && (
                                <Box
                                    component="span"
                                    sx={{ ml: 1, color: (theme) => theme.color.black[40] }}
                                >
                                    {customerName}
                                </Box>
                            )}
                        </Typography>
                    ) : (
                        <Button onClick={backToHome}>
                            <Box
                                component="img"
                                src={logo}
                                alt="Zeppelin Logo"
                                sx={{
                                    height: 36,
                                    color: (theme) => theme.color.black,
                                }}
                            />
                        </Button>
                    )}
                </Stack>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {isOverviewPage && <OverviewDateFilter />}
                    {!breakpointSm && (
                        <>
                            <IconButton onClick={() => setDrawer(true)}>
                                <MenuIcon sx={{ color: '#000' }} />
                            </IconButton>
                            <Drawer anchor="right" open={drawer} onClose={() => setDrawer(false)}>
                                <Navigation />
                                <Divider />
                                <UserNavigation />
                            </Drawer>
                        </>
                    )}
                </Box>
            </Container>
        </Toolbar>
    );
}
