import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

import prioData from '../../utils/prioData';

export default function PrioHeader({ prio, numMachines, numEvents }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: { xs: 'wrap', sm: 'no-wrap' },
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
                width: '100%',
            }}
        >
            <Box
                sx={{ display: 'flex', alignItems: 'center', flexBasis: { xs: '100%', sm: '50%' } }}
            >
                <Box
                    sx={{
                        mr: 2,
                        width: 8,
                        height: 20,
                        borderRadius: 2,
                        background: (theme) => theme.other[`prio${prio}`].main,
                    }}
                />
                <Typography
                    component="h2"
                    variant="zep-typo--display-5"
                    sx={{
                        mr: { xs: 1, sm: 2 },
                    }}
                >
                    {prioData[prio].label}
                </Typography>
            </Box>
            {numEvents && numMachines && (
                <Typography
                    variant="zep-typo--display-6"
                    component="p"
                    sx={{
                        color: (theme) => theme.color.global.lightGray,
                    }}
                >
                    {`(${numEvents} Event-/Diagnosecodes | ${numMachines} Maschinen)`}
                </Typography>
            )}
        </Box>
    );
}

PrioHeader.propTypes = {
    prio: PropTypes.string.isRequired,
    numMachines: PropTypes.number,
    numEvents: PropTypes.number,
};
