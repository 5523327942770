import * as React from 'react';
import UpdateIcon from '@mui/icons-material/Update';
import BuildIcon from '@mui/icons-material/Build';
import FlowerIcon from '@zlab-de/zel-react-icons/ComoFlower';

const errorCodeData = {
    possible_causes: {
        label: 'Mögliche Fehlerursache',
        icon: <FlowerIcon />,
    },
    possible_consequences: {
        label: 'Mögliche Folgen',
        icon: <BuildIcon />,
    },
    recommendations: {
        label: 'Mögliches Entgegenwirken',
        icon: <UpdateIcon />,
    },
};

export default errorCodeData;
