import { Navigate, Route, Routes } from 'react-router-dom';
import { routeConfig, toRoute } from '../../routes';
import { isManager } from './constants/role';
import { useAuth } from './contexts/AuthContext';

import NotFoundPage from '../not-found';
import CustomerOverviewPage from './components/customerOverviewPage/CustomerOverviewPage';
import EventCodesPage from './components/eventCodesPage/EventCodesPage';
import MachineDetailPage from './components/detailPage/MachineDetailPage';
import ComoDashboard from './ComoDashboard';

export default function ComoRoutes() {
    const { user } = useAuth();

    const homepage = isManager(user.role)
        ? routeConfig.app.customers
        : routeConfig.app.dashboard.index;

    return (
        <Routes>
            <Route path="*" element={<NotFoundPage />} />
            <Route index element={<Navigate to={homepage} />} />
            <Route path={toRoute(routeConfig.app.customers)} element={<CustomerOverviewPage />} />
            <Route path={toRoute(routeConfig.app.errors)} element={<EventCodesPage />} />
            <Route
                path={`${toRoute(routeConfig.app.machineDetail)}/:machineId`}
                element={<MachineDetailPage />}
            />
            <Route
                path={`${toRoute(routeConfig.app.dashboard.index)}/*`}
                element={<ComoDashboard />}
            />
        </Routes>
    );
}
