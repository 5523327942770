import React, { createContext } from 'react';
import { Navigate } from 'react-router';
import { routeConfig } from '../../../routes';
import { useAuth } from './AuthContext';

const GuardContext = createContext();

export const GuardContextProvider = (props) => {
    const { user } = useAuth();

    if (!props.check(user.role)) {
        return <Navigate to={routeConfig.app.index} />;
    }

    return <GuardContext.Provider value={{}}>{props.children}</GuardContext.Provider>;
};
