import { DataGridPro, deDE } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routeConfig } from '../../../../routes';
import { useCustomerSelect } from '../../contexts/CustomerSelectContext';
import { escapeRegExp } from '../../utils/regex';
import CustomerTableSearchbar from './CustomerTableSearchbar';

const columns = [
    {
        field: 'companyName',
        headerName: 'Kundenname',
        headerClassName: 'data-grid--header',
        width: 300,
    },
    {
        field: 'realname',
        headerName: 'Ansprechpartner',
        headerClassName: 'data-grid--header',
        width: 200,
    },
    {
        field: 'companyIds',
        headerName: 'Kundennummer(n)',
        headerClassName: 'data-grid--header',
        flex: 1,
        minWidth: 200,
    },
];

export default function CustomerTable({ data }) {
    const navigate = useNavigate();
    const { setSelectedCustomerId, setSelectedCustomerName, setSelectedCustomerCompany } =
        useCustomerSelect();
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState('');

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
    };

    const handleSelect = (value) => {
        const { row: selectedCustomer } = value;
        const { id, realname, company } = selectedCustomer;
        setSelectedCustomerId(id);
        setSelectedCustomerName(realname);
        setSelectedCustomerCompany(company);
        navigate(routeConfig.app.dashboard.start);
    };

    const searchRegex = useMemo(() => new RegExp(escapeRegExp(searchText), 'i'), [searchText]);
    const filteredData = useMemo(
        () =>
            data.filter((row) => {
                return columns
                    .map((elem) => elem.field)
                    .some((field) => {
                        return searchRegex.test(row[field].toString());
                    });
            }),
        [data, searchRegex]
    );

    return (
        <DataGridPro
            autoHeight
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            components={{ Toolbar: CustomerTableSearchbar }}
            rows={filteredData}
            columns={columns}
            onRowClick={handleSelect}
            pageSize={pageSize}
            pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50]}
            maxColumns={4}
            componentsProps={{
                toolbar: {
                    value: searchText,
                    handleChange: (event) => requestSearch(event.target.value),
                    handleClearSearch: () => requestSearch(''),
                },
            }}
            disableColumnMenu
            sx={{
                backgroundColor: (theme) => theme.color.global.white,
                '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: (theme) => theme.other.gray[200],
                },
                '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer',
                },
                '& .MuiDataGrid-cell': {
                    overflow: 'hidden',
                },
                '& .MuiDataGrid-cell:focus,.MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                },
                '& .data-grid--header': {
                    fontFamily: '"Roboto Condensed", sans-serif',
                    letterSpacing: '1.25px',
                    textTransform: 'uppercase',
                },
            }}
        />
    );
}

CustomerTable.propTypes = {
    data: PropTypes.array.isRequired,
};
