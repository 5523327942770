function isValidEmailAddress(emailAddress) {
    const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return pattern.test(emailAddress);
}

export default function validate(values) {
    const errors = {};
    if (!values.email) {
        errors.email = 'E-Mail wird benötigt';
    }
    if (!isValidEmailAddress(values.email)) {
        errors.email = 'E-Mail ist ungültig';
    }
    if (!values.firstName) {
        errors.firstName = 'Vorname wird benötigt';
    }
    if (!values.lastName) {
        errors.lastName = 'Nachname wird benötigt';
    }

    return errors;
}
