import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import ShieldIcon from '@zlab-de/zel-react-icons/ComoShield';

import prioData from '../../utils/prioData';

export default function PrioRecommendation({ prio }) {
    const prioString = `prio${prio}`;

    return (
        <Box
            sx={{
                border: (theme) => `1px solid ${theme.other[prioString].main}`,
                p: 2,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ShieldIcon />
                <Typography variant="zep-typo--display-6" component="p" sx={{ ml: 2 }}>
                    Handlungsempfehlung
                </Typography>
            </Box>
            <Typography variant="zep-typo--body-default" component="p" sx={{ ml: 5 }}>
                {prioData[prio].description}
            </Typography>
        </Box>
    );
}

PrioRecommendation.propTypes = {
    prio: PropTypes.string.isRequired,
};
