import { Box, Typography } from '@mui/material';
import DashboardIcon from '@zlab-de/zel-react-icons/EssentialDashboard';
import SettingsIcon from '@zlab-de/zel-react-icons/EssentialSettings';
import StopwatchIcon from '@zlab-de/zel-react-icons/EssentialStopwatch';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { isManager, isUser } from '../../constants/role';
import { useAuth } from '../../contexts/AuthContext';
import { useCustomerSelect } from '../../contexts/CustomerSelectContext';
import { useMachineDetail } from '../../contexts/MachineDetailContext';
import { getInspectionStatus } from '../../core/machine';
import MachineOverviewPopover from './MachineOverviewPopover';
import MachinePicture from './MachinePicture';
import ServiceContactButton from './ServiceContactButton';

export default function MachineOverview() {
    const { user } = useAuth();
    const { customerCompany } = useCustomerSelect();
    const { machineDetail } = useMachineDetail();

    const title = machineDetail ? `${machineDetail.make}-${machineDetail.model}` : '';
    const machineNickname = machineDetail.nickname || 'interne Nummer';
    const customerTitle = customerCompany?.name? customerCompany.name: "";
    const displayCustomerTitle = isManager(user.role) ? customerTitle : '';
    const subtitle = [machineDetail.serialNumber, machineNickname, displayCustomerTitle]
        .filter(Boolean)
        .join(' | ');
    const inspectionStatus = getInspectionStatus(machineDetail);

    const data = [
        {
            icon: <StopwatchIcon />,
            label: 'Betriebsstunden',
            value: machineDetail.hours
                ? Math.round(machineDetail.hours).toLocaleString('de-DE')
                : 'Unbekannt',
            color: null,
        },
        {
            icon: <SettingsIcon />,
            label: 'Wartung',
            value: inspectionStatus.maintenance,
            color: machineDetail.nextInspection
                ? differenceInCalendarDays(new Date(machineDetail.nextInspection), new Date()) < 0
                    ? 'red'
                    : 'green'
                : machineDetail.meterUsageToNextInspection
                ? parseInt(machineDetail.meterUsageToNextInspection, 10) < 0
                    ? 'red'
                    : 'green'
                : null,
        },
        {
            icon: <DashboardIcon />,
            label: 'UVV',
            value: inspectionStatus.uvv,
            color: machineDetail.nextUvv
                ? differenceInCalendarDays(new Date(machineDetail.nextUvv), new Date()) < 0
                    ? 'red'
                    : 'green'
                : null,
        },
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: { xs: 'wrap', sm: 'no-wrap' },
                pt: 6,
            }}
        >
            <Box sx={{ flexBasis: '60%' }}>
                <MachinePicture model={machineDetail.model} />
            </Box>
            <Box sx={{ flexBasis: '40%' }}>
                <Typography variant="zep-typo--display-5" component="p">
                    {title}
                </Typography>
                <Typography variant="zep-typo--body-small" component="p" sx={{ mb: 2 }}>
                    {subtitle}
                </Typography>

                <Box sx={{ mb: 4, display: 'flex' }}>
                    <ServiceContactButton machine={machineDetail} isUser={isUser(user.role)} />
                </Box>

                {data.map((elem, idx) => (
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }} key={elem.label}>
                        {elem.icon}
                        <Typography sx={{ mx: 1, fontSize: 14 }}>{elem.label}:</Typography>
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: (theme) =>
                                    elem.color === 'green'
                                        ? theme.color.corporate.greenLight
                                        : elem.color === 'red'
                                        ? theme.color.corporate.red
                                        : theme.color.global.font,
                            }}
                        >
                            {elem.value}
                        </Typography>
                        {idx === 0 && <MachineOverviewPopover />}
                    </Box>
                ))}
            </Box>
        </Box>
    );
}
