export default function validate(values) {
    const errors = {};
    if (!values.email) {
        errors.email = 'Benutzername wird benötigt';
    }
    if (!values.password) {
        errors.password = 'Kennwort wird benötigt';
    }

    return errors;
}
