import React, { createContext, useContext, useMemo } from 'react';
import FetchError from '../common/FetchError';
import Spinner from '../common/Spinner';
import { CustomerDetailDTO } from '../dto/customer.dto';
import { useQuery } from '../utils/api';
import { useCustomerSelect } from './CustomerSelectContext';

const CustomerDetailContext = createContext();

const ContextProvider = (props) => {
    const { customerId } = useCustomerSelect();
    const customerDetailDTO = useMemo(() => new CustomerDetailDTO(customerId), [customerId]);
    const { data, error, isLoading, refetch } = useQuery(customerDetailDTO);

    if (isLoading) return <Spinner />;
    if (error) return <FetchError message={error.message} />;

    return (
        <CustomerDetailContext.Provider value={{ selectedCustomer: data.result, refetch }}>
            {props.children}
        </CustomerDetailContext.Provider>
    );
};

export const CustomerDetailContextProvider = ContextProvider;

export const useCustomerDetail = () => useContext(CustomerDetailContext);
