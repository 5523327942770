import * as React from 'react';
import PropTypes from 'prop-types';
import { Stack, Skeleton } from '@mui/material';

export default function Loading({ height }) {
    const placeholders = Array(10)
        .fill(null)
        .map((_, i) => i);

    return (
        <Stack spacing={1}>
            {placeholders.map((elem) => (
                <Skeleton variant="rectangular" width="100%" height={height || 50} key={elem} />
            ))}
        </Stack>
    );
}

Loading.propTypes = {
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
