import * as React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import machinePicture from '../../utils/machinePictures';

export default function MachinePicture({ model }) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                overflow: 'hidden',
                height: { xs: 200, sm: 300 },
            }}
        >
            <Box
                component="img"
                src={`https://cm-machineimages.s3.eu-central-1.amazonaws.com/${machinePicture(
                    model
                )}.png`}
                alt={model}
                sx={{ height: { xs: 200, sm: 300 } }}
            />
        </Box>
    );
}

MachinePicture.propTypes = {
    model: PropTypes.string,
};
