import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';

import pick from 'lodash/pick';
import PartsButton from '../orders/PartsButton';
import formatDate from '../../utils/formatDate';
import EventCardDetails from './EventCardDetails';
import { isUserOrAdmin } from '../../constants/role';
import { useAuth } from '../../contexts/AuthContext';

export default function EventCard({ data, prio, variant, showSparePartsButton }) {
    const [checked, setChecked] = React.useState(false);
    const {user} = useAuth();

    const eventCodeDateRange =
        data.length === 1
            ? formatDate(data[0]._time || data[0].latest_t)
            : `${formatDate(data[0]._time || data[0].latest_t)} - ${formatDate(
                  data[data.length - 1]._time || data[data.length - 1].latest_t
              )}
  `;
    const eventCodeDateSummary = `${eventCodeDateRange} (Häufigkeit: ${data.length})`;
    const showPartsButton =
        showSparePartsButton &&
        data[0].orderNr !== undefined &&
        isUserOrAdmin(user.role)

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            {variant === 'history' && (
                <Box
                    sx={{
                        width: 0,
                        height: 0,
                        position: 'relative',
                        top: 10,
                        borderTop: '8px solid transparent',
                        borderBottom: '8px solid transparent',
                        borderRight: (theme) => `8px solid ${theme.other.gray[300]}`,
                    }}
                />
            )}

            <Box
                sx={{
                    borderRadius: 2,
                    overflow: 'hidden',
                    mb: 4,
                    width: '100%',
                    boxShadow: '0px 16px 28px 0px rgba(12, 17, 34, 0.1)',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        background: (theme) => theme.other.gray[300],
                        py: 1,
                        px: 2,
                    }}
                >
                    <Typography variant="zep-typo--body-small" component="p">
                        {`${data[0].cid ? 'Diagnostic' : 'Event'} Data ${data[0].unique_code}`}
                    </Typography>
                    <Typography
                        variant="zep-typo--body-small"
                        component="p"
                        sx={{ textTransform: 'capitalize' }}
                    >
                        {eventCodeDateSummary}
                    </Typography>
                </Box>
                <Box sx={{ p: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                            variant="zep-typo--headline-6_alt"
                            component="p"
                            sx={{ fontSize: { xs: 14, md: 18 }, p: 1 }}
                        >
                            {data[0].description}
                        </Typography>
                        {showPartsButton && (
                            <PartsButton
                                title="Ersatzteil bestellen"
                                successMessage="Ersatzteil wurde dem Warenkorb im Zeppelin Kundenportal hinzugefügt."
                                order={pick(data[0], ['orderNr', 'jobNr', 'jobSuffix'])}
                                machine={pick(data[0], ['make', 'model', 'serialNumber'])}
                            />
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={handleChange}>
                            <ExpandMoreIcon
                                sx={{
                                    color: (theme) => theme.other.gray[400],
                                    transform: checked ? 'rotate(180deg)' : 'rotate(0deg)',
                                }}
                            />
                        </IconButton>
                        <Typography variant="zep-typo--body-small" component="p">
                            {checked ? 'Weniger Details anzeigen' : 'Alle Fehlerdetails ansehen'}
                        </Typography>
                    </Box>
                    <Collapse in={checked}>
                        <EventCardDetails data={data[0]} prio={prio} />
                    </Collapse>
                </Box>
            </Box>
        </Box>
    );
}

EventCard.propTypes = {
    data: PropTypes.array.isRequired,
    prio: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['history', 'default']),
    showSparePartsButton: PropTypes.bool,
};
