import * as React from 'react';
import PropTypes from 'prop-types';
import {
    TimelineContent,
    TimelineConnector,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from '@mui/lab';

export default function TimelineElement({ prio, customColor, children }) {
    return (
        <TimelineItem
            sx={{
                '&:before': {
                    flex: 0,
                    p: 0,
                    pl: '12px',
                },
                ':last-child .MuiTimelineConnector-root': {
                    display: 'none',
                },
            }}
        >
            <TimelineSeparator>
                <TimelineDot
                    sx={{
                        backgroundColor: (theme) =>
                            theme.other[customColor] || theme.other[`prio${prio}`].main,
                    }}
                />

                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ p: '0 0 0 20px' }}>{children}</TimelineContent>
        </TimelineItem>
    );
}

TimelineElement.propTypes = {
    prio: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    customColor: PropTypes.string,
};
