import * as React from 'react';
import { SvgIcon } from '@mui/material';

export default function MedicalIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <g
                id="icon-/-error-/-como-/-medical"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path
                    d="M20.2390028,8.5392 L19.0606028,6.4592 C18.9078388,6.18493214 18.6510297,5.98376691 18.3481601,5.90112504 C18.0452904,5.81848317 17.7218987,5.86133316 17.4510028,6.02 L14.3990028,7.84 L14.3990028,4.2 C14.4047731,3.54330147 13.8772978,3.00615766 13.2206028,3 L10.7774028,3 C10.1207078,3.00615766 9.5932326,3.54330147 9.59900284,4.2 L9.59900284,7.84 L6.54460284,6.0216 C6.27370701,5.86293316 5.95031527,5.82008317 5.64744561,5.90272504 C5.34457594,5.98536691 5.08776688,6.18653214 4.93500284,6.4608 L3.75900284,8.5392 C3.43279404,9.11191039 3.62440994,9.84033524 4.19020284,10.1784 L7.28620284,12 L4.18860284,13.8216 C3.62338807,14.1602004 3.43251517,14.8885031 3.75900284,15.4608 L4.93660284,17.5408 C5.08936688,17.8150679 5.34617594,18.0162331 5.64904561,18.098875 C5.95191527,18.1815168 6.27530701,18.1386668 6.54620284,17.98 L9.59900284,16.16 L9.59900284,19.8 C9.60336553,20.4609262 10.1380767,20.9956373 10.7990028,21 L13.1990028,21 C13.859929,20.9956373 14.3946401,20.4609262 14.3990028,19.8 L14.3990028,16.16 L17.4534028,17.9816 C17.7242987,18.1402668 18.0476904,18.1831168 18.3505601,18.100475 C18.6534297,18.0178331 18.9102388,17.8166679 19.0630028,17.5424 L20.2414028,15.4624 C20.5676116,14.8896896 20.3759957,14.1612648 19.8102028,13.8232 L16.7126028,12 L19.8102028,10.1784 C20.3751287,9.83953209 20.5656302,9.11129023 20.2390028,8.5392 Z"
                    id="Path-Copy"
                    stroke="currentColor"
                    strokeWidth="1.25"
                />
            </g>
        </SvgIcon>
    );
}
