import * as React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

export default function EditorText({ name, text, handleChange }) {
    const handleOnChange = (event) => {
        handleChange(name, event.target.value);
    };

    return (
        <TextField
            id={name}
            placeholder={text || 'Beschreibung hinzufügen...'}
            variant="filled"
            value={text}
            onChange={handleOnChange}
            sx={{
                width: '100%',
                '& .MuiFilledInput-root': {
                    '&:before': {
                        borderBottom: 'none',
                    },
                },
                '& .MuiFilledInput-input': {
                    p: 1,
                },
            }}
        />
    );
}

EditorText.propTypes = {
    name: PropTypes.string.isRequired,
    text: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
};
