import { addYears, subYears } from 'date-fns';

export const STATUS_UNKNOWN = 'Unbekannt';
export const UNKNOWN_WITH_METER_USAGE_DURATION = 0;
export const ALL = 'all';
export const UVV = 'UVV';
export const MAINTENANCE = 'Wartung';

export const UPCOMING_DATE_START = 0;
export const UPCOMING_DATE_END = 25;
export const YEAR_FILTER_RANGE = 2;
export const FILTER_START_DATE = subYears(new Date(), YEAR_FILTER_RANGE);
export const FILTER_END_DATE = addYears(new Date(), YEAR_FILTER_RANGE);
