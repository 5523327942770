import { FormGroup, Typography } from '@mui/material';
import { isUser } from '../../constants/role';
import { useAlert } from '../../contexts/AlertContext';
import { useAuth } from '../../contexts/AuthContext';
import { useCustomerDetail } from '../../contexts/CustomerDetailContext';
import { useCustomerSelect } from '../../contexts/CustomerSelectContext';
import { CustomerNotificationUpdateDTO } from '../../dto/customer.dto';
import { UserNotificationUpdateDTO } from '../../dto/user.dto';
import { useMutation } from '../../utils/api';
import ReportCheckbox from './ReportCheckbox';
import { useSearchParams } from 'react-router-dom';
import { getDefaultTabValue } from "./ReportsPage";

export default function EventCodeReports() {
    const [searchParams] = useSearchParams();
    const { openAlert } = useAlert();
    const { user } = useAuth();
    const { customerId } = useCustomerSelect();
    const { selectedCustomer, refetch } = useCustomerDetail();
    const [updateNotificationSettings] = useMutation();

    const reports = [
        { id: 'Prio1', label: 'Prio 1 (schwerwiegend)' },
        { id: 'Prio2', label: 'Prio 2 (mittel)' },
        { id: 'Prio3', label: 'Prio 3 (gering)' },
        { id: 'Prio4', label: 'unbekannte Prio' },
    ];

    const tabValue = getDefaultTabValue(searchParams.get('tab'), user);
    const isUpdateForAdmin = tabValue === 1;
    const reportKeyPrefix = isUpdateForAdmin ? 'adminNotifications' : 'notifications';

    const getReportChecked = (reportId) => {
        return selectedCustomer[`${reportKeyPrefix}${reportId}`] === 'ON';
    };

    const getNotificationUpdateDTO = (newValue) => {
        return isUser(user.role)
            ? new UserNotificationUpdateDTO(newValue)
            : new CustomerNotificationUpdateDTO(newValue);
    };

    const handleChange = async (newState, id) => {
        const newValue = [newState ? 'ON' : 'OFF', `${reportKeyPrefix}${id}`, customerId];
        const notificationUpdateDTO = getNotificationUpdateDTO(newValue);

        await updateNotificationSettings(notificationUpdateDTO, {
            onSuccess: () => {
                openAlert({
                    message: 'Einstellungen erfolgreich aktualisiert',
                    severity: 'success',
                });
                refetch();
            },
            onError: (error) => {
                openAlert({
                    message: error.message,
                    severity: 'error',
                });
            },
        });
    };

    return (
        <>
            <Typography
                component="p"
                variant="zep-typo--headline-5_alt"
                sx={{ fontSize: 16, mb: 1 }}
            >
                Maschinen die einen bestimmten Fehlercode ausweisen
            </Typography>
            <FormGroup>
                {reports.map((elem) => (
                    <ReportCheckbox
                        key={elem.id}
                        label={elem.label}
                        id={elem.id}
                        onChange={handleChange}
                        checked={getReportChecked(elem.id)}
                    />
                ))}
            </FormGroup>
        </>
    );
}
