export const COMMON_MESSAGE = 'Wenden Sie sich bitte an Ihren Zeppelin Service oder kontaktieren Sie como@zeppelin.com';
export const FETCH_ERROR_MESSAGE = 'Oops, something went wrong. Please try again later.';

export const errorMessages = {
    fetchAuthInfo: `Fehler beim Verbinden mit dem Server. ${COMMON_MESSAGE}`,
    emptyData: `Keine Daten verfügbar. ${COMMON_MESSAGE}`,
    fetchUser: `Fehler beim Laden der Benutzerdaten. ${COMMON_MESSAGE}`,
    updateNotifications: `Fehler beim Aktualisieren der Reports. ${COMMON_MESSAGE}`,
    updateCustomerNotifications: `Fehler beim Aktualisieren der Kundenreports. ${COMMON_MESSAGE}`,
    fetchCustomers: `Fehler beim Laden der Benutzerdaten. ${COMMON_MESSAGE}`,
    fetchSelectedCustomer: `Fehler beim Laden des Kunden. ${COMMON_MESSAGE}`,
    fetchEventCodes: `Fehler beim Laden der Fehlercodes. ${COMMON_MESSAGE}`,
    updateEventCodes: `Fehler beim Aktualisieren der Fehlercodes. ${COMMON_MESSAGE}`,
    fetchEvents: `Fehler beim Laden der Events. ${COMMON_MESSAGE}`,
    fetchMachines: `Fehler beim Laden der Maschinen. ${COMMON_MESSAGE}`,
    postOrder: `Der Auftrag konnte nicht in den Zeppelin Kundenportal Warenkorb gelegt werden. ${COMMON_MESSAGE}`,
    postMaintenance: `Wartung/UVV Einstellung fehlgeschlagen. ${COMMON_MESSAGE}`,
    fetchSelectedMachine: `Fehler beim Laden der ausgewählten Maschine. ${COMMON_MESSAGE}`,
    fetchSelectedMachineHistory: `Fehler beim Laden der Historie der Maschine. ${COMMON_MESSAGE}`,
    fetchSelectedMachineOilReports: `Fehler beim Laden des Ölreports der Maschine. ${COMMON_MESSAGE}`,
    'login.invalidCredentials': 'Inkorrekte Anmeldedaten. Bitte überprüfen Sie Benutzername und Passwort',
    'login.unexpectedStatus': (statusCode) => `Unerwarteter Status-Code ${statusCode}. ${COMMON_MESSAGE}}`,
    'login.unknownError': `Fehler beim Zugriff auf den Server. ${COMMON_MESSAGE}`,
    'fetchBranches': `Fehler beim Abruf der Niederlassungen. ${COMMON_MESSAGE}`,
    'createTicket': `Fehler beim Erstellen des Tickets. ${COMMON_MESSAGE}`,
    'createMaintenance': `Fehler beim Erstellen der Inspektion. ${COMMON_MESSAGE}`,
};