import { useMediaQuery } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';

const LayoutContext = createContext();

const endDate = new Date();
const startDate = new Date(new Date().setDate(endDate.getDate() - 7));

export const LayoutContextProvider = (props) => {
    const breakpointSm = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const breakpointLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const breakpointMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const breakpointXl = useMediaQuery((theme) => theme.breakpoints.up('xl'));

    const [sidebarOpen, setSidebarOpen] = useState(!!breakpointMd);
    const [eventListDateFilter, setEventListDateFilter] = useState([startDate, endDate]);

    const store = {
        sidebarOpen,
        setSidebarOpen,
        eventListDateFilter,
        setEventListDateFilter,
        breakpointSm,
        breakpointLg,
        breakpointMd,
        breakpointXl,
    };

    return <LayoutContext.Provider value={store}>{props.children}</LayoutContext.Provider>;
};

export const useLayout = () => useContext(LayoutContext);
