import { Box, ButtonBase, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import EmailIcon from '@zlab-de/zel-react-icons/CommunicationEmail';
import ClipboardIcon from '@zlab-de/zel-react-icons/ComoClipboard';
import DataExportIcon from '@zlab-de/zel-react-icons/ComoDownload';
import FleetIcon from '@zlab-de/zel-react-icons/ComoFleet';
import MapIcon from '@zlab-de/zel-react-icons/ComoMap';
import OverviewIcon from '@zlab-de/zel-react-icons/ComoOverview';
import AvatarCheckIcon from '@zlab-de/zel-react-icons/EssentialAvatarCheck';
import { useLocation, useNavigate } from 'react-router-dom';
import { pageTitle, routeConfig } from '../../../../routes';
import { isAdmin, isManager } from '../../constants/role';
import { useAnalytics } from '../../contexts/AnalyticsContext';
import { useAuth } from '../../contexts/AuthContext';
import { useCustomerSelect } from '../../contexts/CustomerSelectContext';
import { useLayout } from '../../contexts/LayoutContext';
import CalendarMonthIcon from '../icons/CalendarIcon';

export default function Navigation() {
    const location = useLocation();
    const navigate = useNavigate();
    const { trackEvent } = useAnalytics();
    const { user } = useAuth();
    const { customerId } = useCustomerSelect();
    const { sidebarOpen: open, breakpointSm } = useLayout();

    const data = [
        {
            link: routeConfig.app.customers,
            label: pageTitle.app.customers,
            icon: <AvatarCheckIcon sx={{ width: 24, height: 24 }} />,
            check: isManager,
        },
        {
            link: routeConfig.app.dashboard.start,
            label: pageTitle.app.dashboard.start,
            icon: (
                <OverviewIcon
                    sx={{
                        width: 24,
                        height: 24,
                    }}
                />
            ),
            disabled: !customerId,
        },
        {
            link: routeConfig.app.dashboard.map,
            label: pageTitle.app.dashboard.map,
            icon: <MapIcon sx={{ width: 24, height: 24 }} />,
            disabled: !customerId,
        },
        {
            link: routeConfig.app.dashboard.inspection,
            label: pageTitle.app.dashboard.inspection,
            icon: <CalendarMonthIcon sx={{ width: 24, height: 24 }} />,
            disabled: !customerId,
        },
        {
            link: routeConfig.app.dashboard.fuhrpark,
            label: pageTitle.app.dashboard.fuhrpark,
            icon: <FleetIcon style={{ width: 24, height: 24 }} />,
            disabled: !customerId,
        },
        {
            link: routeConfig.app.dashboard.export,
            label: pageTitle.app.dashboard.export,
            icon: <DataExportIcon sx={{ width: 24, height: 24 }} />,
            disabled: !customerId,
        },
        {
            link: routeConfig.app.dashboard.reports,
            label: pageTitle.app.dashboard.reports,
            icon: <EmailIcon sx={{ width: 24, height: 24 }} />,
            disabled: !customerId,
        },
        {
            link: routeConfig.app.errors,
            label: pageTitle.app.errors,
            icon: <ClipboardIcon sx={{ width: 24, height: 24 }} />,
            check: isAdmin,
        },
    ];

    const handleClick = (path) => {
        navigate(path);
        const tagManagerArgs = {
            dataLayer: {
                navigationItem: path,
                event: 'navigation_click',
            },
        };
        trackEvent(tagManagerArgs);
    };

    return (
        <nav aria-label="Main Navigation">
            <List disablePadding>
                {data.map((elem, idx) => {
                    if (elem.check && !elem.check(user.role)) return null;
                    return (
                        <ListItemButton
                            onClick={() => handleClick(elem.link)}
                            key={elem.label}
                            disabled={elem.disabled}
                            sx={{
                                mb: 1,
                                p: { xs: '8px 16px', sm: open ? '8px 16px' : 1 },
                                width: { xs: 'auto', sm: open ? 'inherit' : 48 },
                                borderRadius: 1,
                                '&:hover': {
                                    background: (theme) => theme.palette.secondary.main,
                                },
                                background:
                                    location.pathname === elem.link
                                        ? (theme) => theme.palette.secondary.main
                                        : 'transparent',
                            }}
                        >
                            <ButtonBase>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: { xs: 'flex-start', sm: 'center' },
                                        alignItems: { xs: 'flex-start', sm: 'center' },
                                        flexDirection: { xs: 'column', sm: 'row' },
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            display: {
                                                xs: 'none',
                                                sm: open ? 'block' : 'flex',
                                            },
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: open ? 'auto' : '100%',
                                            minWidth: 'auto',
                                            color: (theme) => theme.color.global.black,
                                            mr: open ? 2 : 0,
                                        }}
                                        aria-label={elem.label}
                                    >
                                        {elem.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={!breakpointSm || open ? elem.label : null}
                                        sx={{
                                            '& .MuiListItemText-primary': {
                                                textTransform: {
                                                    xs: 'none',
                                                    sm: 'uppercase',
                                                },
                                                fontWeight: {
                                                    xs: 400,
                                                    sm: 700,
                                                },
                                                fontSize: 14,
                                                letterSpacing: {
                                                    xs: 0,
                                                    sm: 1.25,
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                            </ButtonBase>
                        </ListItemButton>
                    );
                })}
            </List>
        </nav>
    );
}
