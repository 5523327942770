import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { Typography, ButtonBase } from '@mui/material';
import { footerLinks, pageTitle, routeConfig } from '../../../../routes';

export default function Footer() {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                background: (theme) => theme.color.global.almostWhite,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            {footerLinks.map((key, idx) => (
                <ButtonBase onClick={() => navigate(routeConfig[key])} key={key}>
                    <Typography variant="zep-typo--body-small" component="p">
                        {pageTitle[key]}
                        <Box component="span" sx={{ pl: 1, pr: 1 }}>
                            {idx === footerLinks.length - 1 ? '' : '|'}
                        </Box>
                    </Typography>
                </ButtonBase>
            ))}
        </Box>
    );
}
