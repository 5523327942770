import { Box, ButtonBase, Typography } from '@mui/material';
import machinePicture from '../../utils/machinePictures';
import { checkCoordinate } from "../../utils/geography";

export default function MachineListItem({ machine, isSelected }) {
    const machineNickname =
        machine.nickname && machine.nickname.length > 20
            ? `${machine.nickname.match(/.{1,20}/g)[0]}...`
            : machine.nickname;

    const title = `${machine.make}-${machine.model}`;
    const formattedTitle = title.length > 15 ? `${title.match(/.{1,15}/g)[0]}...` : title;
    const isValid = checkCoordinate(machine);

    return (
        <ButtonBase
            sx={{
                background: (theme) =>
                    isSelected ? theme.palette.primary.main : theme.other.gray[200],
                p: 3,
                width: '100%',
                ':hover': isSelected
                    ? undefined
                    : {
                          background: (theme) => theme.color.global.white,
                          '& #mliImage': {
                              background: (theme) => theme.other.gray[200],
                          },
                      },
            }}
        >
            <Box
                id="mliImage"
                sx={{
                    width: 100,
                    minWidth: 100,
                    height: 100,
                    borderRadius: '50%',
                    background: (theme) => theme.color.global.white,
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mr: 4,
                }}
            >
                <Box
                    component="img"
                    sx={{
                        width: '80%',
                    }}
                    alt={`${machine.make}-${machine.model}`}
                    src={`https://cm-machineimages.s3.eu-central-1.amazonaws.com/${machinePicture(
                        machine.model
                    )}.png`}
                />
            </Box>
            <Box
                sx={{
                    flexGrow: 1,
                    textAlign: 'left',
                }}
            >
                <Typography variant="zep-typo--display-5">{formattedTitle}</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="zep-typo--body-small" sx={{ fontWeight: 'bold' }}>
                        Spitzname:
                    </Typography>
                    <Typography variant="zep-typo--body-small">
                        {machineNickname || 'keine'}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="zep-typo--body-small" sx={{ fontWeight: 'bold' }}>
                        Seriennummer:
                    </Typography>
                    <Typography variant="zep-typo--body-small">{machine.serialNumber}</Typography>
                </Box>
                {!isValid && (
                    <Typography
                        variant="zep-typo--body-small"
                        sx={{ fontWeight: 'bold', color: (theme) => theme.other.red }}
                    >
                        Keine Koordinaten!
                    </Typography>
                )}
            </Box>
        </ButtonBase>
    );
}
