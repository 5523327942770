import { useCallback, useEffect, useState } from 'react';
import { customFetch } from './fetch';

export function useQuery(dto) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = useCallback(async () => {
        try {
            const data = await customFetch(dto);
            setData(data);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [dto]);

    const refetch = async () => {
        setIsLoading(true);
        setData(null);
        setError(null);
        await fetchData();
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return { data, error, isLoading, refetch };
}

export function useLazyQuery(dto) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const data = await customFetch(dto);
            setData(data);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [dto]);

    const refetch = async () => {
        setIsLoading(true);
        setData(null);
        setError(null);
        await fetchData();
    };

    return [fetchData, { data, error, isLoading, refetch }];
}

export function useMutation() {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const mutate = async (dto, { onSuccess, onError } = {}) => {
        setIsLoading(true);
        try {
            const data = await customFetch(dto);
            setData(data);
            onSuccess && onSuccess(data);
        } catch (error) {
            setError(error);
            onError && onError(error);
        }
        setIsLoading(false);
    };

    const reset = () => {
        setData(null);
        setError(null);
        setIsLoading(false);
    };

    return [mutate, { data, error, isLoading, reset }];
}
