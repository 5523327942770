import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, ButtonBase, Container, Toolbar, useMediaQuery } from '@mui/material';
import { routeConfig } from '../../routes';

export default function Header() {
    const navigate = useNavigate();
    const breakpointXl = useMediaQuery((theme) => theme.breakpoints.up('xl'));

    return (
        <Toolbar
            sx={{
                background: (theme) => theme.color.global.white,
                minHeight: { xs: 72, md: 96 },
            }}
            disableGutters
        >
            <Container
                sx={{ display: 'flex', justifyContent: 'space-between' }}
                disableGutters={breakpointXl}
            >
                <ButtonBase onClick={() => navigate(routeConfig.app.index)}>
                    <Box
                        component="img"
                        src="/logo.svg"
                        alt="Zeppelin Logo"
                        sx={{
                            height: 36,
                            color: (theme) => theme.color.black,
                        }}
                    />
                </ButtonBase>
            </Container>
        </Toolbar>
    );
}
