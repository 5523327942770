import * as React from 'react';
import { SvgIcon } from '@mui/material';

export default function InfoIcon(props) {
    return (
        <SvgIcon {...props} titleAccess="error" viewBox="0 0 44 44">
            <g
                id="icon-/-alert-/-large-/-info"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <path
                    d="M19.5586542,3.01086569 C20.9068531,1.66304477 23.0922837,1.66304477 24.4404826,3.01086569 L24.4404826,3.01086569 L40.988293,19.5590331 C41.636064,20.2065557 42,21.0849423 42,22.0008631 C42,22.916784 41.636064,23.7951706 40.988293,24.4426932 L40.988293,24.4426932 L24.4404826,40.9891343 C23.0922837,42.3369552 20.9068531,42.3369552 19.5586542,40.9891343 L19.5586542,40.9891343 L3.01084388,24.4426932 L2.87159497,24.2951127 C1.66477116,22.9394275 1.71118746,20.8608364 3.01084388,19.5607594 L3.01084388,19.5607594 Z M21.1580227,17.7921805 L19.4749312,17.7921805 C18.5453854,17.7921805 17.7918397,18.5457425 17.7918397,19.4753083 C17.7918397,20.4048741 18.5453854,21.1584361 19.4749312,21.1584361 L19.4749312,21.1584361 L20.7372498,21.1584361 C20.9696362,21.1584361 21.1580227,21.3468266 21.1580227,21.579218 L21.1580227,21.579218 L21.1580227,29.1532932 C21.1580227,29.3856847 20.9696362,29.5740752 20.7372498,29.5740752 L20.7372498,29.5740752 L19.4749312,29.5740752 C18.5453854,29.5740752 17.7918397,30.3276372 17.7918397,31.257203 C17.7918397,32.1867688 18.5453854,32.9403308 19.4749312,32.9403308 L19.4749312,32.9403308 L26.2072971,32.9403308 C27.1368429,32.9403308 27.8903886,32.1867688 27.8903886,31.257203 C27.8903886,30.3276372 27.1368429,29.5740752 26.2072971,29.5740752 L26.2072971,29.5740752 L24.9449785,29.5740752 C24.7125921,29.5740752 24.5242057,29.3856847 24.5242057,29.1532932 L24.5242057,29.1532932 L24.5242057,21.1584361 C24.5242057,19.2993044 23.0171142,17.7921805 21.1580227,17.7921805 L21.1580227,17.7921805 Z M22.4203413,10.2181053 C21.0260226,10.2181053 19.895704,11.3484483 19.895704,12.742797 C19.895704,14.1371458 21.0260226,15.2674887 22.4203413,15.2674887 C23.8146599,15.2674887 24.9449785,14.1371458 24.9449785,12.742797 C24.9449785,11.3484483 23.8146599,10.2181053 22.4203413,10.2181053 Z"
                    id="Combined-Shape-Copy-5"
                    fill="currentColor"
                    fillRule="nonzero"
                />
            </g>
        </SvgIcon>
    );
}
