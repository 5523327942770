import * as React from 'react';
import PropTypes from 'prop-types';
import {
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
} from '@mui/lab';

export default function TimelineDate({ children, icon, variant }) {
    return (
        <TimelineItem
            sx={{
                '&:before': {
                    flex: 0,
                    p: 0,
                },
            }}
        >
            <TimelineSeparator>
                <TimelineDot
                    variant={variant}
                    sx={{ backgroundColor: 'rgba(255,255,255,0)', boxShadow: 'none' }}
                >
                    {icon}
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
                className="zep-typo--display-5"
                sx={{
                    fontWeight: 700,
                    fontSize: 24,
                    fontFamily: "'Roboto Condensed',sans-serif",
                    lineHeight: '32px',
                    mt: 1,
                }}
            >
                {children}
            </TimelineContent>
        </TimelineItem>
    );
}

TimelineDate.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    icon: PropTypes.element,
    variant: PropTypes.oneOf(['filled', 'outlined']),
};
