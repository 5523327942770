import { Box, Typography } from '@mui/material';
import { pageTitle } from '../../../../routes';
import { useEvent } from '../../contexts/DashboardDataContext';
import { useLayout } from '../../contexts/LayoutContext';
import Layout from '../layout/Layout';
import PrioSection from '../prio/PrioSection';
import EventSection from './EventSection';

export default function OverviewPage() {
    const { sidebarOpen, eventListDateFilter } = useLayout();
    const { events } = useEvent();

    const data = eventListDateFilter
        ? events.filter(
              (elem) =>
                  new Date(elem.latest_t) > eventListDateFilter[0] &&
                  new Date(elem.latest_t) < eventListDateFilter[1]
          )
        : events;

    return (
        <Layout pageTitle={pageTitle.app.dashboard.start}>
            <Box
                sx={{
                    position: 'fixed',
                    top: { xs: 72, md: 96 },
                    background: (theme) => theme.other.gray[200],
                    zIndex: 4,
                    width: {
                        xs: 'calc(100vW - 32px)',
                        sm: sidebarOpen ? 'calc(100vW - 298px)' : 'calc(100vW - 112px)',
                        lg: sidebarOpen
                            ? 'min(1200px, 100vW - 298px)'
                            : 'min(1152px, 100vW - 112px)',
                        xl: sidebarOpen
                            ? 'min(1200px, 100vW - 298px)'
                            : 'min(1200px, 100vW - 112px)',
                    },
                    py: { xs: 2, md: 4, lg: 6 },
                }}
            >
                <PrioSection data={data} />
            </Box>

            <Box sx={{ mt: { xs: '50px', md: '184px', lg: '216px' } }}>
                {data?.length === 0 && (
                    <Typography
                        variant="zep-typo--display-6"
                        component="p"
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        Im gewählten Zeitraum wurden keine Event-/Diagnosecodes gefunden
                    </Typography>
                )}

                {data?.length > 0 &&
                    ['1', '2', '3', '0'].map((elem) => (
                        <EventSection data={data.filter((item) => item.prio === elem)} key={elem} />
                    ))}
            </Box>
        </Layout>
    );
}
