import { Button, Typography } from '@mui/material';
import ArrowIcon from '@zlab-de/zel-react-icons/ArrowChevronLeft';
import React from 'react';
import { pageTitle } from '../../../../routes';
import { isAdmin } from '../../constants/role';
import { EventCodeListContextProvider, useEventCodeList } from '../../contexts/EventCodeListContext';
import { GuardContextProvider } from '../../contexts/GuardContext';
import Layout from '../layout/Layout';
import EventCodeEditor from './EventCodeEditor';
import ErrorTable from './EventCodesTable';

function EventCodes() {
    const { eventCodeList, selectedCode, setSelectedCode } = useEventCodeList();

    if (selectedCode) {
        return (
            <>
                <Button
                    startIcon={<ArrowIcon />}
                    sx={{ mb: 2 }}
                    onClick={() => setSelectedCode(null)}
                >
                    Zurück
                </Button>
                <EventCodeEditor />
            </>
        );
    }

    const isEmptyList = eventCodeList.length === 0;
    if (isEmptyList) {
        return (
            <Typography
                variant="zep-typo--display-6"
                component="p"
                sx={{ width: '100%', textAlign: 'center', mt: 8 }}
            >
                keine Event-/Diagnosecodes gefunden
            </Typography>
        );
    }

    return <ErrorTable />;
}

export default function EventCodesPage() {
    return (
        <GuardContextProvider check={isAdmin}>
            <Layout pageTitle={pageTitle.app.errors}>
                <EventCodeListContextProvider>
                    <EventCodes />
                </EventCodeListContextProvider>
            </Layout>
        </GuardContextProvider>
    );
}
