import { DataGridPro, deDE } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import { routeConfig } from '../../../../routes';
import { useAnalytics } from '../../contexts/AnalyticsContext';
import { useCustomerSelect } from '../../contexts/CustomerSelectContext';
import formatDate from '../../utils/formatDate';

const columns = [
    {
        field: 'date',
        headerName: 'Datum',
        headerClassName: 'data-grid--header',
        width: 150,
        renderCell: (params) => formatDate(params.value),
    },
    {
        field: 'code',
        headerName: 'Code',
        headerClassName: 'data-grid--header',
        width: 150,
    },
    {
        field: 'description',
        headerName: 'beschreibung',
        headerClassName: 'data-grid--header',
        flex: 1,
        minWidth: 150,
    },
    {
        field: 'machine',
        headerName: 'maschine',
        headerClassName: 'data-grid--header',
        width: 150,
    },
    {
        field: 'serialNumber',
        headerName: 'seriennr.',
        headerClassName: 'data-grid--header',
        width: 150,
    },
];

export default function EventsTable({ data }) {
    const { trackEvent } = useAnalytics();
    const { navigateWithCustomerId } = useCustomerSelect();

    const getRows = () => {
        const temp = [];
        data?.forEach((elem) => {
            temp.push({
                id: `${elem.serialNumber}-${elem.unique_code}-${elem.prio}`,
                code: elem.unique_code,
                date: elem.latest_t,
                description: elem.description,
                machine: elem.make_model,
                serialNumber: elem.serialNumber,
            });
        });
        temp.sort((a, b) => new Date(b.date) - new Date(a.date));
        return temp;
    };

    const rows = getRows();

    const handleClick = (newSelectionModel) => {
        const serialNumber = newSelectionModel[0].split('-')[0];
        const machine = data.filter((e) => e.serialNumber === serialNumber);
        navigateWithCustomerId(`${routeConfig.app.machineDetail}/${serialNumber}`);
        const tagManagerArgs = {
            dataLayer: {
                prioGroup: `Prio ${newSelectionModel[0].split('-')[2]}`,
                serialNumber,
                machineName: machine[0].machine,
                errorMessage: machine[0].description,
                event: 'prio_item_click',
            },
        };
        trackEvent(tagManagerArgs);
    };

    return (
        <DataGridPro
            autoHeight
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            columns={columns}
            onSelectionModelChange={(e, newSelectionModel) => {
                handleClick(e, newSelectionModel);
            }}
            maxColumns={columns.length}
            disableColumnMenu
            hideFooter
            hideFooterPagination
            hideFooterRowCount
            sx={{
                background: (theme) => theme.color.global.white,
                '& .MuiDataGrid-cell': {
                    overflow: 'hidden',
                },
                '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer',
                },
                '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: (theme) => theme.other.gray[200],
                },
                '& .MuiDataGrid-cell:focus,.MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                },
                '& .MuiDataGrid-footerContainer': {
                    minHeight: 0,
                },
                '& .data-grid--header': {
                    fontFamily: '"Roboto Condensed", sans-serif',
                    letterSpacing: '1.25px',
                    textTransform: 'uppercase',
                },
            }}
        />
    );
}

EventsTable.propTypes = {
    data: PropTypes.array.isRequired,
};
