import { errorMessages } from '../constants/error';
import { DTO } from './base.dto';

export class ErrorListDTO extends DTO {
    constructor() {
        super({
            apiRoute: 'api/v1/errors',
            fetchErrorMessage: errorMessages.fetchEventCodes,
        });
    }
}

export class ErrorUpdateDTO extends DTO {
    constructor(errorObject) {
        super({
            apiRoute: `api/v1/errors/${errorObject._key}`,
            fetchErrorMessage: errorMessages.updateEventCodes,
            fetchInit: {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    code: errorObject,
                }),
            },
        });
    }
}
