import { Box, Container } from '@mui/material';
import { Element } from 'react-scroll';
import { useLayout } from '../../contexts/LayoutContext';
import Alerts from './Alerts';
import Footer from './Footer';
import Header from './Header';
import PageTitle from './PageTitle';
import Seo from '../../../../components/layout/Seo';
import Sidebar from './Sidebar';

export default function Layout({ children, pageTitle, fullWidth = false }) {
    const { breakpointSm, breakpointMd, breakpointXl, sidebarOpen } = useLayout();
    const drawerWidth = sidebarOpen ? 250 : 64;

    return (
        <Box sx={{ display: 'flex' }}>
            <Seo title={pageTitle} />
            <Alerts />
            {breakpointSm && (
                <Box
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                        flex: '0 0 auto',
                        display: { xs: 'none', sm: 'block' },
                        transition: (theme) =>
                            theme.transitions.create('width', {
                                easing: theme.transitions.easing.sharp,
                                duration: sidebarOpen
                                    ? theme.transitions.duration.enteringScreen
                                    : theme.transitions.duration.leavingScreen,
                            }),
                    }}
                >
                    <Sidebar />
                </Box>
            )}
            <Box
                sx={{
                    width: { xs: '100vW', sm: `calc(100vW - ${drawerWidth}px)` },
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    height: '100vH',
                    background: (theme) => theme.other.gray[200],
                }}
            >
                <Header pageTitle={pageTitle} />
                <Element
                    name="containerElement"
                    id="containerElement"
                    style={{
                        position: 'relative',
                        flex: '1 1 auto',
                        overflowY: 'auto',
                        minHeight: 0,
                    }}
                >
                    {fullWidth ? (
                        <>
                            {!breakpointMd && <PageTitle pageTitle={pageTitle} />}
                            {children}
                        </>
                    ) : (
                        <Container
                            disableGutters={breakpointXl}
                            sx={{
                                my: { xs: 2, md: 4, lg: 6 },
                            }}
                        >
                            {!breakpointMd && <PageTitle pageTitle={pageTitle} />}
                            {children}
                        </Container>
                    )}
                </Element>
                <Footer />
            </Box>
        </Box>
    );
}
