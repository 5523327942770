import { useNavigate } from 'react-router-dom';
import { Button, ButtonBase, Box } from '@mui/material';
import ChevronLeftIcon from '@zlab-de/zel-react-icons/ArrowChevronLeft';
import Navigation from '../navigation/Navigation';
import SidebarUserDrawer from './SidebarUserDrawer';
import logo from '../../assets/logo/como_bildwortmarke.svg';
import logoSm from '../../assets/logo/como_bildmarke.svg';
import { routeConfig } from '../../../../routes';
import { useLayout } from '../../contexts/LayoutContext';

export default function Sidebar() {
    const navigate = useNavigate();
    const { sidebarOpen: open, setSidebarOpen } = useLayout();

    const handleClick = () => {
        navigate(routeConfig.app.index);
    };

    const toggleSidebar = () => {
        setSidebarOpen((prev) => !prev);
    };

    return (
        <Box
            sx={{
                background: (theme) => theme.palette.primary.main,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                overflow: 'hidden',
                height: '100vH',
            }}
        >
            <Box
                sx={{
                    height: 96,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: open ? 'flex-start' : 'center',
                }}
            >
                <ButtonBase onClick={handleClick}>
                    {open ? (
                        <Box
                            component="img"
                            src={logo}
                            alt="Zeppelin Logo"
                            sx={{
                                ml: '34px',
                                height: 36,
                                color: (theme) => theme.color.black,
                            }}
                        />
                    ) : (
                        <Box
                            component="img"
                            src={logoSm}
                            alt="Zeppelin Logo"
                            sx={{
                                height: 36,
                                color: (theme) => theme.color.black,
                            }}
                        />
                    )}
                </ButtonBase>
            </Box>
            <Box sx={{ pt: 4, pl: open ? 2 : 1, pr: open ? 2 : 1, flexGrow: 1 }}>
                <Navigation />
            </Box>
            <div>
                <SidebarUserDrawer />
                <Button
                    onClick={toggleSidebar}
                    sx={{
                        border: (theme) => `2px solid ${theme.palette.secondary.main}`,
                        width: 40,
                        minWidth: 40,
                        height: 40,
                        p: 0,
                        position: 'fixed',
                        bottom: 88,
                        left: open ? 230 : 44,
                        zIndex: 3,
                    }}
                    variant="contained"
                    aria-label={open ? 'minimize' : 'expand'}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
                        }}
                    >
                        <ChevronLeftIcon />
                    </Box>
                </Button>
            </div>
        </Box>
    );
}
