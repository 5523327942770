import * as React from 'react';
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
} from '@mui/material';
import ReactMarkdown from 'markdown-to-jsx';
import service from './service1.md';
import service2 from './service2.md';

export default function Service() {
    const [mdText, setMdText] = React.useState('');
    const [mdText2, setMdText2] = React.useState('');

    React.useEffect(() => {
        fetch(service)
            .then((response) => {
                if (response.ok) {
                    return response.text();
                }
                return Promise.reject(new Error("Didn't fetch text correctly"));
            })
            .then((text) => {
                setMdText(text);
            })
            .catch((error) => console.error(error));

        fetch(service2)
            .then((response) => {
                if (response.ok) {
                    return response.text();
                }
                return Promise.reject(new Error("Didn't fetch text correctly"));
            })
            .then((text) => {
                setMdText2(text);
            })
            .catch((error) => console.error(error));
    });

    return (
        <Container maxWidth="xl" sx={{ pt: 2, pb: 4, minHeight: 'calc(100vH - 92px)' }}>
            <Typography
                variant="zep-typo--display-6"
                component="p"
                sx={{ mb: 2, display: { xs: 'block', sm: 'none' } }}
            >
                Service Level Agreement (SLA) | COMO
            </Typography>
            <ReactMarkdown>{mdText}</ReactMarkdown>
            <Table
                sx={{ minWidth: 650, border: `1px solid rgba(0, 0, 0, 0.1)`, mb: 3 }}
                aria-label="simple table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Schweregrad 1</TableCell>
                        <TableCell>Schweregrad 2</TableCell>
                        <TableCell>Schweregrad 3</TableCell>
                        <TableCell>Schweregrad 4</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow sx={{ background: '#fff' }}>
                        <TableCell component="th" scope="row">
                            1st Level Support
                        </TableCell>
                        <TableCell colSpan={4}>Endkunde</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            1st Level Support
                        </TableCell>
                        <TableCell colSpan={4}>Endkunde</TableCell>
                    </TableRow>
                    <TableRow sx={{ background: '#fff' }}>
                        <TableCell component="th" scope="row">
                            2nd Level Support
                        </TableCell>
                        <TableCell colSpan={4}>Zeppelin</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            3rd Level Support
                        </TableCell>
                        <TableCell colSpan={4}>Zeppelin</TableCell>
                    </TableRow>
                    <TableRow sx={{ background: '#fff' }}>
                        <TableCell component="th" scope="row">
                            Support
                        </TableCell>
                        <TableCell colSpan={4}>Mo-Fr 08:00 – 17:00 CET</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Reaktionszeiten
                        </TableCell>
                        <TableCell>1 h</TableCell>
                        <TableCell>2 h</TableCell>
                        <TableCell>4 h</TableCell>
                        <TableCell>8 h</TableCell>
                    </TableRow>
                    <TableRow sx={{ background: '#fff' }}>
                        <TableCell component="th" scope="row">
                            Ziel Wiederherstellungszeit
                        </TableCell>
                        <TableCell colSpan={4}>24 h</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Wiederherstellungspunkt (RPO)
                        </TableCell>
                        <TableCell colSpan={4}>24 h</TableCell>
                    </TableRow>
                    <TableRow sx={{ background: '#fff' }}>
                        <TableCell component="th" scope="row">
                            Wartungsfenster und Ausfallzeiten Produktiv Systeme
                        </TableCell>
                        <TableCell colSpan={4}>
                            Mi. 18:00 – 22:00 Sa – So 24 hMi. 18:00 – 22:00 Sa – So 24 h
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            SLA-Verfügbarkeit
                        </TableCell>
                        <TableCell colSpan={4}>Produktiv System: 99,5%.</TableCell>
                    </TableRow>
                    <TableRow sx={{ background: '#fff' }}>
                        <TableCell component="th" scope="row" colSpan={5}>
                            Die Serververfügbarkeitsgarantie gilt nicht während der Wartungsfenster.
                            Die Verfügbarkeit von Zeppelin-Systemen wird am Ende eines jeden Monats
                            wie folgt gemessen: Verfügbarkeit in % = 100*(gesamte verfügbare - Zeit
                            relevante Ausfallzeit) /gesamte verfügbare Zeit Die gesamte verfügbare
                            Zeit wird auf der Grundlage der vereinbarten Servicezeit (pro Quartal)
                            abzüglich der im Voraus vereinbarten Wartungsarbeiten (Wartungsfenster,
                            geplante Wartung) ermittelt. Die &quot;relevante Ausfallzeit&quot; ist
                            definiert als die Zeitspanne (innerhalb der vereinbarten Betriebszeiten)
                            zwischen der Meldung einer Störung und ihrer Behebung.
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <ReactMarkdown>{mdText2}</ReactMarkdown>
            <Table
                sx={{ minWidth: 650, border: `1px solid rgba(0, 0, 0, 0.1)`, mb: 3 }}
                aria-label="simple table"
            >
                <TableHead>
                    <TableRow sx={{ background: 'rgba(0, 0, 0, 0.24)' }}>
                        <TableCell>Environment</TableCell>
                        <TableCell>Start</TableCell>
                        <TableCell>Ende</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Produktiv Systeme
                        </TableCell>
                        <TableCell>Mi. 18:00</TableCell>
                        <TableCell>Mi. 22:00</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Container>
    );
}
