import * as React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import PrioCard from './PrioCard';
import PrioCardMinimal from './PrioCardMinimal';

export default function PrioSection({ data, variant }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: { xs: 'wrap', md: 'nowrap' },
                justifyContent: 'space-between',
            }}
        >
            {['1', '2', '3', '0'].map((elem) => (
                <React.Fragment key={elem}>
                    {variant === 'small' ? (
                        <PrioCardMinimal
                            numEvents={data ? data.filter((item) => item.prio === elem).length : 0}
                            prio={elem}
                        />
                    ) : (
                        <PrioCard
                            numEvents={data ? data.filter((item) => item.prio === elem).length : 0}
                            prio={elem}
                        />
                    )}
                </React.Fragment>
            ))}
        </Box>
    );
}

PrioSection.propTypes = {
    data: PropTypes.array,
    variant: PropTypes.oneOf(['small', 'large']),
};
