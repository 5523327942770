import React, { createContext, useContext, useMemo } from 'react';
import FetchError from '../common/FetchError';
import Loading from '../components/loading/Loading';
import { CustomerListDTO } from '../dto/customer.dto';
import { useQuery } from '../utils/api';

const CustomerListContext = createContext();

export const CustomerListContextProvider = (props) => {
    const customerListDTO = useMemo(() => new CustomerListDTO(), []);
    const { data: customers, error, isLoading } = useQuery(customerListDTO);

    if (isLoading) return <Loading height={50} />;
    if (error) return <FetchError message={error.message} />;

    const customerList = customers.map((customer) => {
        return {
            id: customer.userId,
            realname: customer.realname,
            company: customer.company,
            companyName: customer.company.name,
            companyIds: customer.company.ids.toString(),
        };
    });

    return (
        <CustomerListContext.Provider value={{ customerList }}>
            {props.children}
        </CustomerListContext.Provider>
    );
};

export const useCustomerList = () => useContext(CustomerListContext);
