import * as React from 'react';
import { IconButton, Popover, Typography } from '@mui/material';

import InfoIcon from '@zlab-de/zel-react-icons/EssentialInfo';

export default function MachineOverviewPopover() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton aria-describedby={id} variant="contained" onClick={handleClick}>
                <InfoIcon style={{ height: 18, width: 18 }} />
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography variant="zep-typo--body-default" sx={{ p: 2, width: 200 }}>
                    Betriebsstunden werden nur einmal am Tag aktualisiert und spiegeln
                    möglicherweise nicht die neuesten Maschineninformationen wider.
                </Typography>
            </Popover>
        </>
    );
}
