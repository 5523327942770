import {Box, Typography} from '@mui/material';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import * as React from 'react';
import {UVV} from '../../constants/inspection';
import { isUserOrAdmin, isUser} from '../../constants/role';
import {getInspectionStatus} from '../../core/machine';
import PartsButton from '../orders/PartsButton';
import {useAuth} from '../../contexts/AuthContext';
import ServiceContactButton from '../detailPage/ServiceContactButton';
import MaintenanceButton from "../detailPage/MaintenanceButton";
import CATMaintenanceButton from "../detailPage/CATMaintenanceButton";

export default function InspectionCard({ machine, is_overdue }) {
    const { user } = useAuth();
    const showPartsButton = !!machine.orderNr && machine.variant !== UVV && isUserOrAdmin(user.role);

    const maintainanceStatus = getInspectionStatus(machine);

    return (
        <Box
            sx={{
                display: 'flex',
                '&:hover #inspectionCartHeader': {
                    background: (theme) => theme.other.gray[400],
                },
                '&:hover #inspectionCartHeaderArrow': {
                    borderRight: (theme) => `8px solid ${theme.other.gray[400]}`,
                },
                '&:hover #inspectionCartButton': {
                    display: 'flex',
                },
            }}
        >
            <Box
                id="inspectionCartHeaderArrow"
                sx={{
                    width: 0,
                    height: 0,
                    position: 'relative',
                    top: 10,
                    borderTop: '8px solid transparent',
                    borderBottom: '8px solid transparent',
                    borderRight: (theme) => `8px solid ${theme.other.gray[300]}`,
                }}
            />
            <Box
                sx={{
                    borderRadius: 2,
                    overflow: 'hidden',
                    mb: 4,
                    width: '100%',
                    boxShadow: '0px 16px 28px 0px rgba(12, 17, 34, 0.1)',
                }}
            >
                <Box
                    id="inspectionCartHeader"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        background: (theme) => theme.other.gray[300],
                        py: 1,
                        px: 2,
                    }}
                >
                    <Typography
                        variant="zep-typo--body-small"
                        component="p"
                        sx={{ textTransform: 'capitalize' }}
                    >
                        {machine.variant}
                    </Typography>
                    <Typography
                        variant="zep-typo--body-small"
                        component="p"
                        sx={{ textTransform: 'capitalize' }}
                    >
                        {`${machine.make}-${machine.model} (${machine.serialNumber}) ${
                            machine.nickname ? `(${machine.nickname})` : ''
                        }`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        p: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                    }}
                >
                    <div>
                        <Typography component="p">
                            {`Betriebsstunden: ${
                                machine.hours
                                    ? Math.round(machine.hours).toLocaleString('de-DE')
                                    : 'Unbekannt'
                            }`}
                        </Typography>
                        <Typography component="p">
                            <Box
                                component="span"
                                sx={{
                                    color: (theme) =>
                                        machine.isOverdue ? theme.other.dkRed : 'inherit',
                                }}
                            >
                                {`Nächste ${machine.variant} in:`}{' '}
                                {machine.variant === UVV
                                    ? maintainanceStatus.uvv
                                    : maintainanceStatus.maintenance}
                            </Box>
                        </Typography>
                    </div>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                        }}
                    >
                        { is_overdue?
                            <>
                                { machine.variant ==='Wartung' ?
                                    <>
                                        {machine.make !== "CAT" ?
                                            <Box sx={{
                                                display: 'flex',
                                                gap: 1,
                                            }}>
                                                <MaintenanceButton machine={machine}/>
                                                {showPartsButton ?
                                                    <PartsButton
                                                        title="Wartungsteile bestellen"
                                                        successMessage="Wartungsteile wurden dem Warenkorb im Zeppelin Kundenportal hinzugefügt."
                                                        order={pick(machine, ['orderNr', 'jobNr', 'jobSuffix'])}
                                                        machine={pick(machine, ['make', 'model', 'serialNumber'])}
                                                    />
                                                    : null
                                                }
                                            </Box>
                                            : <CATMaintenanceButton machine={machine}/>}
                                    </>: <MaintenanceButton machine={machine}/>
                                }
                            </>
                            :
                            <>
                                <ServiceContactButton machine={machine} isUser={isUser(user.role)}/>
                            </>
                        }
                    </Box>
                </Box>
                {is_overdue ?
                    <Box sx={{
                        marginTop: 0,
                        alignItems: 'flex-end',

                        borderRadius: 2,
                        overflow: 'hidden',
                        mb: 4,
                        width: '100%',
                        boxShadow: '0px 16px 28px 0px rgba(12, 17, 34, 0.1)',

                    }}>
                        <ServiceContactButton machine={machine} as_button={true} isUser={isUser(user.role)}/>
                    </Box> : null }
            </Box>
        </Box>
    );
}

InspectionCard.propTypes = {
    machine: PropTypes.object.isRequired,
};
