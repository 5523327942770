import Layout from '../../components/layout/Layout';
import { pageTitle } from '../../routes';
import TermsConditions from './TermsConditions';

export default function TermsConditionsPage() {
    return (
        <Layout title={pageTitle.legal}>
            <TermsConditions />
        </Layout>
    );
}
