import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useMediaQuery, Container, Typography, Box } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import Seo from './Seo';

export default function Layout({ children, title, description }) {
    const location = useLocation();
    const breakpointXl = useMediaQuery((theme) => theme.breakpoints.up('xl'));

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minHeight: '100vH',
                background: (theme) => theme.other.gray[200],
            }}
        >
            <Seo title={title} description={description} />
            <Header />

            <Container
                disableGutters={breakpointXl}
                sx={{
                    my: { xs: 2, md: 4, lg: 6 },
                }}
            >
                <Box sx={{ textAlign: 'center' }}>
                    <Typography sx={{ mb: 2 }} component="h1" variant="zep-typo--display-6">
                        {location.pathname.replace('_', ' ').replace('/', '')}
                    </Typography>
                </Box>

                {children}
            </Container>

            <Footer />
        </Box>
    );
}

Layout.propTypes = {
    children: PropTypes.element.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
};
