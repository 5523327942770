import SendIcon from '@mui/icons-material/Send';
import {LoadingButton} from '@mui/lab';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
    Link
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from '@mui/x-date-pickers-pro/AdapterDateFns';
import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {BootstrapDialogTitle} from '../../common/BootstrapDialogTitle';
import FetchError from '../../common/FetchError';
import Spinner from '../../common/Spinner';
import {useAlert} from '../../contexts/AlertContext';
import {useAnalytics} from '../../contexts/AnalyticsContext';
import {useCustomerSelect} from '../../contexts/CustomerSelectContext';
import {TicketCreateDTO} from '../../dto/ticket.dto';
import {useMutation} from '../../utils/api';
import {useBranchList} from '../../contexts/BranchListContext';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(3),
    },
}));

const DialogHeading = styled(Typography)({
    color: '#282828',
    fontWeight: 400,
});

const DialogInputLabel = styled(FormLabel)({
    color: 'rgba(40,40,40,1)',
    fontWeight: 400,
    fontSize: '12px',
});

const FormHelperTextStyle = {
    sx: {
        color: 'rgba(90,90,90,1)',
        fontWeight: 400,
        fontSize: '12px',
    },
};

const ExplainText = styled(Typography)({
    color: 'rgba(40,40,40,1)',
    fontSize: '14px',
    fontWeight: 400,
});

function FormWrapper({ children }) {
    return <Box sx={{ padding: 10 }}>{children}</Box>;
}

const PHONE_NUMBER_REGEX = /^[\d\s+-]+$/;
const CONTACT_CONTENT_MAX_LENGTH = 60;
const SELECTPLACEHOLDER = <em style={{color: "gray", fontStyle: "normal"}}>Bitte auswählen ...</em>
const typeOptions = [
    {
        value: 'uvv',
        label: 'UVV-Prüfung',
    },
    {
        value: 'maintenance',
        label: 'Wartung',
    },
    {
        value: 'repair',
        label: 'Reparatur/Fehlermeldung',
    },
];

function TicketContactDialogContent({ machine, company, branchListResponse, handleClose }) {
    const { openAlert } = useAlert();
    const { trackEvent } = useAnalytics();
    const [dateValue, setDateValue] = useState(new Date());
    const { customerName: contact } = useCustomerSelect();

    const { data, error, isLoading: isLoadingBranches } = branchListResponse[1];
    const [sendTicket, { isLoading: isSendingTicket }] = useMutation();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        watch,
    } = useForm();

    if (isLoadingBranches) return <FormWrapper children={<Spinner />} />;
    if (error) return <FormWrapper children={<FetchError message={error.message} />} />;
    if (data.branches.length === 0) return <FormWrapper children={<p>No branches found</p>} />;

    const contactContentLength = watch('freetext')?.length || 0;

    const branchOptions = Object.entries(data.branches).map(([key, value]) => ({
        value: key,
        label: value,
    }));

    const machineInfo = [
        {
            label: 'Hersteller, Modell:',
            value: `${machine.make}-${machine.model}`,
        },
        {
            label: 'Seriennummern:',
            value: machine.serialNumber,
        },
        {
            label: 'Kundenname:',
            value: company.name,
        },
        {
            label: 'Kundennummer:',
            value: company.ids[0],
        },
    ];

    const trackSendTicket = () => {
        const tagManagerArgs = {
            dataLayer: {
                machineName: `${machine.model}-${machine.make}`,
                serialNumber: machine.serialNumber,
                menuItem: 'service contact',
                event: 'button_click',
            },
        };
        trackEvent(tagManagerArgs);
    };

    const onSubmit = async (data) => {
        const ticketCreateBody = {
            make: machine.make,
            model: machine.model,
            serialnumber: machine.serialNumber,
            companyname: company.name,
            companyid: company.ids[0],
            phonenumber: data.phonenumber,
            contact: data.contact,
            branch: data.branch,
            location: data.location,
            type: data.type,
            preferreddate: data.preferreddate,
            freetext: data.freetext,
        };

        await sendTicket(new TicketCreateDTO(ticketCreateBody), {
            onSuccess: () => {
                trackSendTicket();
                openAlert({
                    message: 'Anfrage erfolgreich. Sie erhalten in Kürze eine E-Mail.',
                    severity: 'success',
                });
                handleClose();
            },
            onError: (error) => {
                openAlert({
                    message: error.message,
                    severity: 'error',
                });
            },
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent sx={{ py: '0 !important' }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        background: (theme) => theme.other.gray[200],
                        padding: '1rem',
                        marginBottom: '1.5rem',
                    }}
                >
                    {machineInfo.map((item) => (
                        <Box key={item.label} sx={{ width: '25%' }}>
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '14px',
                                    marginBottom: '0.2rem',
                                }}
                            >
                                {item.label}
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '1rem',
                                    color: 'rgba(0, 0, 0, 0.6)',
                                }}
                            >
                                {item.value}
                            </Typography>
                        </Box>
                    ))}
                </Box>

                <Box sx={{ marginBottom: '28px' }}>
                    <DialogHeading sx={{ fontSize: '18px', marginBottom: '1rem' }}>
                        Kontakt für Rückfragen
                    </DialogHeading>
                    <Grid container spacing={{ xs: 2 }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <DialogInputLabel>Telefonnummer*</DialogInputLabel>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    error={!!errors?.phonenumber}
                                    helperText={
                                        errors?.phonenumber?.type === 'pattern'
                                            ? `Ungültiges Telefonnummernformat.`
                                            : errors?.phonenumber?.type === 'required'
                                            ? `Telefonnummer ist erforderlich.`
                                            : `Format: +4912345678`
                                    }
                                    FormHelperTextProps={FormHelperTextStyle}
                                    {...register('phonenumber', {
                                        required: true,
                                        pattern: PHONE_NUMBER_REGEX,
                                    })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <DialogInputLabel>Ansprechpartner*</DialogInputLabel>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    defaultValue={contact}
                                    error={!!errors?.contact}
                                    helperText={
                                        errors?.contact?.message ||
                                        'Kann vom Account-Inhaber abweichen'
                                    }
                                    FormHelperTextProps={FormHelperTextStyle}
                                    {...register('contact', {
                                        required: 'Ansprechpartner ist erforderlich',
                                    })}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>

                <Box>
                    <Typography
                        variant="h2"
                        sx={{fontWeight: 400, fontSize: '1.1rem', marginBottom: '1rem'}}
                    >
                        Details
                    </Typography>
                    <Grid container spacing={{xs: 2}}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <DialogInputLabel>
                                    Nächstgelegener Service Standort*
                                </DialogInputLabel>
                                <Select
                                    size="small"
                                    defaultValue={"-1"}
                                    error={!!errors?.branch}
                                    {...register('branch', {
                                        pattern: /^(?!.*(-1))/
                                    })}
                                >
                                    <MenuItem value="-1" disabled>
                                        {SELECTPLACEHOLDER}
                                    </MenuItem>
                                    {branchOptions.map(({value, label}) => (
                                        <MenuItem key={value} value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors?.branch && (
                                    <FormHelperText
                                        sx={{
                                            color: '#ff6562',
                                            fontWeight: 400,
                                            fontSize: '12px',
                                        }}
                                    >
                                        {`Nächstgelegener Service Standort ist erforderlich`}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <DialogInputLabel>
                                    Maschinenstandort o. Adresse (optional)
                                </DialogInputLabel>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    helperText="z.B. Musterstraße, A9 Höhe Leipzig, etc."
                                    FormHelperTextProps={FormHelperTextStyle}
                                    {...register('location')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <DialogInputLabel>Art der Meldung*</DialogInputLabel>
                                <Select
                                    size="small"
                                    defaultValue={"-1"}
                                    error={!!errors?.type}
                                    {...register('type', {
                                        pattern: /^(?!.*(-1))/
                                    })}
                                >
                                    <MenuItem value="-1" disabled>
                                        {SELECTPLACEHOLDER}
                                    </MenuItem>
                                    {typeOptions.map(({ value, label }) => (
                                        <MenuItem key={value} value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors?.type && (
                                    <FormHelperText
                                        sx={{
                                            color: '#ff6562',
                                            fontWeight: 400,
                                            fontSize: '12px',
                                        }}
                                    >
                                        {`Art der Meldung ist erforderlich`}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <DialogInputLabel>Anmerkungen (optional)</DialogInputLabel>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    helperText={`${contactContentLength}/${CONTACT_CONTENT_MAX_LENGTH}`}
                                    FormHelperTextProps={FormHelperTextStyle}
                                    multiline
                                    rows={3}
                                    inputProps={{
                                        maxLength: CONTACT_CONTENT_MAX_LENGTH,
                                    }}
                                    {...register('freetext', {
                                        maxLength: CONTACT_CONTENT_MAX_LENGTH,
                                    })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <DialogInputLabel>Wunschtermin*</DialogInputLabel>
                                <Controller
                                    name="preferreddate"
                                    defaultValue={dateValue}
                                    control={control}
                                    rules={{
                                        required: 'Wunschtermin ist erforderlich',
                                    }}
                                    render={({ field: { onChange, ...restField } }) => (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                inputFormat="dd.MM.yyyy"
                                                onChange={(event) => {
                                                    onChange(event);
                                                    setDateValue(event);
                                                }}
                                                renderInput={(startProps) => (
                                                    <TextField
                                                        {...startProps}
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                )}
                                                {...restField}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                                {errors?.preferreddate && (
                                    <FormHelperText
                                        sx={{
                                            color: '#ff6562',
                                            fontWeight: 400,
                                            fontSize: '12px',
                                        }}
                                    >
                                        {errors?.preferreddate?.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>

                <ExplainText sx={{ marginTop: '1.5rem', marginBottom: '8px' }}>
                    *Mit Sternchen versehene Felder sind Pflichtfelder
                </ExplainText>
                <ExplainText>
                    Die Niederlassung wird sich schnellstmöglich mit Ihnen wegen eines genauen
                    Termins in Verbindung setzen.
                </ExplainText>
                <ExplainText>Es handelt sich hierbei um eine unverbindliche Anfrage!</ExplainText>
            </DialogContent>
            <DialogActions
                sx={{
                    flexDirection: 'row-reverse',
                    paddingTop: '16px !important',
                }}
            >
                <LoadingButton
                    type="submit"
                    disabled={isSendingTicket}
                    variant="contained"
                    size="large"
                    sx={{
                        fontSize: '1.1rem',
                        height: '2.5rem',
                    }}
                    endIcon={isSendingTicket ? <SendIcon /> : undefined}
                    loadingPosition={isSendingTicket ? 'end' : undefined}
                    loading={isSendingTicket}
                >
                    <Typography
                        sx={{
                            color: 'rgba(40,40,40,1)',
                            fontSize: '16px',
                            fontWeight: 500,
                            letterSpacing: '0.8px',
                            lineHeight: '32px',
                            textAlign: 'center',
                        }}
                    >
                        Unverbindliche Anfrage senden
                    </Typography>
                </LoadingButton>
            </DialogActions>
        </form>
    );
}

export default function ServiceContactButton({ machine, as_button, isUser}) {

    const showAsButton = !as_button;
    const { customerCompany: company } = useCustomerSelect();
    const [open, setOpen] = useState(false);
    const { branchListResponse } = useBranchList();
    const [fetchData, { data }] = branchListResponse;

    const handleOpen = () => {
        if (!data) fetchData();
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    if (!isUser || !company) return null;

    return (
        <>
        { showAsButton ?
            <Button onClick={handleOpen} variant="contained" size="large">
                Service kontaktieren
            </Button>
            :
            <Link sx={{
                position: 'absolute', right: '1rem', fontSize: '14px', cursor: 'pointer'}}
                  onClick={handleOpen}>
                <u>Service kontaktieren</u></Link>
        }
            <BootstrapDialog fullWidth maxWidth="md" onClose={handleClose} open={open}>
                <BootstrapDialogTitle onClose={handleClose}>
                    <DialogHeading
                        sx={{
                            fontSize: '24px',
                        }}
                    >
                        Service kontaktieren
                    </DialogHeading>
                </BootstrapDialogTitle>
                <TicketContactDialogContent
                    machine={machine}
                    company={company}
                    branchListResponse={branchListResponse}
                    handleClose={handleClose}
                />
            </BootstrapDialog>
        </>
    );
}
