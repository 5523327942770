import * as React from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function OilLineChart({ data }) {
    const colors = ['#27166F', '#94C11C', '#4E8095', '#FFE72D', '#FBBA00', '#DD686A', '#00A760'];

    const lines = data ? Object.keys(data[0]).slice(1) : [];

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 60,
                }}
            >
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                {lines.map((elem, idx) => (
                    <Line
                        key={`line${idx}`}
                        type="monotone"
                        dataKey={elem}
                        stroke={colors[idx]}
                        activeDot={{ r: 6 }}
                        // dot={dots[idx]}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
}

OilLineChart.propTypes = {
    data: PropTypes.array,
};
