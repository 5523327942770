import { Box, FormGroup, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { isUser } from '../../constants/role';
import { useAlert } from '../../contexts/AlertContext';
import { useAuth } from '../../contexts/AuthContext';
import { useCustomerDetail } from '../../contexts/CustomerDetailContext';
import { useCustomerSelect } from '../../contexts/CustomerSelectContext';
import { CustomerNotificationUpdateDTO } from '../../dto/customer.dto';
import { UserNotificationUpdateDTO } from '../../dto/user.dto';
import { useMutation } from '../../utils/api';
import ReportCheckbox from './ReportCheckbox';
import { getDefaultTabValue } from './ReportsPage';

export default function MachineReports() {
    const [searchParams] = useSearchParams();
    const { openAlert } = useAlert();
    const { user } = useAuth();
    const { customerId } = useCustomerSelect();
    const { selectedCustomer, refetch } = useCustomerDetail();
    const [updateNotificationSettings] = useMutation();

    const reports = [
        { id: 'UVV', label: 'UVV' },
        { id: 'Inspection', label: 'Inspektion' },
    ];

    const tabValue = getDefaultTabValue(searchParams.get('tab'), user);
    const isUpdateForAdmin = tabValue === 1;
    const reportKeyPrefix = isUpdateForAdmin ? 'adminNotifications' : 'notifications';

    const getReportChecked = (reportId, reportKeySuffix = '') => {
        return selectedCustomer[`${reportKeyPrefix}${reportId}${reportKeySuffix}`] === 'ON';
    };

    const getNotificationUpdateDTO = (newValue) => {
        return isUser(user.role)
            ? new UserNotificationUpdateDTO(newValue)
            : new CustomerNotificationUpdateDTO(newValue);
    };

    const handleChange = async (newStatus, id) => {
        const newValue = [newStatus ? 'ON' : 'OFF', `${reportKeyPrefix}${id}`, customerId];
        const notificationUpdateDTO = getNotificationUpdateDTO(newValue);

        await updateNotificationSettings(notificationUpdateDTO, {
            onSuccess: () => {
                openAlert({
                    message: 'Einstellungen erfolgreich aktualisiert',
                    severity: 'success',
                });
                refetch();
            },
            onError: (error) => {
                openAlert({
                    message: error.message,
                    severity: 'error',
                });
            },
        });
    };

    return (
        <>
            {reports.map((elem) => (
                <Box sx={{ mb: 4 }} key={elem.id}>
                    <Typography
                        component="p"
                        variant="zep-typo--headline-5_alt"
                        sx={{ fontSize: 16, mb: 1 }}
                    >
                        {`Maschinen mit einer bevorstehenden ${elem.label}`}
                    </Typography>

                    <FormGroup>
                        <ReportCheckbox
                            label={`Bei denen in den nächsten 25 Tagen eine ${elem.label} ansteht (einmaliger Report 25 Tage vorher)`}
                            id={elem.id}
                            onChange={handleChange}
                            checked={getReportChecked(elem.id)}
                        />
                        <ReportCheckbox
                            label={`1x wöchentlicher Report mit einer überfälligen ${elem.label}`}
                            id={`${elem.id}2`}
                            onChange={handleChange}
                            checked={getReportChecked(elem.id, '2')}
                        />
                    </FormGroup>
                </Box>
            ))}
        </>
    );
}
