import * as React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Element } from 'react-scroll';

import EventCard from '../eventCard/EventCard';
import PrioHeader from '../prio/PrioHeader';

export default function DetailPageEventsSection({ data }) {
    return (
        <>
            {['1', '2', '3', '0'].map((elem) => {
                if (data.filter((item) => item.prio === elem).length === 0) return null;
                return (
                    <Element name={elem} key={elem}>
                        <Box key={elem}>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    mb: 1,
                                }}
                            >
                                <PrioHeader prio={elem} />
                            </Box>
                            {data
                                .filter((item) => item.prio === elem)
                                .map((eventCode) => (
                                    <EventCard
                                        data={eventCode.data.sort(
                                            (a, b) => new Date(a).getTime() - new Date(b).getTime()
                                        )}
                                        prio={eventCode.prio}
                                        key={eventCode.data[0].unique_code}
                                        showSparePartsButton
                                    />
                                ))}
                        </Box>
                    </Element>
                );
            })}
        </>
    );
}

DetailPageEventsSection.propTypes = {
    data: PropTypes.array.isRequired,
};
