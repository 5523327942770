import Layout from '../../components/layout/Layout';
import { pageTitle } from '../../routes';
import Impressum from './Impressum';

export default function ImprintPage() {
    return (
        <Layout title={pageTitle.imprint}>
            <Impressum />
        </Layout>
    );
}
