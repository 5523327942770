import { errorMessages } from '../constants/error';
import { DTO } from './base.dto';

export class CustomerListDTO extends DTO {
    constructor() {
        super({
            apiRoute: 'api/v1/customers',
            fetchErrorMessage: errorMessages.fetchCustomers,
        });
    }
}

export class CustomerDetailDTO extends DTO {
    constructor(customerId) {
        super({
            apiRoute: `api/v1/users/metadata/${customerId}`,
            fetchErrorMessage: errorMessages.fetchSelectedCustomer,
        });
    }
}

export class CustomerNotificationUpdateDTO extends DTO {
    constructor(values) {
        super({
            apiRoute: 'api/v1/users/notifications/admin',
            fetchErrorMessage: errorMessages.updateCustomerNotifications,
            fetchInit: {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    flag: values[0],
                    type: values[1],
                    userId: values[2],
                }),
            },
        });
    }
}
