import { DTO } from './base.dto';
import { errorMessages } from '../constants/error';

export class AuthInfoDTO extends DTO {
    constructor() {
        super({
            apiRoute: 'api/info',
            fetchErrorMessage: errorMessages.fetchAuthInfo,
            fetchInit: {
                method: 'POST',
            },
        });
    }
}

export class UserLoginDTO extends DTO {
    constructor(username, password) {
        super({
            apiRoute: 'api/token',
            fetchErrorMessage: '',
            fetchInit: {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    username,
                    password,
                }),
            },
        });
    }
}
