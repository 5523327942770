import { Box, IconButton } from '@mui/material';
import { DataGridPro, deDE } from '@mui/x-data-grid-pro';
import CloseIcon from '@zlab-de/zel-react-icons/EssentialCloseMini';
import { useAnalytics } from '../../contexts/AnalyticsContext';
import { useMachine } from '../../contexts/DashboardDataContext';
import MachineListItem from './MachineListItem';
import {generateRandom} from '../../utils/generateRandom';

export default function MachineList() {
    const { trackEvent } = useAnalytics();
    const { machines, selectedMachine, setSelectedMachine } = useMachine();

    const machineList = selectedMachine ? [selectedMachine] : machines;
    const rows = machineList.map((elem) => ({
        id: elem._key,
        ...elem,
    }));

    const columns = [
        {
            field: 'model',
            headerName: '',
            headerClassName: 'data-grid--header',
            width: 400,
            maxHeight: 'auto',
            renderCell: (params) => (
                <MachineListItem
                    machine={params.row}
                    isSelected={params.row._key === selectedMachine?._key}
                />
            ),
        },
    ];

    const trackMachineClick = (machine) => {
        const tagManagerArgs = {
            dataLayer: {
                serialNumber: machine.serialNumber,
                machineName: `${machine.make}-${machine.model}`,
                event: 'map_list_item_click',
            },
        };
        trackEvent(tagManagerArgs);
    };

    const handleSelectMachine = (value) => {
        const selectedMachine = value.row;
        setSelectedMachine(selectedMachine);
        trackMachineClick(selectedMachine);
    };

    return (
        <>
            {selectedMachine && (
                <Box sx={{ textAlign: 'right', mb: '-50px' }}>
                    <IconButton onClick={() => setSelectedMachine(null)} sx={{ zIndex: 200 }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            )}
            <DataGridPro
                autoHeight
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                rows={rows}
                columns={columns}
                maxColumns={1}
                rowHeight={150}
                disableMultipleSelection
                disableColumnMenu
                hideFooterPagination
                onRowClick={handleSelectMachine}
                getRowId={(row) => row.id? row.id : row.serialNumber? row.serialNumber : generateRandom()}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'model', sort: 'asc' }],
                    },
                }}
                sx={{
                    border: 'none',
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: (theme) => theme.other.gray[200],
                    },
                    '& .MuiDataGrid-columnHeader:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-columnHeader:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-iconSeparator': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-row': {
                        p: 0,
                        height: '150px',
                        maxHeight: '150px !important',
                    },
                    '& .MuiDataGrid-row.Mui-selected': {
                        background: 'transparent',
                    },
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer',
                        background: 'white',
                    },
                    '& .MuiDataGrid-cell': {
                        overflow: 'hidden',
                        p: 0,
                    },
                    '& .MuiDataGrid-footerContainer': {
                        minHeight: 0,
                    },
                    '& .data-grid--header': {
                        fontFamily: '"Roboto Condensed", sans-serif',
                        letterSpacing: '1.25px',
                        textTransform: 'uppercase',
                    },
                }}
            />
        </>
    );
}
