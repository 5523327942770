import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import OpacityIcon from '@mui/icons-material/Opacity';
import PercentIcon from '@mui/icons-material/Percent';
import SettingsIcon from '@mui/icons-material/Settings';

import oilAnalysisImg from '../../assets/oil-analysis.png';
import OilLineChart from './OilLineChart';

export default function OilAnalysisDashboard({ data }) {
    const status = typeof data.Bewertung === 'string' ? data.Bewertung : data.Bewertung[0];

    const titles = ['Verunreinigung', 'Ölzustand', 'Ölreinheit', 'Viskosität in cST'];
    const icons = [<PercentIcon />, <SettingsIcon />, <BubbleChartIcon />, <OpacityIcon />];

    const elementData = [];
    const additivesData = [];
    const sonstData = [];

    if (typeof data.datum === 'string') {
        elementData.push({
            date: data.datum,
            cu: data.cu,
            fe: data.fe,
            cr: data.cr,
            pb: data.pb,
            al: data.al,
            si: data.si,
        });
        additivesData.push({
            date: data.datum,
            zn: data.zn,
            mg: data.mg,
            ca: data.ca,
            p: data.p,
        });
        sonstData.push({
            date: data.datum,
            ba: data.ba,
            sn: data.sn,
            ni: data.ni,
            mo: data.mo,
            na: data.na,
            k: data.k,
        });
    } else {
        [5, 4, 3, 2, 1, 0].forEach((elem) => {
            if (data.datum[elem]) {
                elementData.push({
                    date: data.datum[elem],
                    cu: data.cu[elem],
                    fe: data.fe[elem],
                    cr: data.cr[elem],
                    pb: data.pb[elem],
                    al: data.al[elem],
                    si: data.si[elem],
                });
            }
        });
        [5, 4, 3, 2, 1, 0].forEach((elem) => {
            if (data.datum[elem]) {
                additivesData.push({
                    date: data.datum[elem],
                    zn: data.zn[elem],
                    mg: data.mg[elem],
                    ca: data.ca[elem],
                    p: data.p[elem],
                });
            }
        });
        [5, 4, 3, 2, 1, 0].forEach((elem) => {
            if (data.datum[elem]) {
                sonstData.push({
                    date: data.datum[elem],
                    ba: data.ba[elem],
                    sn: data.sn[elem],
                    ni: data.ni[elem],
                    mo: data.mo[elem],
                    na: data.na[elem],
                    k: data.k[elem],
                });
            }
        });
    }

    const getStatus = (newValue) => {
        const result =
            newValue === '1'
                ? 'grün'
                : newValue === '2'
                ? 'gelb'
                : newValue === '3'
                ? 'rot'
                : 'Unbekannt';
        return result;
    };

    const formatYesNo = (newValue) => {
        const text = newValue === 'N' ? 'Nein' : newValue === 'J' ? 'Ja' : '';
        return text;
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    mb: 4,
                    alignItems: 'stretch',
                }}
            >
                <Box
                    sx={{
                        flexBasis: { xs: '100%', md: '49%' },
                        background: (theme) => theme.color.global.white,
                        borderRadius: 1,
                        boxShadow: `0px 16px 28px 0px rgba(240, 173, 78, 0.1)`,
                        p: 4,
                        mb: { xs: 2, md: 0 },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mb: 2,
                            alignItems: 'flex-end',
                        }}
                    >
                        <Box sx={{ flexBasis: '40%' }}>
                            <Typography variant="zep-typo--headline-4_alt" component="p">
                                Ölanalyse
                            </Typography>
                            <Typography
                                variant="zep-typo--headline-6_alt"
                                component="p"
                                sx={{
                                    mb: 2,
                                    color: (theme) =>
                                        status === '1'
                                            ? theme.other.green
                                            : status === '2'
                                            ? theme.other.prio2.main
                                            : status === '3'
                                            ? theme.other.red
                                            : theme.other.prio0.main,
                                }}
                            >
                                {`Status: ${getStatus(status)}`}
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography
                                    variant="zep-typo--body--small"
                                    component="p"
                                    sx={{ fontWeight: 700 }}
                                >
                                    Labornummer:
                                </Typography>
                                <Typography variant="zep-typo--body--small" component="p">
                                    {typeof data.labornummer === 'string'
                                        ? data.labornummer
                                        : data.labornummer[0]}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography
                                    variant="zep-typo--body--small"
                                    component="p"
                                    sx={{ fontWeight: 700 }}
                                >
                                    Öl:
                                </Typography>
                                <Typography variant="zep-typo--body--small" component="p">
                                    {`${
                                        typeof data.oelmarke === 'string'
                                            ? data.oelmarke
                                            : data.oelmarke[0]
                                    } - ${
                                        typeof data.oelklasse === 'string'
                                            ? data.oelklasse
                                            : data.oelklasse[0]
                                    }`}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography
                                    variant="zep-typo--body--small"
                                    component="p"
                                    sx={{ fontWeight: 700 }}
                                >
                                    Datum:
                                </Typography>
                                <Typography variant="zep-typo--body--small" component="p">
                                    {typeof data.datum === 'string' ? data.datum : data.datum[0]}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                flexBasis: '40%',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Box
                                component="img"
                                src={oilAnalysisImg}
                                sx={{ width: 120, height: 120 }}
                            />
                        </Box>
                    </Box>
                    <Typography variant="zep-typo--body--default" component="p" sx={{ mb: 2 }}>
                        {typeof data.gesamtbefund === 'string'
                            ? data.gesamtbefund
                            : data.gesamtbefund[0]}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        flexBasis: { xs: '100%', md: '49%' },
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                    }}
                >
                    {[0, 1, 2, 3].map((elem) => (
                        <Box
                            key={`box${elem}`}
                            sx={{
                                border: (theme) => `1px solid ${theme.palette.secondary.main}`,
                                flexBasis: { xs: '100%', lg: '49%' },
                                mb: { xs: 2, lg: elem < 2 ? 2 : 0 },
                                p: 2,
                                display: 'flex',
                            }}
                        >
                            <Box
                                sx={{
                                    background: (theme) => theme.palette.secondary.main,
                                    borderRadius: '50%',
                                    mr: 2,
                                    width: 50,
                                    height: 50,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {icons[elem]}
                            </Box>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography
                                    variant="zep-typo--display-6"
                                    component="p"
                                    sx={{ my: 1 }}
                                >
                                    {titles[elem]}
                                </Typography>
                                {elem === 0 && (
                                    <div>
                                        <Typography variant="zep-typo--body-small" component="p">
                                            {`Kraffstoff Mineral / Bio: ${
                                                typeof data.kraftstoff === 'string'
                                                    ? data.kraftstoff
                                                    : data.kraftstoff[0]
                                            }`}
                                        </Typography>
                                        <Typography variant="zep-typo--body-small" component="p">
                                            {`Wasser: ${
                                                typeof data.wasser === 'string'
                                                    ? data.wasser
                                                    : data.wasser[0]
                                            }`}
                                        </Typography>
                                    </div>
                                )}
                                {elem === 1 && (
                                    <div>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                width: '100%',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Typography
                                                    variant="zep-typo--body-small"
                                                    component="p"
                                                >
                                                    Ruß
                                                </Typography>
                                                <Typography
                                                    variant="zep-typo--body-small"
                                                    component="p"
                                                >
                                                    {typeof data.soot === 'string'
                                                        ? data.soot
                                                        : data.soot[0]}
                                                </Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Typography
                                                    variant="zep-typo--body-small"
                                                    component="p"
                                                >
                                                    Oxidation
                                                </Typography>
                                                <Typography
                                                    variant="zep-typo--body-small"
                                                    component="p"
                                                >
                                                    {typeof data.oxidation === 'string'
                                                        ? data.oxidation
                                                        : data.oxidation[0]}
                                                </Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Typography
                                                    variant="zep-typo--body-small"
                                                    component="p"
                                                >
                                                    Sulfation
                                                </Typography>
                                                <Typography
                                                    variant="zep-typo--body-small"
                                                    component="p"
                                                >
                                                    {typeof data.sulfation === 'string'
                                                        ? data.sulfation
                                                        : data.sulfation[0]}
                                                </Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Typography
                                                    variant="zep-typo--body-small"
                                                    component="p"
                                                >
                                                    Nitration
                                                </Typography>
                                                <Typography
                                                    variant="zep-typo--body-small"
                                                    component="p"
                                                >
                                                    {typeof data.nitration === 'string'
                                                        ? data.nitration
                                                        : data.nitration[0]}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </div>
                                )}
                                {elem === 2 && (
                                    <div>
                                        <Typography variant="zep-typo--body-small" component="p">
                                            {`NachISO 4406: ${
                                                typeof data.oelreinheit === 'string'
                                                    ? data.oelreinheit
                                                    : data.oelreinheit[0]
                                            }`}
                                        </Typography>
                                        <Typography variant="zep-typo--body-small" component="p">
                                            {`Öl / Filter wechsel: ${formatYesNo(
                                                typeof data.oelwechsel === 'string'
                                                    ? data.oelwechsel
                                                    : data.oelwechsel[0]
                                            )}`}
                                        </Typography>
                                    </div>
                                )}
                                {elem === 3 && (
                                    <div>
                                        <Typography variant="zep-typo--body-small" component="p">
                                            {`40°C: ${
                                                typeof data.vi40 === 'string'
                                                    ? data.vi40
                                                    : data.vi40[0]
                                            }`}
                                        </Typography>
                                        <Typography variant="zep-typo--body-small" component="p">
                                            {`100°C: ${
                                                typeof data.vi100 === 'string'
                                                    ? data.vi100
                                                    : data.vi100[0]
                                            }`}
                                        </Typography>
                                        <Typography variant="zep-typo--body-small" component="p">
                                            {`VI: ${
                                                typeof data.viskositaet === 'string'
                                                    ? data.viskositaet
                                                    : data.viskositaet[0]
                                            }`}
                                        </Typography>
                                    </div>
                                )}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: { xs: 'wrap', md: 'nowrap' },
                    justifyContent: 'space-between',
                    overflow: 'hidden',
                    mb: 2,
                }}
            >
                <Box
                    sx={{
                        flexBasis: { xs: '100%', md: '30%' },
                        background: (theme) => theme.color.global.white,
                        borderRadius: 1,
                        p: 2,
                        height: 350,
                        mb: { xs: 4, md: 0 },
                    }}
                >
                    <Typography variant="zep-typo--headline-6_alt" component="p" sx={{ mb: 2 }}>
                        Elements in ppm
                    </Typography>
                    <OilLineChart data={elementData} />
                </Box>
                <Box
                    sx={{
                        flexBasis: { xs: '100%', md: '30%' },
                        background: (theme) => theme.color.global.white,
                        borderRadius: 1,
                        p: 2,
                        height: 350,
                        mb: { xs: 4, md: 0 },
                    }}
                >
                    <Typography variant="zep-typo--headline-6_alt" component="p" sx={{ mb: 2 }}>
                        Additives in ppm
                    </Typography>
                    <OilLineChart data={additivesData} />
                </Box>
                <Box
                    sx={{
                        flexBasis: { xs: '100%', md: '30%' },
                        background: (theme) => theme.color.global.white,
                        borderRadius: 1,
                        p: 2,
                        height: 350,
                        mb: { xs: 4, md: 0 },
                    }}
                >
                    <Typography variant="zep-typo--headline-6_alt" component="p" sx={{ mb: 2 }}>
                        Sonst in ppm
                    </Typography>
                    <OilLineChart data={sonstData} />
                </Box>
            </Box>
        </>
    );
}

OilAnalysisDashboard.propTypes = {
    data: PropTypes.array,
};
