import {LoadingButton} from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
} from '@mui/material';
import CartIcon from '@zlab-de/zel-react-icons/EcomCart';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {routeConfig} from '../../../../routes';
import {useAuth} from '../../contexts/AuthContext';
import {useCustomerSelect} from '../../contexts/CustomerSelectContext';
import {OrderCreateDTO} from '../../dto/order.dto';
import {useMutation} from '../../utils/api';

export default function PartsButton({ title, successMessage, order, machine, maintenance }) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { user } = useAuth();
    const { customerId } = useCustomerSelect();
    const [createOrder, { isLoading, error, data, reset }] = useMutation();
    const [orderDialogOpen, setOrderDialogOpen] = useState(false);
    const [noCustomerDialogOpen, setNoCustomerDialogOpen] = useState(false);

    const orderedUserId = customerId || searchParams.get('customerId');

    const handleCreateOrder = async () => {
        if (!orderedUserId) {
            setNoCustomerDialogOpen(true);
            return;
        }

        if (data || error) {
            reset();
        }

        setOrderDialogOpen(true);
        const orderCreateDTO = new OrderCreateDTO(orderedUserId, order, machine);
        await createOrder(orderCreateDTO);
    };

    const handleCloseOrderDialog = () => {
        setOrderDialogOpen(false);
    };

    const gotoOverviewPage = () => {
        navigate(routeConfig.app.index);
    };

    const comoKupoUrlOrder = user.como_kupo_url_order;
    const isSuccess = data != null && data.result != null;

    return (
        <>
            <LoadingButton
                variant="contained"
                startIcon={ !!maintenance? <OpenInNewIcon /> : <CartIcon />}
                onClick={handleCreateOrder}
                loadingPosition="start"
                loading={isLoading}
            >
                {title}
            </LoadingButton>
            <Dialog
                open={orderDialogOpen}
                onClose={handleCloseOrderDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

                {isLoading && (
                    <DialogContent>
                        <LinearProgress />
                        <DialogContentText sx={{ mt: 1 }}>
                            Bitte warten Sie, bis die Artikel zum Warenkorb hinzugefügt wurden. Dies
                            kann mehrere Minuten in Anspruch nehmen.
                        </DialogContentText>
                    </DialogContent>
                )}

                {error && (
                    <DialogContent>
                        <DialogContentText
                            id="alert-dialog-description"
                            sx={{ color: (theme) => theme.other.red }}
                        >
                            {error.message}
                        </DialogContentText>
                    </DialogContent>
                )}

                {isSuccess && (
                    <DialogContent>
                        <DialogContentText
                            id="alert-dialog-description"
                            sx={{ color: (theme) => theme.palette.text.primary }}
                        >
                            {successMessage}
                        </DialogContentText>
                    </DialogContent>
                )}

                {isSuccess && (
                    <DialogActions>
                        <Button onClick={handleCloseOrderDialog}>Schliessen</Button>
                        {comoKupoUrlOrder && (
                            <Button
                                variant="contained"
                                component="a"
                                href={comoKupoUrlOrder}
                                target="_blank"
                                rel="noopener noreferrer"
                                size="large"
                                sx={{
                                    color: 'rgba(0, 0, 0, 0.64)',
                                    '&hover': { color: 'rgba(0, 0, 0, 0.84)' },
                                }}
                                autoFocus
                            >
                                Zum Kundenportal
                            </Button>
                        )}
                    </DialogActions>
                )}
            </Dialog>
            <Dialog open={noCustomerDialogOpen} onClose={() => setNoCustomerDialogOpen(false)}>
                <DialogTitle>Kein Kunde ausgewählt</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bitte wählen Sie einen Kunden aus, um die Bestellung zu erstellen.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNoCustomerDialogOpen(false)}>Schliessen</Button>
                    <Button onClick={gotoOverviewPage} variant="contained" autoFocus>
                        Gehen Sie zur Kundenseite
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

PartsButton.propTypes = {
    title: PropTypes.string,
    machine: PropTypes.shape({
        make: PropTypes.string,
        model: PropTypes.string,
        serialNumber: PropTypes.string,
    }),
    order: PropTypes.exact({
        orderNr: PropTypes.string,
        jobNr: PropTypes.string,
        jobSuffix: PropTypes.string,
    }),
};
