import AppsIcon from '@mui/icons-material/Apps';
import ListIcon from '@mui/icons-material/List';
import {
    Box,
    Container,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useMachineDetail } from '../../contexts/MachineDetailContext';
import OilAnalysisDashboard from './OilAnalysisDashboard';
import OilAnalysisTable from './OilAnalysisTable';
import Spinner from '../../common/Spinner';
import FetchError from '../../common/FetchError';

function OilAnalysis({ oilReports }) {
    const [data, setData] = useState(oilReports[0] || []);
    const [group, setGroup] = useState(oilReports[0]?.baugruppeBez || '');
    const [view, setView] = useState('table');

    const menuItems = oilReports.map((e) => e.baugruppeBez);

    const handleChange = (event) => {
        setGroup(event.target.value);
        const newGroup = oilReports?.filter((elem) => elem.baugruppeBez === event.target.value)[0];
        setData(newGroup);
    };

    const handleRowClick = (id) => {
        setGroup(id);
        const newGroup = oilReports?.filter((elem) => elem.baugruppeBez === id);
        setData(newGroup[0]);
        setView('detail');
    };

    const getLabel = (id) => {
        const newGroup = oilReports?.filter((elem) => elem.baugruppeBez === id);
        const label = Array.isArray(newGroup[0].bezeichnung)
            ? newGroup[0].bezeichnung[0]
            : newGroup[0].bezeichnung;
        return label;
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={() => setView('table')}>
                    <ListIcon />
                </IconButton>
                <IconButton onClick={() => setView('detail')}>
                    <AppsIcon />
                </IconButton>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Container maxWidth="xl">
                {data.length === 0 ? (
                    <Typography sx={{ m: 6, textAlign: 'center' }}>
                        keine Ölanalysen gefunden
                    </Typography>
                ) : (
                    <>
                        {view === 'table' ? (
                            <OilAnalysisTable data={oilReports} handleRowClick={handleRowClick} />
                        ) : (
                            <>
                                <Box sx={{ display: 'flex' }}>
                                    <FormControl sx={{ width: 300, mb: 4 }}>
                                        <InputLabel id="select-label-group">Baugruppe</InputLabel>
                                        <Select
                                            labelId="select-label-group"
                                            id="group-select"
                                            value={group}
                                            label="Baugruppe"
                                            onChange={handleChange}
                                        >
                                            {menuItems.map((elem) => (
                                                <MenuItem key={elem} value={elem}>
                                                    {getLabel(elem)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box sx={{ pb: 16 }}>
                                    <OilAnalysisDashboard data={data} />
                                </Box>
                            </>
                        )}
                    </>
                )}
            </Container>
        </>
    );
}

export default function OilAnalysisWithData() {
    const { fetchMachineOilReportsResponse } = useMachineDetail();
    const [fetchData, { data, error, isLoading }] = fetchMachineOilReportsResponse;

    useEffect(() => {
        if (!data) fetchData();
    }, [fetchData, data]);

    if (isLoading) return <Spinner />;
    if (error) return <FetchError message={error.message} />;
    if (!data) return null;

    return <OilAnalysis oilReports={data.result} />;
}
