import { Box, Button, Typography } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import DownloadIcon from '@zlab-de/zel-react-icons/EssentialDownload';
import { pageTitle } from '../../../../routes';
import { useAnalytics } from '../../contexts/AnalyticsContext';
import { useEvent } from '../../contexts/DashboardDataContext';
import downloadCsv from '../../utils/downloadCsv';
import Layout from '../layout/Layout';

const Icon = styled(DownloadIcon)(({ theme }) => ({
    width: 80,
    height: 80,
    color: theme.color.global.black,
    marginBottom: 60,
}));

export default function DownloadPage() {
    const { trackEvent } = useAnalytics();
    const { events } = useEvent();
    const isEmptyList = events.length === 0;

    const handleClick = () => {
        downloadCsv(events);
        const tagManagerArgs = {
            dataLayer: {
                area: 'Datenexport',
                event: 'file_download',
            },
        };
        trackEvent(tagManagerArgs);
    };

    return (
        <Layout pageTitle={pageTitle.app.dashboard.export}>
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Icon />
                <Typography sx={{ mb: 2 }} variant="zep-typo--headline-4">
                    Report
                </Typography>
                <Typography sx={{ mb: 4, textAlign: 'center' }} variant="zep-typo--body-small">
                    Eine kostenlos herunterladbare CSV-Excel Datei Ihrer Maschinendaten.
                </Typography>
                <Button
                    onClick={handleClick}
                    message="CSV-Excel Report downloaden"
                    variant="contained"
                    size="large"
                    disabled={isEmptyList}
                >
                    Download
                </Button>
                {isEmptyList && (
                    <Typography
                        sx={{ mb: 4, textAlign: 'center', color: (theme) => theme.other.red }}
                        variant="zep-typo--body-small"
                    >
                        Keine Event-/Diagnosecodes
                    </Typography>
                )}
            </Box>
        </Layout>
    );
}
