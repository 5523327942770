import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import prioData from '../../utils/prioData';

export default function EditorPrio({ prio, handleChange }) {
    const handleOnChange = (event) => {
        handleChange('prio', event.target.value);
    };

    return (
        <FormControl sx={{ width: 250 }}>
            <InputLabel id="select-prio-label" sx={{ background: 'white' }}>
                Prio wählen
            </InputLabel>
            <Select
                labelId="select-prio-label"
                id="select-prio"
                value={prio}
                label="Prio"
                onChange={handleOnChange}
            >
                {['1', '2', '3', '0'].map((item, idx) => (
                    <MenuItem value={item} key={`menuItem${idx}`}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    mr: 1,
                                    width: 8,
                                    height: 18,
                                    borderRadius: 2,
                                    background: (theme) => theme.other[`prio${item}`].main,
                                }}
                            />
                            <Typography
                                variant="zep-typo--body-small"
                                sx={{ textTransform: 'capitalize' }}
                            >
                                {prioData[item].label}
                            </Typography>
                        </Box>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

EditorPrio.propTypes = {
    prio: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
};
