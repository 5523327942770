import ErrorIcon from '@mui/icons-material/Error';
import { Snackbar, Stack, Alert } from '@mui/material';
import { useEffect } from 'react';
import { useAlert } from '../../contexts/AlertContext';
import SuccessCircleIcon from '../icons/SuccessCircleIcon';

export default function Alerts() {
    const { alertState, closeAlert } = useAlert();
    const { message, open, severity } = alertState;

    useEffect(() => {
        return () => {
            if (open) {
                closeAlert();
            }
        };
    }, [closeAlert, open]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        closeAlert();
    };

    return (
        <Stack>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <Alert
                    onClose={handleClose}
                    severity={severity}
                    sx={{ width: '100%' }}
                    icon={
                        severity === 'success' ? (
                            <SuccessCircleIcon sx={{ color: (theme) => theme.other.green }} />
                        ) : (
                            <ErrorIcon sx={{ fill: (theme) => theme.other.red }} />
                        )
                    }
                >
                    {message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}
