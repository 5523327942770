import * as React from 'react';
import { SvgIcon } from '@mui/material';

export default function WarningIcon(props) {
    return (
        <SvgIcon {...props} titleAccess="error" viewBox="0 0 44 44">
            <g
                id="icon-/-alert-/-large-/-warning"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <path
                    d="M41.5975606,35.9077502 L25.1232015,4.86380993 L25.1232015,4.86380993 C24.5146628,3.71794994 23.3119277,3 22.0008813,3 C20.6898349,3 19.4870998,3.71794994 18.878561,4.86380993 L2.40243943,35.9077502 C1.83002828,36.9860645 1.87054744,38.2811894 2.50927642,39.3226104 C3.1480054,40.3640313 4.29199625,41 5.52564092,41 L38.4743591,41 C39.7080037,41 40.8519946,40.3640313 41.4907236,39.3226104 C42.1294526,38.2811894 42.1699717,36.9860645 41.5975606,35.9077502 Z M20.2374709,15.7735375 C20.2374709,14.813028 21.0265821,14.0343812 22,14.0343812 C22.9734179,14.0343812 23.7625291,14.813028 23.7625291,15.7735375 L23.7625291,26.2084754 C23.7625291,27.168985 22.9734179,27.9476318 22,27.9476318 C21.0265821,27.9476318 20.2374709,27.168985 20.2374709,26.2084754 L20.2374709,15.7735375 Z M22.0881265,35.7912268 L22.0387756,35.7912268 C20.5945165,35.7866575 19.4110206,34.6587545 19.3562064,33.234667 C19.3305425,32.5461067 19.5864878,31.8762734 20.0664726,31.3758327 C20.5464573,30.8753919 21.2101797,30.5863633 21.9083485,30.5737578 L21.9576993,30.5737578 C23.4017219,30.5754568 24.587055,31.7013259 24.6437936,33.1251001 C24.6714101,33.8152687 24.4158009,34.4872648 23.9347924,34.9890632 C23.4537838,35.4908616 22.7880237,35.7800576 22.0881265,35.7912268 Z"
                    id="Shape-Copy-2"
                    fill="currentColor"
                    fillRule="nonzero"
                />
            </g>
        </SvgIcon>
    );
}
