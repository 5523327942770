import React, { createContext, useContext, useMemo } from 'react';
import { routeConfig } from '../../../routes';
import Spinner from '../common/Spinner';
import { JWT_TOKEN } from '../constants/fetchStatus';
import { UserDTO } from '../dto/user.dto';
import { useQuery } from '../utils/api';

const AuthContext = createContext();

const withAuthTokenProvided = (Component) => {
    return (props) => {
        const token = localStorage.getItem(JWT_TOKEN);
        if (!token) {
            window.location.replace(routeConfig.login);
            return;
        }
        return <Component {...props} />;
    };
};

const ContextProvider = (props) => {
    const userDTO = useMemo(() => new UserDTO(), []);
    const { data, error, isLoading } = useQuery(userDTO);

    const backToLogin = () => {
        window.location.replace(routeConfig.login);
    };

    const logout = () => {
        localStorage.removeItem(JWT_TOKEN);
        backToLogin();
    };

    if (error) {
        logout();
        return;
    }

    if (isLoading) return <Spinner />;

    const user = { ...data.result, como_kupo_url_order: data.como_kupo_url_order || null };

    return (
        <AuthContext.Provider
            value={{
                user,
                logout,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export const AuthContextProvider = withAuthTokenProvided(ContextProvider);

export const useAuth = () => useContext(AuthContext);
