import * as React from 'react';
import { SvgIcon } from '@mui/material';

export default function CalendarIcon(props) {
    return (
        <SvgIcon {...props} titleAccess="error" viewBox="0 0 24 24">
            <path
                d="M15.667.42a.58.58 0 0 1 .572.486l.008.094-.001 2.086h3.42c1.007 0 1.832.778 1.908 1.764l.006.15v14.667a1.913 1.913 0 0 1-1.913 1.913H2.333A1.913 1.913 0 0 1 .42 19.667V5c0-1.057.857-1.913 1.913-1.913l3.42-.001V1A.58.58 0 0 1 6.906.906L6.913 1v2.086h8.173V1c0-.32.26-.58.58-.58Zm4.753 9.16H1.58v10.087c0 .381.283.696.651.746l.102.007h17.334a.753.753 0 0 0 .753-.753V9.58ZM11 16.434c.52 0 .913.442.913.955 0 .476-.338.891-.803.948l-.11.007c-.52 0-.913-.442-.913-.955 0-.477.338-.892.803-.948l.11-.007Zm5.667 1.91c-.521 0-.914-.442-.914-.955 0-.477.339-.892.804-.948l.11-.007c.52 0 .913.442.913.955 0 .476-.339.891-.804.948l-.11.007ZM5.333 16.42a.913.913 0 0 1 .107 1.82l-.107.007a.913.913 0 0 1-.106-1.82l.106-.007Zm11.334-4.653c.52 0 .913.442.913.955 0 .477-.339.892-.804.949l-.11.006c-.52 0-.913-.442-.913-.955 0-.476.339-.891.804-.948l.11-.007Zm-11.334-.014a.913.913 0 0 1 .107 1.82l-.107.007a.913.913 0 0 1-.106-1.82l.106-.007Zm5.667 0a.913.913 0 0 1 .107 1.82L11 13.58a.913.913 0 0 1-.107-1.82l.107-.007ZM5.753 4.246h-3.42A.753.753 0 0 0 1.58 5v3.42h18.84V5a.754.754 0 0 0-.651-.746l-.102-.007-3.421-.001v1.42a.58.58 0 0 1-1.152.095l-.007-.094-.001-1.421H6.913v1.42a.58.58 0 0 1-1.152.095l-.008-.094V4.246Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
