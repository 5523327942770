export default function machinePicture(machine) {
    let img = null;
    const subString = machine ? machine.substring(0, 2) : null;
    const subString2 = machine ? machine.substring(0, 3) : null;

    switch (true) {
        case subString === 'M3':
            img = 'B5';
            break;
        case subString === 'MH':
            img = 'B6';
            break;
        case subString === 'D3':
        case subString === 'D4':
        case subString === 'D5':
        case subString === 'D6':
            img = 'D1';
            break;
        case subString === 'D7':
        case subString === 'D8':
        case subString === 'D9':
        case subString === 'D1':
            img = 'D2';
            break;
        case subString === 'CB':
            img = 'W1';
            break;
        case subString === 'CS':
        case subString === 'CP':
            img = 'W2';
            break;
        case subString === '60':
            img = 'B4';
            break;
        case parseInt(subString2) >= 12 && parseInt(subString2) <= 20:
        case parseInt(subString2) >= 120 && parseInt(subString2) <= 180:
            img = 'G1';
            break;
        case parseInt(subString2) >= 300 && parseInt(subString2) <= 310:
            img = 'B1';
            break;
        case parseInt(subString2) >= 311 && parseInt(subString2) <= 336:
        case parseInt(subString2) === 352:
            img = 'B2';
            break;
        case parseInt(subString2) >= 366 && parseInt(subString2) <= 390:
            img = 'B3';
            break;
        case parseInt(subString2) >= 906 && parseInt(subString2) <= 918:
            img = 'L1';
            break;
        case parseInt(subString2) >= 926 && parseInt(subString2) <= 938:
            img = 'L2';
            break;
        case parseInt(subString2) >= 950 && parseInt(subString2) <= 952:
        case parseInt(subString2) >= 954 && parseInt(subString2) <= 962:
        case parseInt(subString2) >= 964 && parseInt(subString2) <= 972:
        case parseInt(subString2) >= 974 && parseInt(subString2) <= 992:
            img = 'L3';
            break;
        case parseInt(subString2) === 953:
        case parseInt(subString2) === 963:
        case parseInt(subString2) === 973:
            img = 'L4';
            break;
        case parseInt(subString2) >= 216 && parseInt(subString2) <= 272:
            img = 'L5';
            break;
        case parseInt(subString2) >= 239 && parseInt(subString2) <= 299:
            img = 'L6';
            break;
        case parseInt(subString2) >= 725 && parseInt(subString2) <= 745:
            img = 'M1';
            break;
        case parseInt(subString2) >= 769 && parseInt(subString2) <= 777:
            img = 'M2';
            break;
        default:
            img = 'placeholder';
    }

    return img;
}
