import SendIcon from '@mui/icons-material/Send';
import {LoadingButton} from '@mui/lab';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from '@mui/x-date-pickers-pro/AdapterDateFns';
import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {humanize} from 'humanize'
import {useAlert} from '../../contexts/AlertContext';
import {useAnalytics} from '../../contexts/AnalyticsContext';
import {useCustomerSelect} from '../../contexts/CustomerSelectContext';
import {useMutation} from '../../utils/api';
import {BootstrapDialogNoTextTitle} from "../../common/BootstrapDialogNoTextTtile";
import {range} from 'lodash'
import {MaintenanceCreateDTO} from "../../dto/maintenance.dto";
import {useAuth} from "../../contexts/AuthContext";


const SELECTPLACEHOLDER = <em style={{color: "gray", fontStyle: "normal"}}>Bitte auswählen ...</em>;

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(3),
    },
}));

const DialogHeading = styled(Typography)({
    color: '#282828',
    fontWeight: 400,
});

const DialogInputLabel = styled(FormLabel)({
    color: 'rgba(40,40,40,1)',
    fontWeight: 400,
    fontSize: '12px',
});

const FormHelperTextStyle = {
    sx: {
        color: 'rgba(90,90,90,1)',
        fontWeight: 400,
        fontSize: '12px',
    },
};

const FormErrorText = {
    sx: {color: '#ff6562', fontWeight: 400, fontSize: '12px'}
};

const intervals = range(500, 14500, 500)
const intervalOptions = intervals.map(el => {
    return ({
        "value": el,
        "label": humanize.numberFormat(el, 0, '', '.')
    })
})


function checkRequiredAttribute(object_) {
    const requiredAttributes = [
        'make', 'serialNumber', 'companyid', 'smcs', 'operatingHours', 'preferreddate']
    const missingAttributes = [];
    for (const key of requiredAttributes) {
        if (object_.hasOwnProperty(key)) {
            const value = object_[key];
            if (value === null || value === undefined || value === '') {
                missingAttributes.push(key);
            }
        }
    }
    return missingAttributes;
}


function MaintenanceDialogContent({machine, company, handleClose, completed, handleSetCompleted}) {
    const {user} = useAuth();
    const {openAlert} = useAlert();
    const {trackEvent} = useAnalytics();
    const [dateValue, setDateValue] = useState(new Date());
    const [sendMaintenance, {isLoading: isSendingMaintenance}] = useMutation();

    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm();

    const trackSendMaintenance = () => {
        const tagManagerArgs = {
            dataLayer: {
                machineName: `${machine.model}-${machine.make}`,
                serialNumber: machine.serialNumber,
                event: 'button_click',
            },
        };
        trackEvent(tagManagerArgs);
    };

    const onSubmit = async (data) => {
        const maintenanceCreateBody = {
            make: machine.make,
            model: machine.model,
            serialnumber: machine.serialNumber,
            companyname: company.name,
            companyid: company.ids[0],
            variant: machine.variant,
            preferreddate: data.preferreddate,
            smcs: machine.variant === "UVV" ? '9860' :
                data.smcs.toString().includes('500') ? '7525' : '7526',
            operatingHours: data.hours
        };

        const missingAttributes = checkRequiredAttribute(maintenanceCreateBody);
        const hasMissingAttributes = missingAttributes.length > 0;

        await sendMaintenance(new MaintenanceCreateDTO(user.userId, maintenanceCreateBody), {
            onSuccess: () => {
                trackSendMaintenance();
                handleSetCompleted();
                openAlert({
                    message: 'Inspektion wurde erfolgreich erstellt.',
                    severity: 'success',
                });
            },
            onError: (error) => {
                let message = error.message;
                if (hasMissingAttributes) {
                    message = message + ` !!! Es fehlen noch einige Angaben: ${missingAttributes.join(', ')}`;
                }
                openAlert({
                    message: message,
                    severity: 'error',
                });
            },
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {
                completed ?
                    <DialogContent sx={{py: '0 !important'}}>
                        <Box sx={{marginBottom: '28px'}}>
                            <Typography>
                                All good
                            </Typography>
                            <Button onClick={handleClose}>OK</Button>
                        </Box>
                    </DialogContent>
                    :
                    <>
                        <DialogContent sx={{py: '0 !important'}}>
                            <Box sx={{marginBottom: '28px'}}>
                                <Grid container spacing={{xs: 4}}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <DialogInputLabel>Datum der {machine.variant}*</DialogInputLabel>
                                            <Controller
                                                name="preferreddate"
                                                defaultValue={dateValue}
                                                control={control}
                                                rules={{
                                                    required: 'Datum ist erforderlich',
                                                }}
                                                render={({field: {onChange, ...restField}}) => (
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            inputFormat="dd.MM.yyyy"
                                                            onChange={(event) => {
                                                                onChange(event);
                                                                setDateValue(event);
                                                            }}
                                                            renderInput={(startProps) => (
                                                                <TextField
                                                                    {...startProps}
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    size="small"
                                                                />
                                                            )}
                                                            {...restField}
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                            {errors?.preferreddate && (
                                                <FormHelperText
                                                    sx={{
                                                        color: '#ff6562',
                                                        fontWeight: 400,
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    {errors?.preferreddate?.message}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <DialogInputLabel>Betriebsstunde bei {machine.variant}*</DialogInputLabel>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                placeholder={'e.g. 2367 '}
                                                defaultValue={!!machine.hours ? Math.round(machine.hours) : null}
                                                error={!!errors?.hours}
                                                helperText={errors?.hours?.type === 'pattern'
                                                    ? `Ungültiges Nummernformat (nur Ziffern).`
                                                    : errors?.hours?.type === 'required'
                                                        ? `Betriebsstunde ist erforderlich.`
                                                        : null
                                                }
                                                FormHelperTextProps={FormHelperTextStyle}
                                                {...register('hours', {
                                                    required: true,
                                                    pattern: /^\d+$/
                                                })}
                                            />
                                        </FormControl>
                                    </Grid>

                                    {machine.variant === 'Wartung' ?
                                        <Grid container item xs={12} spacing={'1rem'} justify="flex-end"
                                              alignItems="center">
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <DialogInputLabel>Wartungsinterval*</DialogInputLabel>
                                                    <Select
                                                        size="small"
                                                        defaultValue={"-1"}
                                                        error={!!errors?.smcs}
                                                        {...register('smcs', {
                                                            pattern: /^(?!.*(-1))/
                                                        })}
                                                    >
                                                        <MenuItem value="-1" disabled>
                                                            {SELECTPLACEHOLDER}
                                                        </MenuItem>
                                                        {intervalOptions.map(({value, label}) => (
                                                            <MenuItem key={value} value={value}>
                                                                {label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors?.smcs && (
                                                        <FormHelperText sx={{FormErrorText}}>
                                                            {`Wartungsinterval ist erforderlich`}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4} marginTop={'17px'}>
                                                Betriebsstunden
                                            </Grid>
                                        </Grid> : null}
                                </Grid>
                            </Box>
                        </DialogContent>

                        <DialogActions
                            sx={{
                                flexDirection: 'row-reverse',
                                paddingTop: '16px !important',
                                paddingLeft: '1rem !important'
                            }}>

                            <Button
                                type="button"
                                variant="contained"
                                size="large"
                                onClick={handleClose}
                                sx={{
                                    fontSize: '1.1rem',
                                    height: '2.5rem',
                                    marginLeft: '1rem',
                                    background: 'none'
                                }}>
                                <Typography
                                    sx={{
                                        color: 'rgba(40,40,40,1)',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        letterSpacing: '0.8px',
                                        lineHeight: '32px',
                                        textAlign: 'center',
                                    }}
                                >Abbrechen
                                </Typography>
                            </Button>

                            <LoadingButton
                                type="submit"
                                disabled={isSendingMaintenance}
                                variant="contained"
                                size="large"
                                sx={{
                                    fontSize: '1.1rem',
                                    height: '2.5rem',
                                }}
                                endIcon={isSendingMaintenance ? <SendIcon/> : undefined}
                                loadingPosition={isSendingMaintenance ? 'end' : undefined}
                                loading={isSendingMaintenance}
                            >
                                <Typography
                                    sx={{
                                        color: 'rgba(40,40,40,1)',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        letterSpacing: '0.8px',
                                        lineHeight: '32px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Bestätigen
                                </Typography>
                            </LoadingButton>
                        </DialogActions>
                    </>
            }
        </form>
    );
}

export default function MaintenanceButton({machine}) {
    const {customerCompany: company} = useCustomerSelect();
    const [open, setOpen] = useState(false);
    const [completed, setCompleted] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleSetCompleted = () => {setCompleted(true)}
    const handleClose = () => setOpen(false);
    if (!company) return null;
    const unWrappedButton =
        <Button
            disabled={completed}
            sx={{
                ":disabled": completed,
                ':hover': {
                    bgcolor: '#FFCC00'
                },
                bgcolor: 'none',
                border: '1px solid #FFCC00'
            }}
            onClick={handleOpen} variant="text" size="large">
            Erledigen
        </Button>

    return (
        <div>
            {completed ?
                <Tooltip
                    title={"Anfrage wurde gesendet"}
                    followCursor={true}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: 'grey'
                            }
                        }
                    }}
                >
                    <span>
                {unWrappedButton}
                        </span>
                </Tooltip> :
                unWrappedButton
            }
            <BootstrapDialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
                {completed?
                    <>
                        <DialogHeading sx={{fontSize: '24px', padding: '2rem'}}>
                            {machine.make} {machine.model} ({machine.serialNumber})
                            <DialogHeading sx={{fontSize: '24px'}}>
                                Die überfällige {machine.variant} wurde auf erledigt eingestellt.
                            </DialogHeading>
                            <br/>
                            <DialogHeading
                                sx={{border: '1px solid #FFCC00', borderRadius: '5px'}}
                            >
                                <Grid sx={12}
                                      textAlign={'center'}
                                      backgroundColor={'rgba(251, 218, 85, 0.21)'}
                                      padding={'1rem'}
                                >
                                    <b>HINWEIS</b>
                                </Grid>
                                <Grid sx={12} padding={'1rem'}>
                                    Die überfällige {machine.variant} wurde als erledigt markiert. <br />
                                    Der neue {machine.variant} Status wird in COMO innerhalb einiger Stunden automatisch übernommen.
                                </Grid>
                            </DialogHeading>
                        </DialogHeading>

                        <Typography align='center'>
                            <Button
                                type="button"
                                variant="contained"
                                size="large"
                                onClick={handleClose}
                                sx={{
                                    fontSize: '1.1rem',
                                    height: '2.5rem',
                                    background: 'none',
                                    margin: '1.5rem',
                                    justifyContent: 'center'
                                }}>
                                <Typography
                                    sx={{
                                        color: 'rgba(40,40,40,1)',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        letterSpacing: '0.8px',
                                        lineHeight: '32px',
                                        textAlign: 'center',
                                    }}
                                >OK
                                </Typography>
                            </Button>
                        </Typography>
                    </>:
                    <>
                        <BootstrapDialogNoTextTitle onClose={handleClose}>
                            <DialogHeading sx={{fontSize: '24px'}}>
                                {machine.make} {machine.model} ({machine.serialNumber})
                                <DialogHeading sx={{fontSize: '24px'}}>
                                    {machine.variant} auf erledigt setzen
                                </DialogHeading>
                                <DialogHeading sx={{fontSize: '16px', paddingTop: '2rem'}}>
                                    Hier bestätigen Sie, dass die
                                    Maschine {machine.make} {machine.model} ({machine.serialNumber})
                                    einer ordnungsgemäßen {machine.variant} unterzogen wurde.
                                </DialogHeading>
                            </DialogHeading>
                        </BootstrapDialogNoTextTitle>

                        <MaintenanceDialogContent
                            machine={machine}
                            company={company}
                            handleClose={handleClose}
                            completed={completed}
                            handleSetCompleted={handleSetCompleted}
                        />
                    </>
                }
            </BootstrapDialog>
        </div>
    );
}
