import { Alert, Box, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { pageTitle } from '../../../../routes';
import { isManager, isDeveloper } from '../../constants/role';
import { useAuth } from '../../contexts/AuthContext';
import { useCustomerSelect } from '../../contexts/CustomerSelectContext';
import Layout from '../layout/Layout';
import EventCodeReports from './EventCodeReports';
import MachineReports from './MachineReports';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function getDefaultTabValue(tabParamString, user) {
    if (isDeveloper(user.role)) return 1;
    return tabParamString ? Number(tabParamString) : 0;
}

export default function ReportsPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const { user } = useAuth();
    const { customerName } = useCustomerSelect();

    const handleChange = (event, newValue) => {
        setSearchParams({ tab: newValue });
    };

    const value = getDefaultTabValue(searchParams.get('tab'), user);

    return (
        <Layout pageTitle={pageTitle.app.dashboard.reports}>
            {isManager(user.role) && (
                <Tabs value={value} onChange={handleChange}>
                    <Tab disabled={isDeveloper(user.role)} label="Kunde" {...a11yProps(0)} />
                    <Tab label="Niederlassung" {...a11yProps(1)} />
                </Tabs>
            )}

            <Box sx={{ background: (theme) => theme.color.global.white, p: 4 }}>
                <Typography
                    component="p"
                    variant="zep-typo--display-5"
                    sx={{ fontSize: 16, mb: 1 }}
                >
                    Benachrichtigungen
                </Typography>
                <Typography component="p" variant="zep-typo--body-default" sx={{ mb: 4 }}>
                    Stellen Sie Ihre individuellen Benachrichtigungen ein, die Sie an Ihre Benutzer
                    E-Mail erhalten möchten.
                </Typography>
                {isManager(user.role) ? (
                    <>
                        <Alert severity="warning">
                            {value === 0
                                ? `Hier ändern Sie die Report Einstellungen für Kunde "${customerName}".`
                                : 'Hier ändern Sie die Report Einstellungen für die jeweilige Niederlassung.'}
                        </Alert>
                        <TabPanel value={value} index={0}>
                            <MachineReports />
                            <EventCodeReports />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <MachineReports />
                            <EventCodeReports />
                        </TabPanel>
                    </>
                ) : (
                    <>
                        <MachineReports />
                        <EventCodeReports />
                    </>
                )}
            </Box>
        </Layout>
    );
}
