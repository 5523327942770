import ErrorIcon from '@mui/icons-material/Error';
import { Box, ButtonBase, Popover, Typography } from '@mui/material';
import InfoOutlinedIcon from '@zlab-de/zel-react-icons/EssentialInfo';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { useLayout } from '../../contexts/LayoutContext';

export default function InspectionSummaryCard({ data, id }) {
    const { sidebarOpen, breakpointSm, breakpointMd, breakpointLg, breakpointXl } = useLayout();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const idString = `inspection${id}`;
    const label = ['Überfällige', 'Bevorstehende', 'Zukünftige'];
    const description = [
        'Überfällige Inspektionen',
        'Bevorstehende Inspektionen',
        'Zukünftige Inspektionen',
    ];

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    let scrollOffset = -80;
    switch (true) {
        case breakpointXl:
            scrollOffset = -216;
            break;
        case breakpointLg:
            scrollOffset = -216;
            break;
        case breakpointMd:
            scrollOffset = -184;
            break;
        case breakpointSm:
            scrollOffset = -88;
            break;
        default:
            scrollOffset = -80;
    }

    return (
        <ScrollLink
            activeClass="active"
            to={idString}
            smooth
            duration={250}
            offset={scrollOffset}
            containerId="containerElement"
            style={{ flexBasis: '32%', cursor: data.length === 0 ? 'default' : 'pointer' }}
        >
            <Box
                sx={{
                    background: (theme) => theme.color.global.white,
                    borderRadius: 1,
                    height: {
                        xs: 'auto',
                        md: 120,
                    },
                    '&:hover': {
                        boxShadow: (theme) => `0px 16px 28px 0px ${theme.other[idString].light}`,
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            p: {
                                xs: 1,
                                md: open ? 1 : 2,
                                lg: 2,
                            },
                        }}
                    >
                        <ErrorIcon
                            sx={{
                                color: (theme) => theme.other[idString].main,
                                height: 'auto',
                                width: {
                                    xs: 32,
                                    sm: 40,
                                    lg: 48,
                                },
                            }}
                        />
                        <Typography
                            component="p"
                            variant="zep-typo--body-default"
                            sx={{
                                color: (theme) => theme.color.font,
                                fontWeight: 500,
                                fontSize: 36,
                                letterSpacing: '0.48px',
                                lineHeight: '23px',
                            }}
                        >
                            {data.length}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                        }}
                    >
                        <Typography
                            component="p"
                            variant="zep-typo--display-6"
                            sx={{
                                fontSize: { xs: 12, lg: 14 },
                                pl: {
                                    xs: 1,
                                    md: open ? 1 : 2,
                                    lg: 2,
                                },
                                pb: {
                                    xs: 1,
                                    md: open ? 1 : 2,
                                    lg: 2,
                                },
                            }}
                        >
                            {label[id]}
                        </Typography>
                        <ButtonBase
                            aria-owns={open ? `mouse-over-popover-${id}` : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                            sx={{
                                width: 40,
                                height: 40,
                                zIndex: 50000,
                                pr: 1,
                                pb: 1,
                            }}
                        >
                            <InfoOutlinedIcon
                                sx={{
                                    width: 18,
                                    height: 18,
                                    display: { xs: 'none', md: 'block' },
                                }}
                            />
                        </ButtonBase>
                        <Popover
                            id={`mouse-over-popover-${id}`}
                            sx={{
                                pointerEvents: 'none',
                                '& .MuiPaper-root': {
                                    boxShadow: 'none',
                                    width: {
                                        xs: 0,
                                        md: sidebarOpen
                                            ? 'calc((100vW - 298px)* 0.32)'
                                            : 'calc((100vW - 112px)* 0.32)',
                                        lg: sidebarOpen
                                            ? 'min(384px, (100vW - 298px)* 0.32)'
                                            : 'min(384px, (100vW - 112px)* 0.32)',
                                        xl: sidebarOpen
                                            ? 'min(384px, (100vW - 298px)* 0.32)'
                                            : 'min(384px, (100vW - 112px)* 0.32)',
                                    },
                                    height: 120,
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: {
                                        xs: 1,
                                        md: open ? 1 : 2,
                                        lg: 2,
                                    },
                                },
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorPosition={{ top: 0, left: 0 }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <Typography
                                component="p"
                                variant="zep-typo--body-extra-small"
                                align="center"
                            >
                                {description[id]}
                            </Typography>
                        </Popover>
                    </Box>
                </Box>
            </Box>
        </ScrollLink>
    );
}

InspectionSummaryCard.propTypes = {
    data: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
};
