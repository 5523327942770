import ErrorIcon from '@mui/icons-material/Error';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import { routeConfig } from '../../../../routes';
import { useAnalytics } from '../../contexts/AnalyticsContext';

import { useCustomerSelect } from '../../contexts/CustomerSelectContext';
import machinePicture from '../../utils/machinePictures';

export default function MachineCard({ machine }) {
    const { trackEvent } = useAnalytics();
    const { navigateWithCustomerId } = useCustomerSelect();

    const prio = ['1', '2', '3', '0'];

    const handleDetailPage = (serialNumber) => {
        navigateWithCustomerId(`${routeConfig.app.machineDetail}/${serialNumber}`);
        const tagManagerArgs = {
            dataLayer: {
                prioGroupDetails: {
                    prio1: machine.prio1,
                    prio2: machine.prio2,
                    prio3: machine.prio3,
                    unknown: machine.prio0,
                },
                serialNumber: machine.serialNumber,
                machineName: `${machine.make}-${machine.model}`,
                area: 'Fuhrpark',
                event: 'prio_item_click',
            },
        };
        trackEvent(tagManagerArgs);
    };

    return (
        <Card
            sx={{
                marginBottom: 4,
                boxShadow: '0px 16px 28px 0px rgba(12, 17, 34, 0.1)',
                borderRadius: 1,
            }}
        >
            <CardActionArea onClick={() => handleDetailPage(machine.serialNumber)}>
                <Box
                    sx={{
                        height: { xs: 200, md: 250 },
                        background: (theme) => `linear-gradient(#fff, ${theme.other.gray[100]})`,
                    }}
                >
                    <CardMedia
                        image={`https://cm-machineimages.s3.eu-central-1.amazonaws.com/${machinePicture(
                            machine.model
                        )}.png`}
                        title={machine.model}
                        sx={{
                            height: { xs: 200, md: 250 },
                            backgroundSize: 'contain',
                        }}
                    />
                </Box>

                <CardContent>
                    <Typography
                        variant="zep-typo--display-6"
                        sx={{ marginBottom: 2, fontSize: 16 }}
                    >
                        {`${machine.make}-${machine.model}`}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {prio.map((item, index) => (
                            <Box
                                sx={{
                                    flexBasis: '23%',
                                    background: (theme) => theme.other.gray[200],
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: 1,
                                    borderRadius: 1,
                                }}
                                key={`box${index}`}
                            >
                                {index === 0 && (
                                    <ErrorIcon
                                        sx={{
                                            height: 24,
                                            width: 24,
                                            color: (theme) => theme.other.prio1.main,
                                        }}
                                    />
                                )}
                                {index === 1 && (
                                    <WarningIcon
                                        sx={{
                                            height: 24,
                                            width: 24,
                                            color: (theme) => theme.other.prio2.main,
                                        }}
                                    />
                                )}
                                {index === 2 && (
                                    <InfoIcon
                                        sx={{
                                            height: 24,
                                            width: 24,
                                            color: (theme) => theme.other.prio3.main,
                                        }}
                                    />
                                )}
                                {index === 3 && (
                                    <HelpIcon
                                        sx={{
                                            height: 24,
                                            width: 24,
                                            color: (theme) => theme.other.prio0.main,
                                        }}
                                    />
                                )}
                                <Typography variant="zep-typo--display-6">
                                    {machine[`prio${item}`] === 0 ? '-' : machine[`prio${item}`]}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

MachineCard.propTypes = {
    machine: PropTypes.object.isRequired,
};
