import { errorMessages } from '../constants/error';
import { DTO } from './base.dto';

export class EventListDTO extends DTO {
    constructor(customerId) {
        super({
            apiRoute: `api/v1/customers/events/${encodeURIComponent(customerId)}`,
            fetchErrorMessage: errorMessages.fetchEvents,
        });
    }
}
