import pick from 'lodash/pick';
import {Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography,} from '@mui/material';
import {styled} from '@mui/material/styles';
import * as React from 'react';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useCustomerSelect} from '../../contexts/CustomerSelectContext';
import {BootstrapDialogNoTextTitle} from "../../common/BootstrapDialogNoTextTtile";
import {useAuth} from "../../contexts/AuthContext";
import PartsButton from "../orders/PartsButton";


const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(3),
    },
}));

const DialogHeading = styled(Typography)({
    color: '#282828',
    fontWeight: 400,
});


function MaintenanceDialogContent({machine, handleClose}) {
    const {user} = useAuth();
    const CustomerPortalLink = user.como_kupo_url_order;
    const {customerName: contact} = useCustomerSelect();

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm();

    const onSubmit = () => {
        window.open(CustomerPortalLink, '_blank').focus();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box padding={'1.5rem'}>
                <DialogContent fullwidth sx={{py: '0 !important', paddingLeft: '0 !important'}}>
                    <Box sx={{
                        marginBottom: '2rem',
                        border: '1px solid #FFCC00',
                        borderRadius: '5px'
                    }}>
                        <Grid sx={12}
                              textAlign={'center'}
                              backgroundColor={'rgba(251, 218, 85, 0.21)'}
                              padding={'1rem'}
                        >
                            <b>HINWEIS</b>
                        </Grid>
                        <Grid sx={12}
                              textAlign={'center'}
                              padding={'1rem'}
                        >
                            Die Wartungen wird nach Abschluss einer Bestellung <br/>
                            <b>automatisch auf erledigt gesetzt.</b><br/>
                            Dies kann <b>einige Stunden</b> in Anpsruch nehmen.
                        </Grid>
                    </Box>
                </DialogContent>

                <DialogActions
                    sx={{
                        flexDirection: 'row-reverse',
                        paddingTop: '16px !important',
                        paddingLeft: '0 !important'
                    }}>

                    <Button
                        type="button"
                        variant="contained"
                        size="normal"
                        onClick={handleClose}
                        sx={{
                            fontSize: '1rem',
                            height: '2.25rem',
                            marginLeft: '1rem',
                            background: 'none'
                        }}>
                        <Typography
                            sx={{
                                color: 'rgba(40,40,40,1)',
                                fontSize: '14px',
                                fontWeight: 500,
                                letterSpacing: '0.8px',
                                lineHeight: '32px',
                                textAlign: 'center',
                            }}
                        >Abbrechen
                        </Typography>
                    </Button>

                    <PartsButton
                        title="Zum Kundenportal"
                        successMessage="Wartungsteile wurden dem Warenkorb im Zeppelin Kundenportal hinzugefügt."
                        order={pick(machine, ['orderNr', 'jobNr', 'jobSuffix'])}
                        machine={pick(machine, ['make', 'model', 'serialNumber'])}
                        maintenance={true}
                    />
                </DialogActions>
            </Box>
        </form>
    );
}

export default function CATMaintenanceButton({machine}) {
    const {customerCompany: company} = useCustomerSelect();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    if (!company) return null;

    return (
        <>
            <Button onClick={handleOpen} variant="contained" size="large">
                Wartungsteile bestellen & erledigen
            </Button>
            <BootstrapDialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>

                <BootstrapDialogNoTextTitle onClose={handleClose}>
                    <DialogHeading sx={{fontSize: '24px'}}>
                        Wartung bestellen um Wartung zu erledigen
                        <DialogHeading sx={{fontSize: '16px', paddingTop: '2rem'}}>
                            Die Wartungsteile für die Maschine {
                            machine.make} {machine.model} ({machine.serialNumber}
                            ) wurden dem Kundenportal Warenkorb hinzugefügt.
                        </DialogHeading>
                    </DialogHeading>
                </BootstrapDialogNoTextTitle>

                <MaintenanceDialogContent
                    machine={machine}
                    handleClose={handleClose}
                />
            </BootstrapDialog>
        </>
    );
}
