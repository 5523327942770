import Layout from '../../components/layout/Layout';
import { pageTitle } from '../../routes';
import Service from './Service';

export default function ServicePage() {
    return (
        <Layout title={pageTitle.service}>
            <Service />
        </Layout>
    );
}
