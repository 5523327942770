import { DataGridPro, deDE } from '@mui/x-data-grid-pro';
import CheckIcon from '@zlab-de/zel-react-icons/EssentialCheckSmall';
import CloseIcon from '@zlab-de/zel-react-icons/EssentialCloseMini';
import { useMemo, useState } from 'react';
import { useEventCodeList } from '../../contexts/EventCodeListContext';
import { escapeRegExp } from '../../utils/regex';
import PrioTitle from '../prio/PrioTitle';
import ErrorTableSearchbar from './EventCodesTableSearchbar';
import TableHeaderIcon from './TableHeaderIcon';

const columns = [
    {
        field: 'id',
        headerName: 'Code',
        headerClassName: 'data-grid--header',
        cellClassName: 'data-grid--cell',
        flex: 1,
        minWidth: 200,
    },
    {
        field: 'type',
        headerName: 'Kategorie',
        headerClassName: 'data-grid--header',
        flex: 1,
        minWidth: 200,
    },
    {
        field: 'prio',
        headerName: 'Prio',
        renderCell: (params) => <PrioTitle prio={params.value} />,
        headerClassName: 'data-grid--header',
        flex: 1,
        minWidth: 200,
    },
    {
        field: 'possible_causes',
        renderHeader: () => <TableHeaderIcon variant="possible_causes" />,
        renderCell: (params) => (params.value ? <CheckIcon /> : <CloseIcon />),
        headerClassName: 'data-grid--header',
        align: 'center',
        headerAlign: 'center',
        width: 100,
    },
    {
        field: 'possible_consequences',
        renderHeader: () => <TableHeaderIcon variant="possible_consequences" />,
        renderCell: (params) => (params.value ? <CheckIcon /> : <CloseIcon />),
        headerClassName: 'data-grid--header',
        align: 'center',
        headerAlign: 'center',
        width: 100,
    },
    {
        field: 'recommendations',
        renderHeader: () => <TableHeaderIcon variant="recommendations" />,
        renderCell: (params) => (params.value ? <CheckIcon /> : <CloseIcon />),
        headerClassName: 'data-grid--header',
        align: 'center',
        headerAlign: 'center',
        width: 100,
    },
    {
        field: 'status',
        headerName: 'status',
        headerClassName: 'data-grid--header',
        align: 'right',
        headerAlign: 'right',
        width: 200,
    },
];

export default function EventCodesTable() {
    const { eventCodeList: data, setSelectedCode } = useEventCodeList();
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [selectionModel, setSelectionModel] = useState([]);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
    };

    const handleSelect = (value) => {
        setSelectionModel(value);
        const fullCodeData = data.filter((elem) => elem.id === value[0]);
        setSelectedCode(fullCodeData[0]);
    };

    const searchRegex = useMemo(() => new RegExp(escapeRegExp(searchText), 'i'), [searchText]);
    const filteredData = useMemo(
        () =>
            data.filter((row) => {
                return searchRegex.test(row.id.toString());
            }),
        [data, searchRegex]
    );

    return (
        <DataGridPro
            autoHeight
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            components={{ Toolbar: ErrorTableSearchbar }}
            rows={filteredData}
            columns={columns}
            onSelectionModelChange={(newSelectionModel) => {
                handleSelect(newSelectionModel);
            }}
            selectionModel={selectionModel}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            maxColumns={7}
            pagination
            componentsProps={{
                toolbar: {
                    value: searchText,
                    handleChange: (event) => requestSearch(event.target.value),
                    handleClearSearch: () => requestSearch(''),
                },
            }}
            sx={{
                background: (theme) => theme.color.global.white,
                '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: (theme) => theme.other.gray[200],
                },
                '& .MuiDataGrid-cell': {
                    overflow: 'hidden',
                },
                '& .MuiDataGrid-cell:focus,.MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                },
                '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                },
                '& .data-grid--header': {
                    fontFamily: '"Roboto Condensed", sans-serif',
                    letterSpacing: '1.25px',
                    textTransform: 'uppercase',
                },
                '& .data-grid--cell': {
                    fontWeight: 'bold',
                },
            }}
        />
    );
}
