function downloadCsv(data) {
    // reformat events for printing
    const formattedData = [];

    if (data.length > 0) {
        data.forEach((event) => {
            formattedData.push({
                Seriennummer: event.serialNumber,
                Model: event.make_model,
                Fehlercode: event.unique_code,
                Fehlertyp: event.type,
                Beschreibung: event.description ? event.description.replace('\n', '. ') : null,
                Empfehlung: event.recommendation?.recommendations
                    ? event.recommendation?.recommendations.replace('\n', '. ')
                    : null,
            });
        });

        let result; let ctr;
        const columnDelimiter = ';';
        const lineDelimiter = '\n';

        const keys = Object.keys(formattedData[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        formattedData.forEach((item) => {
            ctr = 0;
            keys.forEach((key) => {
                if (ctr > 0) {
                    result += columnDelimiter;
                }
                result +=
                    typeof item[key] === 'string' && item[key].includes(columnDelimiter)
                        ? `"${item[key]}"`
                        : typeof item[key] === 'object'
                        ? `"${JSON.stringify(item[key])}"`
                        : item[key];
                ctr++;
            });
            result += lineDelimiter;
        });

        const exportName = 'zeppelin.csv';
        const blob = new Blob([`\uFEFF${  result}`], {
            type: 'text/csv;charset=utf-8;',
        });
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportName);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', exportName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
}

export default downloadCsv;
