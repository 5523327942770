import * as React from 'react';
import PropTypes from 'prop-types';
import {
    ClickAwayListener,
    FormControl,
    FormControlLabel,
    Grow,
    IconButton,
    Paper,
    Popper,
    Radio,
    RadioGroup,
} from '@mui/material';
import FilterIcon from '@zlab-de/zel-react-icons/EssentialFilter1';

const filters = [
    { value: 'all', label: 'Alles Prio' },
    { value: '1', label: 'Prio 1' },
    { value: '2', label: 'Prio 2' },
    { value: '3', label: 'Prio 3' },
    { value: '0', label: 'unbekannte Prio' },
];

export default function EventFilter({ onChange }) {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(filters[0].value);

    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleChange = (event) => {
        onChange(event.target.value);
        setValue(event.target.value);
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <IconButton
                ref={anchorRef}
                aria-controls={open ? 'filter-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <FilterIcon style={{ width: 24 }} />
            </IconButton>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: 2 }}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'center bottom',
                        }}
                    >
                        <Paper sx={{ p: 2 }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        id="filter-list-grow"
                                        aria-label="prio"
                                        name="prio"
                                        value={value}
                                        onChange={handleChange}
                                    >
                                        {filters.map((elem) => (
                                            <FormControlLabel
                                                key={elem.label}
                                                value={elem.value}
                                                control={<Radio />}
                                                label={elem.label}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

EventFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
};
