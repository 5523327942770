import { isValid, format, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';

const formatDate = (date, dateFormat) => {
    const formattedDate =
        date && isValid(new Date(parseISO(date)))
            ? format(new Date(parseISO(date)), dateFormat || 'dd.MM.yyyy', {
                  locale: de,
              })
            : '';
    return formattedDate;
};

export default formatDate;
