import CloseIcon from '@mui/icons-material/Close';
import { Button, DialogTitle } from '@mui/material';

export function BootstrapDialogNoTextTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle
            sx={{ m: 0, p: 3, display: 'flex', justifyContent: 'space-between' }}
            {...other}
        >
            {children}
            {onClose ? (
                <Button
                    sx={{
                        textTransform: 'capitalize',
                        fontSize: 14,
                        fontWeight: 400,
                        letterSpacing: 0,
                        color: (theme) => theme.color.font,
                        padding: 0,
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }}
                    startIcon={
                        <CloseIcon
                            style={{
                                color: (theme) => theme.color.font,
                                width: 14,
                                height: 14,
                                position: 'absolute',
                                right: 0,
                                top: 0
                            }}
                        />
                    }
                    onClick={onClose}
                ></Button>
            ) : null}
        </DialogTitle>
    );
}
