import { Avatar, Box, Button, Collapse, Popover, Typography } from '@mui/material';
import ChevronUpIcon from '@zlab-de/zel-react-icons/ArrowChevronUp';
import AvatarIcon from '@zlab-de/zel-react-icons/EssentialAvatar';
import { useState } from 'react';
import ZeppelinLogo from '../../assets/logo/logo_lg.svg';
import { useAuth } from '../../contexts/AuthContext';
import { useLayout } from '../../contexts/LayoutContext';
import UserNavigation from '../navigation/UserNavigation';

export default function SidebarUserDrawer() {
    const { user } = useAuth();
    const { sidebarOpen: open } = useLayout();
    const [anchorEl, setAnchorEl] = useState(null);
    const [expand, setExpand] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openPopup = Boolean(anchorEl);
    const id = openPopup ? 'user-popover' : undefined;

    return (
        <>
            {open ? (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, mx: 6 }}>
                        <Box
                            component="img"
                            src={ZeppelinLogo}
                            alt="zeppelin cat"
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Box>

                    <Collapse orientation="vertical" in={expand} collapsedSize={72}>
                        <Box
                            sx={{
                                textAlign: 'center',
                                background: (theme) => theme.palette.secondary.main,
                            }}
                        >
                            <Button
                                onClick={() => setExpand(!expand)}
                                fullWidth
                                aria-label="user"
                                sx={{
                                    xIndex: 400,
                                    display: 'flex',
                                    background: (theme) => theme.palette.secondary.main,
                                    color: (theme) => theme.color.font,
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderRadius: 0,
                                    px: 4,
                                    py: 2,
                                    zIndex: 2,
                                    '&:hover': {
                                        background: '#ded3a6',
                                    },
                                }}
                            >
                                <Avatar
                                    sx={{ bgcolor: (theme) => theme.color.global.black }}
                                    alt={user?.realname}
                                    src={user?.image}
                                >
                                    <AvatarIcon
                                        sx={{ color: (theme) => theme.color.global.white }}
                                    />
                                </Avatar>
                                <Typography
                                    component="p"
                                    variant="zep-typo--display-6"
                                    sx={{
                                        flexGrow: 1,
                                        textAlign: 'left',
                                        ml: 1,
                                        color: (theme) => theme.color.global.black,
                                    }}
                                >
                                    {user?.realname || 'User'}
                                </Typography>
                                <Box sx={{ transform: expand ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                                    <ChevronUpIcon />
                                </Box>
                            </Button>
                            <UserNavigation />
                        </Box>
                    </Collapse>
                </>
            ) : (
                <>
                    <Button onClick={handleClick} fullWidth aria-describedby={id}>
                        <Avatar
                            sx={{ bgcolor: (theme) => theme.color.global.black }}
                            alt={user?.realname}
                            src={user?.image}
                        >
                            <AvatarIcon sx={{ color: (theme) => theme.color.global.white }} />
                        </Avatar>
                    </Button>
                    <Popover
                        id={id}
                        open={openPopup}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <UserNavigation />
                    </Popover>
                </>
            )}
        </>
    );
}
