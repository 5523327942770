import { Typography } from '@mui/material';
import { pageTitle } from '../../../../routes';
import { isManager } from '../../constants/role';
import { CustomerListContextProvider, useCustomerList } from '../../contexts/CustomerListContext';
import { GuardContextProvider } from '../../contexts/GuardContext';
import Layout from '../layout/Layout';
import CustomerTable from './CustomerTable';
import { useClearSelectedCustomer } from '../../contexts/CustomerSelectContext';

function CustomerOverview() {
    const { customerList } = useCustomerList();
    const isEmptyList = customerList.length === 0;

    if (isEmptyList) {
        return (
            <Typography
                variant="zep-typo--display-6"
                component="p"
                sx={{ width: '100%', textAlign: 'center', mt: 8 }}
            >
                keine Kunden gefunden
            </Typography>
        );
    }

    return <CustomerTable data={customerList} />;
}

export default function CustomerOverviewPage() {
    useClearSelectedCustomer();
    return (
        <GuardContextProvider check={isManager}>
            <Layout pageTitle={pageTitle.app.customers}>
                <CustomerListContextProvider>
                    <CustomerOverview />
                </CustomerListContextProvider>
            </Layout>
        </GuardContextProvider>
    );
}
