import { Box, Button, Typography } from '@mui/material';
import GpsIcon from '@zlab-de/zel-react-icons/LocationGpsActive';
import PropTypes from 'prop-types';
import * as React from 'react';
import { routeConfig } from '../../../../routes';
import { useAnalytics } from '../../contexts/AnalyticsContext';
import { useCustomerSelect } from '../../contexts/CustomerSelectContext';

export default function MarkerPopup({ isDetailPage, hasInvalidCoords, machine }) {
    const { trackEvent } = useAnalytics();
    const { navigateWithCustomerId } = useCustomerSelect();

    const trackViewMachine = () => {
        const tagManagerArgs = {
            dataLayer: {
                serialNumber: machine.serialNumber,
                machineName: `${machine.make}-${machine.model}`,
                event: 'view_machine',
            },
        };
        trackEvent(tagManagerArgs);
    };

    const handleClick = () => {
        trackViewMachine();
        navigateWithCustomerId(`${routeConfig.app.machineDetail}/${machine.serialNumber}`);
    };

    const handleRouteClick = (e) => {
        e.preventDefault();
        const tagManagerArgs = {
            dataLayer: {
                machineName: `${machine?.make}-${machine?.model}`,
                serialNumber: machine?.serialNumber,
                event: 'route_planen',
            },
        };
        trackEvent(tagManagerArgs);
        window.open(`http://www.google.com/maps/place/${machine?.lat},${machine?.lon}`, '_blank');
    };

    return (
        <Box sx={{ background: (theme) => theme.color.global.white }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <GpsIcon sx={{ width: 32, height: 32, mr: 2 }} />
                <Typography
                    variant="zep-typo--body-small"
                    component="p"
                >{`${machine?.lat} , ${machine?.lon}`}</Typography>
            </Box>
            {hasInvalidCoords ? (
                <Typography
                    sx={{
                        color: (theme) => theme.other.red,
                        pb: 2,
                        textAlign: 'center',
                    }}
                >
                    Diese Maschine hat ungültige Koordinaten!
                </Typography>
            ) : (
                <Button
                    component="a"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mb: 2 }}
                    onClick={handleRouteClick}
                >
                    Route Planen
                </Button>
            )}
            {!isDetailPage && (
                <Button variant="outlined" color="primary" fullWidth onClick={handleClick}>
                    Maschine ansehen
                </Button>
            )}
        </Box>
    );
}

MarkerPopup.propTypes = {
    isDetailPage: PropTypes.bool,
    hasInvalidCoords: PropTypes.bool,
    machine: PropTypes.object,
};
