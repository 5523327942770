import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

import ProtectShieldIcon from '../icons/ProtectShieldIcon';
import DisabledIcon from '../icons/DisabledIcon';
import MedicalIcon from '../icons/MedicalIcon';
import LightningIcon from '../icons/LightningIcon';

const text = [
    'Für diesen Fehlercode haben wir aktuell noch keine Handlungsempfehlung für Sie erstellt. Wir werden dies aufnehmen und bewerten.',
    'Maschine sollte abgestellt werden. Zeppelin Service kontaktieren.',
    'Maschine kann weiterfahren. Zeppelin Service sollte kontaktiert werden.',
    'Maschine funktionsfähig und kann weiterfahren.',
];

export default function EventCardDetails({ data, prio }) {
    const details = [
        {
            icon: <LightningIcon />,
            title: 'Mögliche Fehlerursache',
            text: data.recommendation?.possible_causes,
        },
        {
            icon: <MedicalIcon />,
            title: 'Mögliches Entgegenwirken',
            text: data.recommendation?.recommendations,
        },
        {
            icon: <DisabledIcon />,
            title: 'Mögliche Folgen',
            text: data.recommendation?.possible_consequences,
        },
        {
            icon: <ProtectShieldIcon />,
            title: 'Handlungsempfehlung',
            text: text[prio],
        },
    ];

    const prioString = `prio${prio}`;

    return (
        <Box sx={{ p: 2 }}>
            {details.map((elem, idx) => (
                <Box
                    sx={{
                        display: 'flex',
                        marginBottom: 3,
                        p: 2,
                        border:
                            idx === 3
                                ? (theme) => `1px solid ${theme.other[prioString].main}`
                                : null,
                    }}
                    key={elem.title}
                >
                    {elem.icon}
                    <Box sx={{ pl: 1 }}>
                        <Typography
                            component="p"
                            variant="zep-typo--body-small"
                            sx={{ fontWeight: 700 }}
                        >
                            {elem.title}
                        </Typography>
                        <Typography component="p" variant="zep-typo--body-small">
                            {elem.text}
                        </Typography>
                    </Box>
                </Box>
            ))}
        </Box>
    );
}

EventCardDetails.propTypes = {
    data: PropTypes.object.isRequired,
    prio: PropTypes.string.isRequired,
};
