import React, { createContext, useContext } from 'react';
import TagManager from 'zepdev-react-gtm-module';

const TAG_MANAGER_ID = 'GTM-TV8V9FQ';
const AnalyticsContext = createContext();

export const AnalyticsContextProvider = (props) => {
    const initializeTagManager = () => {
        const tagManagerArgs = {
            gtmId: TAG_MANAGER_ID,
            dataLayer: {
                userProject: 'como',
            },
        };
        TagManager.initialize(tagManagerArgs);
    };

    const trackEvent = (tagManagerArgs) => {
        TagManager.dataLayer(tagManagerArgs);
    };

    initializeTagManager();

    return (
        <AnalyticsContext.Provider
            value={{
                trackEvent,
            }}
        >
            {props.children}
        </AnalyticsContext.Provider>
    );
};

export const useAnalytics = () => useContext(AnalyticsContext);
