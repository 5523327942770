import { errorMessages } from '../constants/error';
import { DTO } from './base.dto';

export class UserDTO extends DTO {
    constructor() {
        super({
            apiRoute: 'api/v1/users/me',
            fetchErrorMessage: errorMessages.fetchUser,
        });
    }
}

export class UserNotificationUpdateDTO extends DTO {
    constructor(values) {
        super({
            apiRoute: 'api/v1/users/notifications',
            fetchErrorMessage: errorMessages.updateNotifications,
            fetchInit: {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    flag: values[0],
                    type: values[1],
                    userId: values[2],
                }),
            },
        });
    }
}