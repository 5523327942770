import Layout from '../../components/layout/Layout';
import { pageTitle } from '../../routes';
import Cookie from './Cookie';

export default function CookiePage() {
    return (
        <Layout title={pageTitle.cookie}>
            <Cookie />
        </Layout>
    );
}
