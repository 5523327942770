import * as React from 'react';
import { SvgIcon } from '@mui/material';

export default function ProtectShieldIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <g
                id="icon-/-error-/-como-/-shield"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g
                    id="Group"
                    transform="translate(2.000000, 3.333028)"
                    stroke="currentColor"
                    strokeWidth="1.25"
                >
                    <path
                        d="M5.02222222,11.6006101 C5.92905098,12.8000836 7.15188082,13.7234386 8.55377778,14.2672768 L9.26488889,14.5410546 C9.73863073,14.723312 10.263147,14.723312 10.7368889,14.5410546 L11.448,14.2672768 C12.8497819,13.7232321 14.0725597,12.7999163 14.9795556,11.6006101"
                        id="Path"
                    />
                    <path
                        d="M16.5822222,7.77216566 C16.6354887,7.40622102 16.6637025,7.03706593 16.6666889,6.66727677 L16.6666889,2.17038788 C16.6692999,1.77033154 16.4373176,1.4058377 16.0737778,1.23883233 C14.1587747,0.40360088 12.0891362,-0.0183230864 10,0.000610106264 C7.91086383,-0.0183230864 5.84122531,0.40360088 3.92622222,1.23883233 C3.56268238,1.4058377 3.33070014,1.77033154 3.33331115,2.17038788 L3.33331115,6.66727677 C3.33776932,7.03716746 3.36746809,7.40632309 3.42222222,7.77216566"
                        id="Path"
                    />
                    <path
                        d="M3.38222222,9.86016566 C3.38222222,9.86016566 2.66666667,12.6672768 0,12.6672768 L2,14.0006101 L0,17.3339434 C2.07727003,17.2593791 4.02128622,16.291907 5.33333333,14.6797212"
                        id="Path"
                    />
                    <path
                        d="M16.6177778,9.86016566 C16.6177778,9.86016566 17.3288889,12.6672768 20,12.6672768 L18,14.0006101 L20,17.3339434 C17.92273,17.2593791 15.9787138,16.291907 14.6666667,14.6797212"
                        id="Path"
                    />
                    <path
                        d="M10,9.33394344 C12.0796126,9.41031708 14.0244668,10.3827442 15.3333333,12.0006101 L17.3333333,8.66727677 C17.3333333,8.66727677 15.3333333,5.33394344 10,5.33394344 C4.66666667,5.33394344 2.66666667,8.66727677 2.66666667,8.66727677 L4.66666667,12.0006101 C5.97553315,10.3827442 7.92038738,9.41031708 10,9.33394344 L10,9.33394344 Z"
                        id="Path"
                    />
                </g>
            </g>
        </SvgIcon>
    );
}
