const prioData = {
    0: {
        label: 'prio unbekannt',
        description:
            'Für diesen Fehlercode haben wir aktuell noch keine Handlungsempfehlung für Sie erstellt. Wir werden dies aufnehmen und bewerten.',
    },
    3: {
        label: 'prio 3',
        description: 'Die Maschine ist funktionsfähig und ist mit leichtem Problem im Einsatz.',
    },
    2: {
        label: 'prio 2',
        description:
            'Die Maschine kann weiterfahren aber der Zeppelin Service sollte kontaktiert werden.',
    },
    1: {
        label: 'prio 1',
        description: 'Die Maschine abstellen und Zeppelin Service kontaktieren.',
    },
};

export default prioData;
