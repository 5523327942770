import * as React from 'react';
import PropTypes from 'prop-types';
import { Popover, Typography } from '@mui/material';

import errorCodeData from '../../utils/errorCodeData';

export default function TableHeaderIcon({ variant }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Typography
                aria-owns={anchorEl ? `mouse-over-popover-${variant}` : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{ display: 'flex', alignItems: 'center' }}
            >
                {errorCodeData[variant].icon}
            </Typography>
            <Popover
                id={`mouse-over-popover-${variant}`}
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography
                    sx={{
                        p: 1,
                        color: (theme) => theme.color.global.white,
                        background: (theme) => theme.color.global.almostBlack,
                    }}
                >
                    {errorCodeData[variant].label}
                </Typography>
            </Popover>
        </>
    );
}

TableHeaderIcon.propTypes = {
    variant: PropTypes.oneOf(['possible_causes', 'possible_consequences', 'recommendations'])
        .isRequired,
};
