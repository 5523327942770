import { List, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import { useAuth } from '../../contexts/AuthContext';

export default function UserNavigation() {
    const { logout } = useAuth();
    return (
        <List disablePadding>
            <ListItemButton
                onClick={logout}
                sx={{
                    mb: 1,
                    borderRadius: 1,
                    '&:hover': {
                        background: (theme) => theme.palette.primary.main,
                    },
                }}
            >
                <ListItemText
                    primary="Ausloggen"
                    sx={{
                        '& .MuiListItemText-primary': {
                            fontSize: 14,
                        },
                    }}
                />
            </ListItemButton>
        </List>
    );
}
