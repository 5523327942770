import AppsIcon from '@mui/icons-material/Apps';
import ListIcon from '@mui/icons-material/List';
import { Box, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { pageTitle } from '../../../../routes';
import { useEvent, useMachine } from '../../contexts/DashboardDataContext';
import Layout from '../layout/Layout';
import FleetGridView from './FleetGridView';
import FleetTable from './FleetTable';

export default function FleetPage() {
    const { events } = useEvent();
    const { machines } = useMachine();
    const [view, setView] = useState(0);

    const filterEvents = (serialNumber, prio) => {
        const machineEvents = events?.filter((item) => item.serialNumber === serialNumber);
        const eventsPerPrio = machineEvents.filter(
            (item) => parseInt(item.prio, 10) === parseInt(prio, 10)
        );
        return eventsPerPrio[0] ? eventsPerPrio?.length : 0;
    };

    const data = machines.map((elem) => ({
        id: elem._key,
        prio0: filterEvents(elem.serialNumber, 0),
        prio1: filterEvents(elem.serialNumber, 1),
        prio2: filterEvents(elem.serialNumber, 2),
        prio3: filterEvents(elem.serialNumber, 3),
        ...elem,
    }));

    const isEmptyList = data.length === 0;

    return (
        <Layout pageTitle={pageTitle.app.dashboard.fuhrpark}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: 2,
                }}
            >
                <div>
                    <IconButton onClick={() => setView(0)}>
                        <ListIcon />
                    </IconButton>
                    <IconButton onClick={() => setView(1)}>
                        <AppsIcon />
                    </IconButton>
                </div>
            </Box>
            {isEmptyList ? (
                <Typography
                    variant="zep-typo--display-6"
                    component="p"
                    sx={{ width: '100%', textAlign: 'center', mt: 8 }}
                >
                    keine Maschine gefunden
                </Typography>
            ) : (
                <>
                    {view === 0 && <FleetTable data={data} />}
                    {view === 1 && <FleetGridView data={data} />}
                </>
            )}
        </Layout>
    );
}
