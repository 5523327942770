import * as React from 'react';
import {
    Box,
    Button,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet';

const Cookie = () => {
    return (
        <>
            <Helmet>
                <script type="text/javascript">
                    {`


if (window.OneTrust != null) {
OneTrust.Init();
setTimeout(function() {
OneTrust.LoadBanner();
var toggleDisplay = document.getElementsByClassName(
    "ot-sdk-show-settings"
);
for (var i = 0; i < toggleDisplay.length; i++) {
    toggleDisplay[i].onclick = function(event) {
        event.stopImmediatePropagation();
        window.OneTrust.ToggleInfoDisplay();
    };
}
}, 1000);
}

`}
                </script>
            </Helmet>
            <Container maxWidth="xl" sx={{ pt: 2, pb: 4, height: '100%' }}>
                <Typography
                    variant="zep-typo--display-6"
                    component="p"
                    sx={{ mb: 2, display: { xs: 'block', sm: 'none' } }}
                >
                    Cookie Hinweise
                </Typography>

                <Button
                    variant="contained"
                    sx={{ mb: 4 }}
                    classes={{
                        root: 'ot-sdk-show-settings',
                    }}
                >
                    Cookie-Einstellungen
                </Button>
                <Typography
                    variant="zep-typo--headline-2_alt"
                    component="p"
                    sx={{
                        maxWidth: { xs: 250, sm: 400, md: 500, lg: 600, xl: 700 },
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        pb: 1,
                        mt: 3,
                    }}
                >
                    Cookie-Liste
                </Typography>
                <Typography>
                    Ein Cookie ist ein kleines Datenpaket (Textdatei), das Ihr Browser auf Anweisung
                    einer besuchten Website auf Ihrem Gerät speichert, um sich Informationen über
                    Sie zu „merken“, wie etwa Ihre Spracheinstellungen oder Anmeldeinformationen.
                    Diese Cookies werden von uns gesetzt und als Erstanbieter-Cookies bezeichnet.
                    Wir verwenden auch Drittanbieter-Cookies, welche von einer anderen Domäne als
                    die der von Ihnen besuchten Website stammen. Wie verwenden diese Cookies zur
                    Unterstützung unserer Werbe- und Marketingmaßnahmen. Insbesondere verwenden wir
                    Cookies und andere Tracker-Technologien für die folgenden Zwecke:
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="zep-typo--headline-5_alt" sx={{ mt: 2 }}>
                        Statistik
                    </Typography>
                </Box>
                <Typography>
                    Mit diesen Cookies ist die Website in der Lage, erweiterte Funktionalität und
                    Personalisierung bereitzustellen. Sie können von uns oder von Drittanbietern
                    gesetzt werden, deren Dienste wir auf unseren Seiten verwenden. Wenn Sie diese
                    Cookies nicht zulassen, funktionieren einige oder alle dieser Dienste
                    möglicherweise nicht einwandfrei.
                </Typography>
                <Box
                    sx={{
                        mt: 2,
                        overflowX: { xs: 'scroll', sm: 'auto' },
                        flexBasis: { xs: '50%', md: '60%', lg: '70%' },
                    }}
                >
                    <TableContainer sx={{ minWidth: { xs: 600, md: '100%' } }}>
                        <Table aria-label="Statistik">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            color: (theme) => theme.color.black[60],
                                            verticalAlign: 'top',
                                            width: '33%',
                                        }}
                                    >
                                        Cookie-Untergruppe
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            color: (theme) => theme.color.black[60],
                                            verticalAlign: 'top',
                                            width: '49%',
                                        }}
                                    >
                                        Cookies
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            color: (theme) => theme.color.black[60],
                                            verticalAlign: 'top',
                                            width: '18%',
                                        }}
                                    >
                                        Verwendete Cookies
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody></TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <Typography variant="zep-typo--headline-5_alt" sx={{ mt: 2 }}>
                        Marketing
                    </Typography>
                </Box>
                <Typography>
                    Diese Cookies können über unsere Website von unseren Werbepartnern gesetzt
                    werden. Sie können von diesen Unternehmen verwendet werden, um ein Prol Ihrer
                    Interessen zu erstellen und Ihnen relevante Anzeigen auf anderen Websites zu
                    zeigen. Sie speichern nicht direkt personenbezogene Daten, basieren jedoch auf
                    einer einzigartigen Identizierung Ihres Browsers und Internet-Geräts. Wenn Sie
                    diese Cookies nicht zulassen, werden Sie weniger gezielte Werbung erleben.
                </Typography>
                <Box
                    sx={{
                        mt: 2,
                        overflowX: { xs: 'scroll', md: 'hidden' },
                        flexBasis: { xs: '50%', md: '60%', lg: '70%' },
                    }}
                >
                    <TableContainer sx={{ minWidth: { xs: 600, md: '100%' } }}>
                        <Table aria-label="Marketing">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            color: (theme) => theme.color.black[60],
                                            verticalAlign: 'top',
                                            width: '33%',
                                        }}
                                    >
                                        Cookie-Untergruppe
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            color: (theme) => theme.color.black[60],
                                            verticalAlign: 'top',
                                            width: '49%',
                                        }}
                                    >
                                        Cookies
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            color: (theme) => theme.color.black[60],
                                            verticalAlign: 'top',
                                            width: '18%',
                                        }}
                                    >
                                        Verwendete Cookies
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        height: { xs: 80, md: 'auto' },
                                        a: {
                                            textDecoration: 'underline',
                                            color: (theme) => theme.color.black[60],
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        zeppelin.com
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/_fbp"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            _fbp
                                        </a>
                                        ,{' '}
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/cdt_ga_43TNPQ5PMW"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            cdt_ga_43TNPQ5PMW
                                        </a>
                                        ,{' '}
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/cdt_ga_sst"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            cdt_ga_sst
                                        </a>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        Erstanbieter
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <Typography variant="zep-typo--headline-5_alt" sx={{ mt: 2 }}>
                        Komfort
                    </Typography>
                </Box>
                <Typography>
                    Diese Cookies ermöglichen es uns, Besuche und Verkehrsquellen zu zählen, damit
                    wir die Leistung unserer Website messen und verbessern können. Sie unterstützen
                    uns bei der Beantwortung der Fragen, welche Seiten am beliebtesten sind, welche
                    am wenigsten genutzt werden und wie sich Besucher auf der Website bewegen. Alle
                    von diesen Cookies erfassten Informationen werden aggregiert und sind deshalb
                    anonym. Wenn Sie diese Cookies nicht zulassen, können wir nicht wissen, wann Sie
                    unsere Website besucht haben.
                </Typography>
                <Box
                    sx={{
                        mt: 2,
                        overflowX: { xs: 'scroll', md: 'hidden' },
                        flexBasis: { xs: '50%', md: '60%', lg: '70%' },
                    }}
                >
                    <TableContainer sx={{ minWidth: { xs: 600, md: '100%' } }}>
                        <Table aria-label="Komfort">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            color: (theme) => theme.color.black[60],
                                            verticalAlign: 'top',
                                            width: '33%',
                                        }}
                                    >
                                        Cookie-Untergruppe
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            color: (theme) => theme.color.black[60],
                                            verticalAlign: 'top',
                                            width: '49%',
                                        }}
                                    >
                                        Cookies
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            color: (theme) => theme.color.black[60],
                                            verticalAlign: 'top',
                                            width: '18%',
                                        }}
                                    >
                                        Verwendete Cookies
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        height: { xs: 80, md: 'auto' },
                                        a: {
                                            textDecoration: 'underline',
                                            color: (theme) => theme.color.black[60],
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        www.zeppelin.com
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/GTM-optout"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            GTM-optout
                                        </a>
                                        ,{' '}
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/FPLC"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            FPLC
                                        </a>{' '}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        Erstanbieter
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        height: { xs: 80, md: 'auto' },
                                        a: {
                                            textDecoration: 'underline',
                                            color: (theme) => theme.color.black[60],
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        <a
                                            href="https://cookiepedia.co.uk/host/www.facebook.com"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            zeppelin.com
                                        </a>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/cdt_ga"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            cdt_ga
                                        </a>
                                        ,{' '}
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/_gid"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            _gid
                                        </a>
                                        ,{' '}
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/_dc_gtm_UA-xxxxxxxx"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            _dc_gtm_UA-xxxxxxxx
                                        </a>
                                        ,{' '}
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/_gat_UA-"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            _gat_UA-
                                        </a>
                                        ,{' '}
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/cdt_ga_gid"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            cdt_ga_gid
                                        </a>
                                        ,{' '}
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/_ga"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            _ga
                                        </a>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        Erstanbieter
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <Typography variant="zep-typo--headline-5_alt" sx={{ mt: 2 }}>
                        Unbedingt erforderlich
                    </Typography>
                </Box>
                <Typography>
                    Diese Cookies sind zur Funktion der Website erforderlich und können in Ihren
                    Systemen nicht deaktiviert werden. In der Regel werden diese Cookies nur als
                    Reaktion auf von Ihnen getätigte Aktionen gesetzt, die einer Dienstanforderung
                    entsprechen, wie etwa dem Festlegen Ihrer Datenschutzeinstellungen, dem Anmelden
                    oder dem Ausfüllen von Formularen. Sie können Ihren Browser so einstellen, dass
                    diese Cookies blockiert oder Sie über diese Cookies benachrichtigt werden.
                    Einige Bereiche der Website funktionieren dann aber nicht. Diese Cookies
                    speichern keine personenbezogenen Daten.
                </Typography>
                <Box
                    sx={{
                        mt: 2,
                        overflowX: { xs: 'scroll', md: 'hidden' },
                        flexBasis: { xs: '50%', md: '60%', lg: '70%' },
                    }}
                >
                    <TableContainer sx={{ minWidth: { xs: 600, md: '100%' } }}>
                        <Table aria-label="Unbedingt erforderlich">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            color: (theme) => theme.color.black[60],
                                            verticalAlign: 'top',
                                            width: '33%',
                                        }}
                                    >
                                        Cookie-Untergruppe
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            color: (theme) => theme.color.black[60],
                                            verticalAlign: 'top',
                                            width: '49%',
                                        }}
                                    >
                                        Cookies
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            color: (theme) => theme.color.black[60],
                                            verticalAlign: 'top',
                                            width: '18%',
                                        }}
                                    >
                                        Verwendete Cookies
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        height: { xs: 80, md: 'auto' },
                                        a: {
                                            textDecoration: 'underline',
                                            color: (theme) => theme.color.black[60],
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        como.zeppelin.com
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/splunkweb_uid"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            splunkweb_uid
                                        </a>{' '}
                                        ,{' '}
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/session_id_8000"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            session_id_8000
                                        </a>{' '}
                                        ,{' '}
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/splunkweb_csrf_token_8000"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            splunkweb_csrf_token_8000
                                        </a>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        Erstanbieter
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        height: { xs: 80, md: 'auto' },
                                        a: {
                                            textDecoration: 'underline',
                                            color: (theme) => theme.color.black[60],
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        como.zeppelin.com
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/AWSALB"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            AWSALB
                                        </a>
                                        ,{' '}
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/AWSALBCORS"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            AWSALBCORS
                                        </a>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        Erstanbieter
                                    </TableCell>
                                </TableRow>

                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        height: { xs: 80, md: 'auto' },
                                        a: {
                                            textDecoration: 'underline',
                                            color: (theme) => theme.color.black[60],
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        zeppelin.com
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/OptanonConsent"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            OptanonConsent
                                        </a>
                                        ,{' '}
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/eupubconsent"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            eupubconsent
                                        </a>
                                        ,{' '}
                                        <a
                                            href="https://cookiepedia.co.uk/cookies/OptanonAlertBoxClosed"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            OptanonAlertBoxClosed
                                        </a>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        Erstanbieter
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        height: { xs: 80, md: 'auto' },
                                        a: {
                                            textDecoration: 'underline',
                                            color: (theme) => theme.color.black[60],
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        <a
                                            href="https://cookiepedia.co.uk/host/cookielaw.org"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            cookielaw.org
                                        </a>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        __cfduid
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        Drittanbieter
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box sx={{ pb: '16rem' }} />
            </Container>
        </>
    );
};

export default Cookie;
