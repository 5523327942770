import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FetchError from '../common/FetchError';
import Spinner from '../common/Spinner';
import { isManager } from '../constants/role';
import { EventListDTO } from '../dto/event.dto';
import { MachineListDTO } from '../dto/machine.dto';
import { useQuery } from '../utils/api';
import { checkCoordinate } from '../utils/geography';
import { useAnalytics } from './AnalyticsContext';
import { useAuth } from './AuthContext';
import { useCustomerSelect, withCustomerSelected } from './CustomerSelectContext';

const DashboardDataContext = createContext();

const WithTrackingFetchedEventList = (props) => {
    const location = useLocation();
    const { trackEvent } = useAnalytics();
    const { user } = useAuth();
    const { events } = useContext(DashboardDataContext);

    useEffect(() => {
        const tagManagerArgs = {
            dataLayer: {
                userId: isManager(user.role) ? 'admin' : user.userId,
                userCompany: isManager(user.role) ? 'admin' : user.company,
                page_title: location.pathname.replace('/', ''),
                event: 'screen_view',
                prioNumbers: {
                    prio1: events.filter((elem) => elem.prio === '1').length,
                    prio2: events.filter((elem) => elem.prio === '2').length,
                    prio3: events.filter((elem) => elem.prio === '3').length,
                    prioUnknown: events.filter((elem) => elem.prio === '0').length,
                },
            },
        };
        trackEvent(tagManagerArgs);
    }, [trackEvent, location, user, events]);

    return <>{props.children}</>;
};

const ContextProvider = (props) => {
    const { customerId } = useCustomerSelect();
    const eventListDTO = useMemo(() => new EventListDTO(customerId), [customerId]);
    const machineListDTO = useMemo(() => new MachineListDTO(customerId), [customerId]);

    const {
        data: eventListData,
        error: fetchEventListError,
        isLoading: isLoadingEvents,
    } = useQuery(eventListDTO);

    const {
        data: machineListData,
        error: fetchMachineListError,
        isLoading: isLoadingMachines,
    } = useQuery(machineListDTO);

    const [selectedMachine, setSelectedMachine] = useState(null);

    const isLoading = isLoadingEvents || isLoadingMachines;
    const error = fetchEventListError || fetchMachineListError;

    if (isLoading) return <Spinner />;
    if (error) return <FetchError message={error.message} />;

    return (
        <DashboardDataContext.Provider
            value={{
                events: eventListData.result,
                machines: machineListData.result,
                selectedMachine,
                setSelectedMachine,
            }}
        >
            <WithTrackingFetchedEventList>{props.children}</WithTrackingFetchedEventList>
        </DashboardDataContext.Provider>
    );
};

export const DashboardDataContextProvider = withCustomerSelected(ContextProvider);

export function useEvent() {
    const { events } = useContext(DashboardDataContext);
    return { events };
}

export function useMachine() {
    const { machines, selectedMachine, setSelectedMachine } = useContext(DashboardDataContext);
    const machineListValidCoords = machines.filter((elem) => checkCoordinate(elem));

    return {
        machines,
        machineListValidCoords,
        selectedMachine,
        setSelectedMachine,
    };
}
