import { Button, Stack } from '@mui/material';
import { routeConfig } from '../../../routes';
import { FETCH_ERROR_MESSAGE } from '../constants/error';

export default function FetchError({ message = FETCH_ERROR_MESSAGE }) {
    const backHomeAndReload = () => {
        window.location.replace(routeConfig.app.index);
    }

    return (
        <Stack direction="column" spacing={2}>
            <h4 style={{ textAlign: 'center' }}>{message}</h4>
            <Button
                onClick={backHomeAndReload}
                style={{
                    margin: 'auto',
                }}
                variant="contained"
            >
                Back Home
            </Button>
        </Stack>
    );
}
