import { Navigate, Route, Routes } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import { routeConfig, toRoute } from '../../routes';
import { CustomerDetailContextProvider } from './contexts/CustomerDetailContext';
import { DashboardDataContextProvider } from './contexts/DashboardDataContext';

import NotFoundPage from '../not-found';
import OverviewPage from './components/overviewPage/OverviewPage';
import MapPage from './components/mapPage/MapPage';
import InspectionPage from './components/inspectionPage/InspectionPage';
import FleetPage from './components/fleetPage/FleetPage';
import DownloadPage from './components/downloadPage/DownloadPage';
import ReportsPage from './components/reportsPage/ReportsPage';

export default function ComoDashboard() {
    return (
        <DashboardDataContextProvider>
            <CustomerDetailContextProvider>
                <Routes>
                    <Route path="*" element={<NotFoundPage />} />
                    <Route index element={<Navigate to={routeConfig.app.dashboard.start} />} />
                    <Route
                        path={toRoute(routeConfig.app.dashboard.start)}
                        element={<OverviewPage />}
                    />
                    <Route path={toRoute(routeConfig.app.dashboard.map)} element={<MapPage />} />
                    <Route
                        path={toRoute(routeConfig.app.dashboard.inspection)}
                        element={<InspectionPage />}
                    />
                    <Route
                        path={toRoute(routeConfig.app.dashboard.fuhrpark)}
                        element={<FleetPage />}
                    />
                    <Route
                        path={toRoute(routeConfig.app.dashboard.export)}
                        element={<DownloadPage />}
                    />
                    <Route
                        path={toRoute(routeConfig.app.dashboard.reports)}
                        element={<ReportsPage />}
                    />
                </Routes>
            </CustomerDetailContextProvider>
        </DashboardDataContextProvider>
    );
}
