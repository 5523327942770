import * as React from 'react';
import { Typography, Box, ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { footerLinks, pageTitle, routeConfig } from '../../routes';

export default function Footer() {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                width: '100%',
            }}
        >
            {footerLinks.map((key, idx) => (
                <ButtonBase
                    onClick={() => navigate(routeConfig[key])}
                    key={key}
                    style={{
                        color: 'rgba(0, 0, 0, 0.84)',
                        textDecoration: 'none',
                    }}
                >
                    <Typography variant="zep-typo--body-small" component="p">
                        {pageTitle[key]}
                        <Box component="span" sx={{ pl: 1, pr: 1 }}>
                            {idx === footerLinks.length - 1 ? '' : '|'}
                        </Box>
                    </Typography>
                </ButtonBase>
            ))}
        </Box>
    );
}
