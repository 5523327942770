import { errorMessages } from '../constants/error';
import { DTO } from './base.dto';

export class OrderCreateDTO extends DTO {
    constructor(userId, order, machine) {
        super({
            apiRoute: `api/v1/orders/cart/${encodeURIComponent(userId)}`,
            fetchErrorMessage: errorMessages.postOrder,
            fetchInit: {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ order, machine }),
            },
        });
    }
}
