import { Box, Typography } from '@mui/material';
import { useCustomerSelect } from '../../contexts/CustomerSelectContext';
import { useAuth } from '../../contexts/AuthContext';
import { isUser } from '../../constants/role';

export default function PageTitle({ pageTitle }) {
    const { user } = useAuth();
    const { customerName } = useCustomerSelect();

    return (
        <Box sx={{ textAlign: 'center' }}>
            <Typography sx={{ mb: 2 }} component="h1" variant="zep-typo--display-6">
                {pageTitle} {!isUser(user.role) ? customerName : ''}
            </Typography>
        </Box>
    );
}
