export const footerLinks = ['legal', 'dataPrivacy', 'imprint', 'service', 'cookie'];

export const pageTitle = {
    legal: 'Rechtliche Hinweise',
    dataPrivacy: 'Datenschutzerklärung',
    imprint: 'Impressum',
    service: 'Service',
    cookie: 'Cookie Hinweise',
    login: 'Login',
    app: {
        customers: 'Kunden',
        errors: 'Fehler',
        machineDetail: 'Maschinendetail',
        dashboard: {
            start: 'Übersicht',
            map: 'Karte',
            inspection: 'Inspektion',
            fuhrpark: 'Fuhrpark',
            export: 'Datenexport',
            reports: 'Reports',
        },
    },
};

export const routeConfig = {
    notFound: '/404',
    legal: '/rechtliche_hinweise',
    dataPrivacy: '/datenschutzerklarung',
    imprint: '/impressum',
    service: '/service',
    cookie: '/cookie',
    login: '/login',
    app: {
        index: '/app',
        customers: '/app/customers',
        errors: '/app/errors',
        machineDetail: '/app/machines',
        dashboard: {
            index: '/app/dashboard',
            start: '/app/dashboard/start',
            map: '/app/dashboard/karte',
            inspection: '/app/dashboard/inspektion',
            fuhrpark: '/app/dashboard/fuhrpark',
            export: '/app/dashboard/datenexport',
            reports: '/app/dashboard/reports',
        },
    },
};

export const toRoute = (path) => path.split('/').at(-1);
