import { errorMessages } from '../constants/error';
import { DTO } from './base.dto';

export class BranchListDTO extends DTO {
    constructor() {
        super({
            apiRoute: `api/v1/tickets/branches`,
            fetchErrorMessage: errorMessages.fetchBranches,
        });
    }
}

export class TicketCreateDTO extends DTO {
    constructor(body) {
        super({
            apiRoute: `api/v1/tickets`,
            fetchErrorMessage: errorMessages.createTicket,
            fetchInit: {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            },
        });
    }
}