import * as React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import prioData from '../../utils/prioData';

export default function PrioTitle({ prio }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
                sx={{
                    mr: 1,
                    width: 8,
                    height: 18,
                    borderRadius: 2,
                    background: (theme) => theme.other[`prio${prio}`].main,
                }}
            />
            <Box sx={{ textTransform: 'capitalize' }}>{prioData[prio].label}</Box>
        </Box>
    );
}

PrioTitle.propTypes = {
    prio: PropTypes.oneOf(['0', '1', '2', '3']).isRequired,
};
