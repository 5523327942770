import Layout from '../../components/layout/Layout';
import { pageTitle } from '../../routes';
import DataPrivacy from './DataPrivacy';

export default function DataPrivacyPage() {
    return (
        <Layout title={pageTitle.dataPrivacy}>
            <DataPrivacy />
        </Layout>
    );
}
