import { errorMessages } from '../constants/error';
import { JWT_TOKEN } from '../constants/fetchStatus';

const BASE_URL = process.env.REACT_APP_BASE_URL || '';

const getDefaultFetchInit = () => {
    const defaultFetchInit = {
        method: 'GET',
    };
    const token = localStorage.getItem(JWT_TOKEN);
    if (token) {
        defaultFetchInit.headers = {
            Authorization: `Bearer ${token}`,
        };
    }
    return defaultFetchInit;
};

const getRequestOptions = (fetchInit) => {
    if (fetchInit == null) return getDefaultFetchInit();
    const headersWithToken = fetchInit.headers || {};
    const token = localStorage.getItem(JWT_TOKEN);
    if (token) {
        headersWithToken.Authorization = `Bearer ${token}`;
    }
    return { ...fetchInit, headers: headersWithToken };
};

export const customFetch = async ({ apiRoute, fetchErrorMessage, fetchInit }) => {
    const url = `${BASE_URL}/${apiRoute}`;
    const requestOptions = getRequestOptions(fetchInit);

    const response = await fetch(url, requestOptions);
    if (!response.ok) throw new Error(fetchErrorMessage);

    const data = await response.json();
    if (data == null) throw new Error(errorMessages.emptyData);

    return data;
};
