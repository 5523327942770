import React, { createContext, useContext, useMemo } from 'react';
import { BranchListDTO } from '../dto/ticket.dto';
import { useLazyQuery } from '../utils/api';

const BranchListContext = createContext();

export const BranchListContextProvider = (props) => {
    const branchListDTO = useMemo(() => new BranchListDTO(), []);
    const branchListResponse = useLazyQuery(branchListDTO);

    return (
        <BranchListContext.Provider value={{ branchListResponse }}>
            {props.children}
        </BranchListContext.Provider>
    );
};

export const useBranchList = () => useContext(BranchListContext);
