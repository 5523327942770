import { differenceInCalendarDays } from 'date-fns';
import formatDate from '../utils/formatDate';

// TODO: Shorten logic of this function
export const getInspectionStatus = (machine) => {
    // FIXME: Naming in the backend is not optimal, a better naming is `meterUsageToNextMaintenance` as inspection includes (maintenance and uvv), but this value is only about maintenance (german: wartung)
    const meterUsageToNextMaintenance = machine.meterUsageToNextInspection;
    const nextMaintenance = machine.nextInspection;

    const maintenanceHours = meterUsageToNextMaintenance
        ? `${meterUsageToNextMaintenance} Std`
        : '';
    const maintenanceDays = nextMaintenance
        ? ` | ${differenceInCalendarDays(new Date(nextMaintenance), new Date())} Tage`
        : !meterUsageToNextMaintenance && !nextMaintenance
        ? 'Unbekannt'
        : '';
    const maintenanceDate =
        formatDate(nextMaintenance) !== '' ? ` (${formatDate(nextMaintenance)})` : '';

    const uvvDays = machine.nextUvv
        ? `${differenceInCalendarDays(new Date(machine.nextUvv), new Date())} Tage`
        : 'Unbekannt';
    const uvvDate = formatDate(machine.nextUvv) !== '' ? ` (${formatDate(machine.nextUvv)})` : '';

    return {
        maintenance: `${maintenanceHours}${maintenanceDays}${maintenanceDate}`,
        uvv: `${uvvDays}${uvvDate}`,
    };
};
