import React, { createContext, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import FetchError from '../common/FetchError';
import Spinner from '../common/Spinner';
import {
    MachineDetailDTO,
    MachineHistoryEventListDTO,
    MachineOilReportListDTO,
} from '../dto/machine.dto';
import { useLazyQuery, useQuery } from '../utils/api';

const MachineDetailContext = createContext();

const withMachineExist = (Component) => {
    return (props) => {
        const { machineId } = useParams();
        const machineDetailDTO = useMemo(() => new MachineDetailDTO(machineId), [machineId]);
        const { data, error, isLoading } = useQuery(machineDetailDTO);

        if (isLoading) return <Spinner />;
        if (error) return <FetchError message={error.message} />;

        return (
            <Component
                machineDetail={data.result.machine}
                machineEvents={data.result.events}
                {...props}
            />
        );
    };
};

const ContextProvider = (props) => {
    const { machineId } = useParams();
    const machineHistoryEventListDTO = useMemo(
        () => new MachineHistoryEventListDTO(machineId),
        [machineId]
    );
    const machineOilReportListDTO = useMemo(
        () => new MachineOilReportListDTO(machineId),
        [machineId]
    );

    const fetchMachineEventHistoryResponse = useLazyQuery(machineHistoryEventListDTO);
    const fetchMachineOilReportsResponse = useLazyQuery(machineOilReportListDTO);
    const { machineDetail, machineEvents } = props;

    return (
        <MachineDetailContext.Provider
            value={{
                machineDetail,
                machineEvents,
                fetchMachineEventHistoryResponse,
                fetchMachineOilReportsResponse,
            }}
        >
            {props.children}
        </MachineDetailContext.Provider>
    );
};

export const MachineDetailContextProvider = withMachineExist(ContextProvider);

export const useMachineDetail = () => useContext(MachineDetailContext);
