import React, { createContext, useContext, useMemo, useState } from 'react';
import FetchError from '../common/FetchError';
import Loading from '../components/loading/Loading';
import { ErrorListDTO } from '../dto/error.dto';
import { useQuery } from '../utils/api';

const EventCodeListContext = createContext();

export const EventCodeListContextProvider = (props) => {
    const errorListDTO = useMemo(() => new ErrorListDTO(), []);
    const { data, error, isLoading, refetch } = useQuery(errorListDTO);
    const [selectedCode, setSelectedCode] = useState(null);

    if (isLoading) return <Loading height={50} />;
    if (error) return <FetchError message={error.message} />;

    const eventCodeList = data.data.map((elem) => ({ id: elem.unique_code, ...elem }));

    return (
        <EventCodeListContext.Provider
            value={{ eventCodeList, refetch, selectedCode, setSelectedCode }}
        >
            {props.children}
        </EventCodeListContext.Provider>
    );
};

export const useEventCodeList = () => useContext(EventCodeListContext);
