import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const Seo = ({ title, description }) => {
    const seoTitle = title || 'Zeppelin Condition Monitoring';
    const seoDescription =
        description ||
        'Ihr kollaboratives Arbeitswerkzeug wenn es um den Zustand Ihrer Maschinen geht';

    return (
        <>
            <Helmet>
                <html lang="de" />
                <meta name="robots" content="index, follow" />
                <title>{`COMO | ${seoTitle}`}</title>
                <meta name="description" content={seoDescription} />
                <meta
                    name="google-site-verification"
                    content="exePOknZUjRveJ7stQXhBsRkvmvLUoIS9yDnYMiI6ck"
                />
            </Helmet>
        </>
    );
};

export default Seo;

Seo.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
};
