import * as React from 'react';
import { Container, Typography } from '@mui/material';
import ReactMarkdown from 'markdown-to-jsx';
import imrpressum from './impressum.md';

export default function Impressum() {
    const [mdText, setMdText] = React.useState('');

    React.useEffect(() => {
        fetch(imrpressum)
            .then((response) => {
                if (response.ok) {
                    return response.text();
                }
                return Promise.reject(new Error("Didn't fetch text correctly"));
            })
            .then((text) => {
                setMdText(text);
            })
            .catch((error) => console.error(error));
    });

    return (
        <Container maxWidth="xl" sx={{ pt: 2, pb: 4, height: '100%' }}>
            <Typography
                variant="zep-typo--display-6"
                component="p"
                sx={{ mb: 2, display: { xs: 'block', sm: 'none' } }}
            >
                Impressum
            </Typography>
            <ReactMarkdown>{mdText}</ReactMarkdown>
        </Container>
    );
}
