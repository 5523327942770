import * as React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, FormControl, IconButton, TextField } from '@mui/material';
import VisibilityOffIcon from '@zlab-de/zel-react-icons/EssentialHide';
import View from '@zlab-de/zel-react-icons/EssentialView';

export default function FormInput({
    elem,
    value,
    error,
    label,
    notRequired,
    handleChange,
    ...others
}) {
    const [showPassword, setShowPassword] = React.useState(false);

    return (
        <FormControl fullWidth {...others}>
            <TextField
                label={label}
                placeholder={label}
                type={elem === 'password' && showPassword === false ? 'password' : 'text'}
                autoComplete={elem === 'password' ? 'off' : 'on'}
                required={!notRequired}
                id={elem}
                // Needs to be `null` to fix https://github.com/mui/material-ui/issues/14427
                value={value || null}
                onChange={handleChange(elem)}
                error={!!error}
                helperText={error || null}
                InputProps={{
                    endAdornment:
                        elem === 'password' ? (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Passwortsichtbarkeit umschalten"
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    {showPassword ? <VisibilityOffIcon /> : <View />}
                                </IconButton>
                            </InputAdornment>
                        ) : null,
                }}
                sx={{
                    '& label.Mui-focused': {
                        color: (theme) => theme.palette.primary.main,
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: (theme) => theme.palette.primary.main,
                    },
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 0,
                        mb: 2,
                        background: 'rgb(242, 244, 248)',
                        '& fieldset': {
                            borderColor: 'rgb(188, 193, 203)',
                        },
                        '&:hover fieldset': {
                            borderColor: (theme) => theme.palette.primary.main,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: (theme) => theme.palette.primary.main,
                        },
                    },
                }}
            />
        </FormControl>
    );
}

FormInput.propTypes = {
    elem: PropTypes.string.isRequired,
    value: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.string.isRequired,
    notRequired: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
};
