import { Box, Typography } from '@mui/material';
import { useMachine } from '../../contexts/DashboardDataContext';
import Layout from '../layout/Layout';
import MapLoader from '../map/MapLoader';
import MachineList from './MachineList';
import { pageTitle } from '../../../../routes';

export default function MapPage() {
    const { machines, machineListValidCoords, selectedMachine, setSelectedMachine } = useMachine();
    const isEmptyList = machines.length === 0;

    return (
        <Layout fullWidth pageTitle={pageTitle.app.dashboard.map}>
            <Box
                sx={{
                    display: 'flex',
                    height: '100%',
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        width: 400,
                        height: { xs: 'calc(100vH - 72px)', md: 'calc(100vH - 96px)' },
                        overflowY: 'scroll',
                        boxShadow: (theme) => `0 0 15px ${theme.color.black[16]}`,
                        zIndex: 2,
                    }}
                >
                    {isEmptyList ? (
                        <Typography
                            variant="zep-typo--display-6"
                            component="p"
                            sx={{ width: '100%', textAlign: 'center', mt: 8 }}
                        >
                            keine Maschinen gefunden
                        </Typography>
                    ) : (
                        <MachineList />
                    )}
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    <MapLoader
                        machines={machineListValidCoords}
                        selectedMachine={selectedMachine}
                        setSelectedMachine={setSelectedMachine}
                    />
                </Box>
            </Box>
        </Layout>
    );
}
