import { Box, Typography } from '@mui/material';
import { DataGridPro, deDE } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import * as React from 'react';

import formatDate from '../../utils/formatDate';

const columns = [
    {
        field: 'date',
        headerName: 'Datum',
        headerClassName: 'data-grid--header',
        flex: 1,
        minWidth: 150,
    },
    {
        field: 'bezeichnung',
        headerName: 'Bezeichnung',
        headerClassName: 'data-grid--header',
        flex: 1,
        minWidth: 150,
    },
    {
        field: 'Bewertung',
        headerName: 'Status',
        headerClassName: 'data-grid--header',
        flex: 1,
        minWidth: 150,
        renderCell: (params) => (
            <Typography
                sx={{
                    color: (theme) =>
                        params.value === 'grün'
                            ? theme.other.green
                            : params.value === 'gelb'
                            ? theme.other.prio2.main
                            : params.value === 'rot'
                            ? theme.other.red
                            : theme.other.prio0.main,
                }}
            >
                {params.value}
            </Typography>
        ),
    },
];

export default function OilAnalysisTable({ data, handleRowClick }) {
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const getStatus = (value) => {
        const newValue =
            value === '1' ? 'grün' : value === '2' ? 'gelb' : value === '3' ? 'rot' : 'Unbekannt';
        return newValue;
    };

    React.useEffect(() => {
        const temp = [];
        data?.forEach((elem) => {
            if (elem.baugruppeBez) {
                temp.push({
                    id: elem.baugruppeBez,
                    date: formatDate(typeof elem.datum === 'string' ? elem.datum : elem.datum[0]),
                    bezeichnung:
                        typeof elem.bezeichnung === 'string'
                            ? elem.bezeichnung
                            : elem.bezeichnung[0],
                    Bewertung: getStatus(
                        typeof elem.Bewertung === 'string' ? elem.Bewertung : elem.Bewertung[0]
                    ),
                });
            }
        });
        temp.sort((a, b) => new Date(a.date) - new Date(b.date));
        setRows(temp);
        setLoading(false);
    }, [data]);

    const handleClick = (newSelectionModel) => {
        handleRowClick(newSelectionModel[0]);
    };

    return (
        <>
            {!loading && rows?.length === 0 && (
                <Typography sx={{ m: 6, textAlign: 'center' }}>
                    keine Ölanalysen gefunden
                </Typography>
            )}
            {!loading && rows?.length > 0 && (
                <Box sx={{ pb: 16 }}>
                    <DataGridPro
                        autoHeight
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        rows={rows}
                        columns={columns}
                        onSelectionModelChange={(e, newSelectionModel) => {
                            handleClick(e, newSelectionModel);
                        }}
                        maxColumns={columns.length}
                        disableColumnMenu
                        hideFooter
                        hideFooterPagination
                        hideFooterRowCount
                        sx={{
                            '& .MuiDataGrid-cell': {
                                overflow: 'hidden',
                            },
                            '& .MuiDataGrid-row': {
                                backgroundColor: (theme) => theme.color.global.white,
                            },
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer',
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: (theme) => theme.other.gray[100],
                            },
                            '& .MuiDataGrid-cell:focus,.MuiDataGrid-columnHeader:focus': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-footerContainer': {
                                minHeight: 0,
                            },
                            '& .data-grid--header': {
                                fontFamily: '"Roboto Condensed", sans-serif',
                                letterSpacing: '1.25px',
                                textTransform: 'uppercase',
                            },
                        }}
                    />
                </Box>
            )}
        </>
    );
}

OilAnalysisTable.propTypes = {
    data: PropTypes.array,
    handleRowClick: PropTypes.func,
};
