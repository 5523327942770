import { Box, Grow, IconButton, Paper, Popper, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';
import CalendarIcon from '@zlab-de/zel-react-icons/ComoCalendar';
import deLocale from 'date-fns/locale/de';
import { useEffect, useRef, useState } from 'react';
import { useLayout } from '../../contexts/LayoutContext';

const endDate = new Date();
const startDate = new Date(new Date().setDate(endDate.getDate() - 7));

export default function OverviewDateFilter() {
    const { breakpointSm, eventListDateFilter, setEventListDateFilter } = useLayout();
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const prevOpen = useRef(open);

    useEffect(() => {
        // return focus to the button when we transitioned from !open -> open
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleChange = (newValue) => {
        setEventListDateFilter(newValue);
    };

    return (
        <>
            <IconButton
                ref={anchorRef}
                aria-controls={open ? 'date-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                aria-label="date range"
            >
                <CalendarIcon sx={{ color: (theme) => theme.color.font }} viewBox="0 0 20 20" />
            </IconButton>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: 10 }}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'center bottom',
                        }}
                    >
                        <Paper sx={{ p: 2, zIndex: 10 }}>
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={deLocale}
                                localeText={{
                                    start: 'Anfangsdatum',
                                    end: 'Enddatum',
                                    cancel: 'Abbrechen',
                                    today: 'Heute',
                                }}
                            >
                                {breakpointSm ? (
                                    <DesktopDateRangePicker
                                        disableFuture
                                        toolbarTitle=""
                                        mask="__.__.____"
                                        minDate={startDate}
                                        value={eventListDateFilter}
                                        onAccept={() => setOpen(false)}
                                        onChange={(newValue) => {
                                            handleChange(newValue);
                                        }}
                                        renderInput={(startProps, endProps) => (
                                            <>
                                                <TextField {...startProps} size="small" />
                                                <Box sx={{ mx: 2 }}> bis </Box>
                                                <TextField {...endProps} size="small" />
                                            </>
                                        )}
                                    />
                                ) : (
                                    <MobileDateRangePicker
                                        disableFuture
                                        minDate={startDate}
                                        toolbarTitle=""
                                        mask="__.__.____"
                                        value={eventListDateFilter}
                                        onAccept={() => setOpen(false)}
                                        onChange={(newValue) => {
                                            handleChange(newValue);
                                        }}
                                        renderInput={(startProps, endProps) => (
                                            <>
                                                <TextField {...startProps} size="small" />
                                                <Box sx={{ mx: 2 }}> bis </Box>
                                                <TextField {...endProps} size="small" />
                                            </>
                                        )}
                                    />
                                )}
                            </LocalizationProvider>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
