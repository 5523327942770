import * as React from 'react';
import PropTypes from 'prop-types';
import EventCard from '../eventCard/EventCard';
import TimelineElement from '../timeline/TimelineElement';

export default function HistoryTimeline({ data }) {
    const [events, setEvents] = React.useState([]);

    React.useEffect(() => {
        const uniqueCodes = [...new Set(data.map((elem) => elem.unique_code))];
        const tempData = [];
        uniqueCodes.forEach((elem) => {
            const filteredEvents = data.filter((item) => item.unique_code === elem);
            filteredEvents.sort(
                (a, b) => new Date(a._time).getTime() - new Date(b._time).getTime()
            );
            tempData.push({ data: filteredEvents, latestDate: filteredEvents[0]._time });
        });
        setEvents(tempData);
    }, [data]);

    return (
        <>
            {events.map((elem) => (
                <TimelineElement prio={elem.data[0].prio} key={elem.data[0].unique_code}>
                    <EventCard data={elem.data} prio={elem.data[0].prio} variant="history" />
                </TimelineElement>
            ))}
        </>
    );
}

HistoryTimeline.propTypes = {
    data: PropTypes.array.isRequired,
};
