import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import FormInput from './FormInput';
import validate from './ContactFormValidationRules';
import { useAnalytics } from '../../app/contexts/AnalyticsContext';

export default function FormContact({ handleFormChange }) {
    const { trackEvent } = useAnalytics();
    const [errors, setErrors] = React.useState({});
    const [firstClick, setFirstClick] = React.useState(false);

    const [values, setValues] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        customerNumber: '',
    });

    const handleChange = (prop) => (event) => {
        const newValues = { ...values };
        newValues[prop] = event.target.value;
        setValues(newValues);
        if (firstClick) {
            const errorsVal = validate(newValues);
            setErrors(errorsVal);
        }
    };

    const handleClick = () => {
        const errorsVal = validate(values);
        setErrors(errorsVal);
        setFirstClick(true);
        if (Object.keys(errorsVal).length === 0) {
            window.location.href = `mailto:como@zeppelin.com?subject=COMO%20Registrieren
                        &body=Vorname:%20${values.firstName}%0A%0ANachname:%20${values.lastName}%0A%0AEmail:%20${values.email}%0A%0AZeppelin%20Kundennummer:%20${values.customerNumber}%0A%0A`;
            const newArgs = {
                dataLayer: {
                    event: 'registration_contact',
                },
            };
            trackEvent(newArgs);
            handleFormChange();
        }
    };

    return (
        <>
            <Box
                component="form"
                noValidate
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <FormInput
                    label="Vorname"
                    elem="firstName"
                    key="input_firstName"
                    value={values.firstName}
                    error={errors.firstName}
                    handleChange={handleChange}
                />
                <FormInput
                    label="Nachname"
                    elem="lastName"
                    key="input_lastName"
                    value={values.lastName}
                    error={errors.lastName}
                    handleChange={handleChange}
                />
                <FormInput
                    label="E-Mail"
                    elem="email"
                    key="input_email"
                    value={values.email}
                    error={errors.email}
                    handleChange={handleChange}
                />
                <FormInput
                    label="Kundennummer"
                    elem="customerNumber"
                    key="input_customerNumber"
                    value={values.customerNumber}
                    error={errors.customerNumber}
                    handleChange={handleChange}
                    notRequired
                />

                <Box mb={2} mt={4} sx={{ width: '100%' }}>
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ mb: 2 }}
                        component="a"
                        disabled={Object.keys(errors).length !== 0}
                        onClick={handleClick}
                    >
                        SENDEN
                    </Button>
                </Box>

                <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ pr: 1, fontSize: 14 }}>
                        Sie haben bereits ein Konto?
                    </Typography>
                    <Button
                        onClick={handleFormChange}
                        sx={{
                            fontSize: 14,
                            letterSpacing: 0,
                            lineHeight: '1.5px',
                            background: 'transparent',
                            color: (theme) => theme.color.black[84],
                            textTransform: 'capitalize',
                            textDecoration: 'underline',
                            p: 0,
                            minWidth: 10,
                        }}
                    >
                        login
                    </Button>
                </Box>
            </Box>
        </>
    );
}

FormContact.propTypes = {
    handleFormChange: PropTypes.func.isRequired,
};
