import { GoogleMap, InfoWindow, Marker, MarkerClusterer } from '@react-google-maps/api';
import MarkerIcon from '../../assets/location.svg';
import { checkCoordinate } from '../../utils/geography';
import mapStyles from './mapStyles.json';
import MarkerPopup from './MarkerPopup';

const options = {
    imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    calculator: function CALCULATOR(markers) {
        const count = markers.length;

        const index = 2;

        return {
            text: count.toString(),
            index,
            title: '',
        };
    },
};

const DEFAULT_ZOOM = 6;
const DETAIL_ZOOM = 18;
const DEFAULT_LAT = 51.1657;
const DEFAULT_LNG = 10.4515;

const germanyCenter = { lat: DEFAULT_LAT, lng: DEFAULT_LNG };

const getPositionByMachineList = (machines) => {
    const lats = machines.map((marker) =>
        Number.isNaN(parseFloat(marker.lat)) ? DEFAULT_LAT : parseFloat(marker.lat)
    );
    const lons = machines.map((marker) =>
        Number.isNaN(parseFloat(marker.lon)) ? DEFAULT_LNG : parseFloat(marker.lon)
    );
    const avgLat = lats.length > 0 ? (Math.max(...lats) + Math.min(...lats)) / 2 : DEFAULT_LAT;
    const avgLon = lons.length > 0 ? (Math.max(...lons) + Math.min(...lons)) / 2 : DEFAULT_LNG;
    if (avgLat < 44 || avgLat > 57 || avgLon < -10 || avgLon > 25) {
        return germanyCenter;
    } else {
        return { lat: parseFloat(avgLat), lng: parseFloat(avgLon) };
    }
};

const getPositionByMachine = (machine) => {
    return {
        lat: Number.isNaN(parseFloat(machine.lat))
            ? parseFloat(DEFAULT_LAT)
            : parseFloat(machine.lat),
        lng: Number.isNaN(parseFloat(machine.lon))
            ? parseFloat(DEFAULT_LNG)
            : parseFloat(machine.lon),
    };
};

export default function Map({ machines, selectedMachine, setSelectedMachine, isDetailPage }) {
    const center = selectedMachine
        ? getPositionByMachine(selectedMachine)
        : getPositionByMachineList(machines);
    const zoom = selectedMachine ? DETAIL_ZOOM : DEFAULT_ZOOM;

    const handleClickMarker = (marker) => {
        if (marker.serialNumber === selectedMachine?.serialNumber) {
            return;
        }
        if (!isDetailPage && setSelectedMachine) {
            setSelectedMachine(marker);
        }
    };

    const closeActiveMarker = () => {
        if (!isDetailPage && setSelectedMachine) {
            setSelectedMachine(null);
        }
    };

    return (
        <GoogleMap
            onClick={closeActiveMarker}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            zoom={zoom}
            center={center}
            defaultZoom={6}
            defaultCenter={germanyCenter}
            language="de"
            region="DE"
            options={{
                styles: mapStyles,
                mapTypeControl: false,
                streetViewControl: false,
            }}
        >
            <MarkerClusterer options={options} gridSize={30}>
                {(clusterer) =>
                    machines.map((machine) => (
                        <Marker
                            key={machine.serialNumber}
                            position={getPositionByMachine(machine)}
                            onClick={() => handleClickMarker(machine)}
                            clusterer={clusterer}
                            icon={MarkerIcon}
                        >
                            {selectedMachine?.serialNumber === machine.serialNumber && (
                                <InfoWindow
                                    position={getPositionByMachine(machine)}
                                    onCloseClick={closeActiveMarker}
                                >
                                    <MarkerPopup
                                        machine={machine}
                                        isDetailPage={isDetailPage}
                                        hasInvalidCoords={
                                            !checkCoordinate({
                                                lat: machine.lat,
                                                lon: machine.lon,
                                            })
                                        }
                                    />
                                </InfoWindow>
                            )}
                        </Marker>
                    ))
                }
            </MarkerClusterer>
        </GoogleMap>
    );
}
