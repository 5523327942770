import { createTheme } from '@mui/material/styles';
import guidelines from 'zeppelin-element-library/guidelines.json';

const {
    yellow: { primary, secondary },
} = guidelines.theme;

const typography = [];
const headings = Object.keys(guidelines.typography.headings);
headings.forEach((elem) => {
    const variants = Object.keys(guidelines.typography.headings[elem]);
    variants.forEach((item) => {
        const temp = {
            props: { variant: item },

            style: {
                fontFamily: guidelines.typography.headings[elem][item]['font-family'],
                fontSize: guidelines.typography.headings[elem][item]['font-size'],
                fontWeight: guidelines.typography.headings[elem][item]['font-weight'],
                letterSpacing: guidelines.typography.headings[elem][item]['letter-spacing'],
                lineHeight: guidelines.typography.headings[elem][item]['line-height'],
                textTransform: guidelines.typography.headings[elem][item]['text-transform'],
            },
        };
        typography.push(temp);
    });
});
Object.keys(guidelines.typography.body).forEach((item) => {
    const temp = {
        props: { variant: item },
        style: {
            fontFamily: guidelines.typography.body[item]['font-family'],
            fontSize: guidelines.typography.body[item]['font-size'],
            fontWeight: guidelines.typography.body[item]['font-weight'],
            letterSpacing: guidelines.typography.body[item]['letter-spacing'],
            lineHeight: guidelines.typography.body[item]['line-height'],
            textTransform: guidelines.typography.body[item]['text-transform'],
        },
    };
    typography.push(temp);
});

const theme = createTheme({
    palette: {
        primary: {
            main: primary,
        },
        secondary: {
            main: secondary,
        },
        error: {
            main: guidelines.color.support.alert,
        },
    },
    typography: {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        lineHeight: '1.75rem',
        fontDisplay: 'swap',
    },
    logo: guidelines.logo,
    yellow: guidelines.theme.yellow,
    color: guidelines.color,
    other: {
        prio1: { light: 'rgba(255, 101, 98, 0.1)', main: '#FF0000' },
        prio2: { light: 'rgba(240, 173, 78, 0.1)', main: '#FFD500' },
        prio3: { light: 'rgba(90, 118,142, 0.1)', main: '#006DFF' },
        prio0: { light: 'rgba(153, 153, 153, 0.1)', main: '#5a768e' },
        inspection0: { light: 'rgba(175,49,49, 0.1)', main: '#af3131' },
        inspection1: { light: 'rgba(250,185,0, 0.1)', main: '#fab900' },
        inspection2: { light: 'rgba(61,83,109, 0.1)', main: '#3d536d' },
        gray: {
            100: '#f1f1f1',
            200: '#f8f8f8',
            300: '#E4E4E4',
            400: '#bdbdbd',
        },
        green: '#239438',
        red: '#fc0007',
        dkRed: '#af3131',
        dkYellow: '#fab900',
        dkBlue: '#3d536d',
    },
    space: guidelines.spacing.component,
    components: {
        MuiTypography: {
            variants: typography,
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.84)',
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.84)',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.64)',
                    fontWeight: 500,
                    fontSize: '0.875rem',
                    letterSpacing: '0.7px',
                    lineHeight: '1.125rem',
                    boxShadow: 'none',
                    textTransform: 'none',
                    padding: '6px 16px', // to accommodate the 2px border
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'transparent',
                    '&:hover': {
                        borderWidth: '2px',
                        boxShadow: 'none',
                        color: 'rgba(0, 0, 0, 0.84)',
                    },
                },
                contained: {
                    '&:hover': {
                        backgroundColor: primary,
                    },
                },
                containedPrimary: {
                    borderColor: primary,
                },
                outlinedPrimary: {
                    borderColor: primary,
                },
                outlined: {
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.84)',
                    '$icon &': {
                        outlineOffset: 1,
                    },
                },
                colorSecondary: {
                    '&$checked': {
                        backgroundImage: 'linear-gradient(180deg,green,hsla(0,0%,100%,0))',
                        outline: '1px auto rgba(19,124,189,.6)',
                        color: primary,
                    },
                    '$icon &': {
                        outline: '1px auto rgba(19,124,189,.6)',
                        outlineOffset: 1,
                    },
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    height: 2,
                },
                thumb: {
                    width: 16,
                    height: 16,
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                arrow: {
                    color: '#000',
                },
                tooltip: {
                    backgroundColor: '#000',
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.4)',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                standardSuccess: {
                    background: 'white',
                    borderLeft: '4px solid #239438',
                    color: 'rgba(0, 0, 0, 0.84)',
                },
                standardError: {
                    background: 'white',
                    borderLeft: '4px solid #fc0007',
                    color: 'rgba(0, 0, 0, 0.84)',
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                icon: { color: 'rgba(0, 0, 0, 0.64)', fontSize: '1rem' },
            },
        },
    },
});

export default theme;
