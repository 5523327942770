import * as React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Element } from 'react-scroll';

import EventsTable from './EventsTable';
import PrioHeader from '../prio/PrioHeader';

export default function EventSection({ data }) {
    if (data.length === 0) {
        return null;
    }

    const numMachines = [...new Set(data.map((item) => item.serialNumber))].length;

    return (
        <Box
            sx={{
                mb: { xs: 2, md: 4 },
                '& .sticky': {
                    width: '100%',
                    position: 'sticky',
                    zIndex: 3,
                    top: { xs: '50px', md: '184px', lg: '216px' },
                    background: (theme) => theme.other.gray[200],
                },
            }}
        >
            <Element name={data[0].prio}>
                <Box className="sticky">
                    <PrioHeader
                        prio={data[0].prio}
                        numMachines={numMachines}
                        numEvents={data.length}
                    />
                </Box>
                <EventsTable data={data} />
            </Element>
        </Box>
    );
}

EventSection.propTypes = {
    data: PropTypes.array.isRequired,
};
