import React, { createContext, useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { routeConfig } from '../../../routes';
import { isManager, isUser } from '../constants/role';
import { useAuth } from './AuthContext';
const CustomerSelectContext = createContext();

export const CustomerSelectContextProvider = (props) => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [selectedCustomerName, setSelectedCustomerName] = useState(null);
    const [selectedCustomerCompany, setSelectedCustomerCompany] = useState(null);

    const customerId = isUser(user.role) ? user.userId : selectedCustomerId;
    const customerName = isUser(user.role) ? user.realname : selectedCustomerName;
    const customerCompany = isUser(user.role) ? user.company : selectedCustomerCompany;

    const navigateWithCustomerId = (pathname) => {
        navigate({
            pathname,
            search: isManager(user.role) ? `?customerId=${customerId}` : null,
        });
    };

    return (
        <CustomerSelectContext.Provider
            value={{
                customerId,
                setSelectedCustomerId,
                customerName,
                setSelectedCustomerName,
                customerCompany,
                setSelectedCustomerCompany,
                navigateWithCustomerId,
            }}
        >
            {props.children}
        </CustomerSelectContext.Provider>
    );
};

export const useCustomerSelect = () => useContext(CustomerSelectContext);

// This is a HOC that make sure that the customer is selected before rendering the component
// If the customer is not selected, it will redirect to the customer list page
export const withCustomerSelected = (Component) => {
    return (props) => {
        const { customerId } = useCustomerSelect();
        if (!customerId) return <Navigate to={routeConfig.app.customers} />;
        return <Component {...props} />;
    };
};

// This is a hook that will clear the selected customer
export const useClearSelectedCustomer = () => {
    const { setSelectedCustomerId, setSelectedCustomerName } = useCustomerSelect();

    useEffect(() => {
        setSelectedCustomerId(null);
        setSelectedCustomerName(null);
    }, [setSelectedCustomerId, setSelectedCustomerName]);
};
