import {DataGridPro, deDE} from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import * as React from 'react';
import {routeConfig} from '../../../../routes';
import {useAnalytics} from '../../contexts/AnalyticsContext';
import {useCustomerSelect} from '../../contexts/CustomerSelectContext';
import TableHeaderIcon from './TableHeaderIcon';
import {generateRandom} from '../../utils/generateRandom';

export default function FleetTable({ data }) {
    const { trackEvent } = useAnalytics();
    const { navigateWithCustomerId } = useCustomerSelect();

    const [colWidth, setColWidth] = React.useState(null);
    const [pageSize, setPageSize] = React.useState(10);
    const [rows, setRows] = React.useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    React.useEffect(() => {
        if (data) {
            setRows(data);
            const lengths = data.map((elem) => elem.model?.length? elem.model?.length : 8);
            const maxLength = Math.max(...lengths);
            setColWidth(maxLength);
        }
    }, [data]);

    const handleSelect = (value) => {
        setSelectionModel(value);
        const serialNumber = value[0];
        const rowData = rows.filter((e) => e.serialNumber === serialNumber);
        navigateWithCustomerId(`${routeConfig.app.machineDetail}/${serialNumber}`);
        const tagManagerArgs = {
            dataLayer: {
                prioGroupDetails: {
                    prio1: rowData[0].prio1,
                    prio2: rowData[0].prio2,
                    prio3: rowData[0].prio3,
                    unknown: rowData[0].prio0,
                },
                machineName: `${rowData[0].make}-${rowData[0].model}`,
                serialNumber,
                area: 'Fuhrpark',
                event: 'prio_item_click',
            },
        };
        trackEvent(tagManagerArgs);
    };

    const columns = [
        {
            field: 'prio1',
            renderHeader: () => <TableHeaderIcon variant={1} />,
            renderCell: (params) => (params.value === 0 ? '-' : params.value),
            headerClassName: 'data-grid--header',
            align: 'center',
            headerAlign: 'center',
            width: 100,
        },
        {
            field: 'prio2',
            renderHeader: () => <TableHeaderIcon variant={2} />,
            renderCell: (params) => (params.value === 0 ? '-' : params.value),
            headerClassName: 'data-grid--header',
            align: 'center',
            headerAlign: 'center',
            width: 100,
        },
        {
            field: 'prio3',
            renderHeader: () => <TableHeaderIcon variant={3} />,
            renderCell: (params) => (params.value === 0 ? '-' : params.value),
            headerClassName: 'data-grid--header',
            align: 'center',
            headerAlign: 'center',
            width: 100,
        },
        {
            field: 'prio0',
            renderHeader: () => <TableHeaderIcon variant={0} />,
            renderCell: (params) => (params.value === 0 ? '-' : params.value),
            headerClassName: 'data-grid--header',
            align: 'center',
            headerAlign: 'center',
            width: 100,
        },
        {
            field: 'serialNumber',
            headerName: 'sernummr',
            headerClassName: 'data-grid--header',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'make',
            headerName: 'Marke',
            headerClassName: 'data-grid--header',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'model',
            headerName: 'modell',
            headerClassName: 'data-grid--header',
            flex: 1,
            minWidth: colWidth > 11 ? colWidth * 13 : 150,
        },
    ];

    return (
        <DataGridPro
            autoHeight
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            columns={columns}
            maxColumns={columns.length}
            onSelectionModelChange={(newSelectionModel) => {
                handleSelect(newSelectionModel);
            }}
            selectionModel={selectionModel}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 50, 100]}
            pagination
            disableColumnPinning
            disableColumnReorder
            disableColumnResize
            disableColumnSelector
            disableMultipleColumnsFiltering
            getRowId={(row) => row.id? row.id : row.serialNumber? row.serialNumber : generateRandom()}
            sx={{
                background: (theme) => theme.color.global.white,
                '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: (theme) => theme.other.gray[200],
                },
                '& .MuiDataGrid-cell': {
                    overflow: 'hidden',
                },
                '& .MuiDataGrid-cell:focus,.MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                },
                '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                },
                '& .data-grid--header': {
                    fontFamily: '"Roboto Condensed", sans-serif',
                    letterSpacing: '1.25px',
                    textTransform: 'uppercase',
                },
            }}
        />
    );
}

FleetTable.propTypes = {
    data: PropTypes.array.isRequired,
};
