import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(
    '44b74119c5e6c8adf7fdc2fc47b83ce7T1JERVI6Mjc5MzAsRVhQSVJZPTE2NTk3NzM2MzkwMDAsS0VZVkVSU0lPTj0x'
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
