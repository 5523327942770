import React, { createContext, useCallback, useContext, useState } from 'react';

const AlertContext = createContext();

const initialState = {
    open: false,
    message: '',
    severity: 'error',
};

export const AlertContextProvider = (props) => {
    const [alertState, setAlertState] = useState(initialState);

    const openAlert = useCallback(({ message, severity }) => {
        setAlertState({
            open: true,
            message,
            severity,
        });
    }, []);

    const closeAlert = useCallback(() => {
        setAlertState(initialState);
    }, []);

    return (
        <AlertContext.Provider value={{ alertState, openAlert, closeAlert }}>
            {props.children}
        </AlertContext.Provider>
    );
};

export const useAlert = () => useContext(AlertContext);
