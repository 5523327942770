import * as React from 'react';
import PropTypes from 'prop-types';
import { TextField, IconButton } from '@mui/material';
import SearchIcon from '@zlab-de/zel-react-icons/EssentialSearch';
import CloseIcon from '@zlab-de/zel-react-icons/EssentialCloseMini';

export default function CustomerTableSearchbar({ value, handleChange, handleClearSearch }) {
    return (
        <TextField
            variant="standard"
            fullWidth
            value={value}
            onChange={handleChange}
            placeholder="Nach Kundenname, Kundennummer oder Ansprechpartner suchen"
            InputProps={{
                startAdornment: <SearchIcon />,
                endAdornment: (
                    <IconButton
                        title="Clear"
                        aria-label="Clear"
                        size="small"
                        style={{ visibility: value ? 'visible' : 'hidden' }}
                        onClick={handleClearSearch}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                ),
            }}
            sx={{
                '& .MuiInput-input': {
                    p: '9px 16px',
                },
                '& .MuiSvgIcon-root': {
                    m: 1,
                },
                '& .MuiInput-underline:before': {
                    borderBottom: 1,
                    borderColor: 'divider',
                },
            }}
        />
    );
}

CustomerTableSearchbar.propTypes = {
    value: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleClearSearch: PropTypes.func.isRequired,
};
