import { Box, Button, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import FormInput from './FormInput';
import validate from './LoginFormValidationRules';

import { errorMessages } from '../../app/constants/error';
import { JWT_TOKEN } from '../../app/constants/fetchStatus';
import { useAlert } from '../../app/contexts/AlertContext';
import { useAnalytics } from '../../app/contexts/AnalyticsContext';
import { UserLoginDTO } from '../../app/dto/auth.dto';

const BASE_URL = process.env.REACT_APP_BASE_URL || '';

async function login(username, password) {
    const userLoginDTO = new UserLoginDTO(username, password);

    try {
        const response = await fetch(
            `${BASE_URL}/${userLoginDTO.apiRoute}`,
            userLoginDTO.fetchInit
        );
        if (response.status === 200) {
            const data = await response.json();
            localStorage.setItem(JWT_TOKEN, data.access_token);
            window.location.replace('/');
            return;
        }

        if (response.status === 401) {
            return errorMessages['login.invalidCredentials'];
        }

        console.error(response);
        return errorMessages['login.unexpectedStatus'](response.status);
    } catch (error) {
        console.error(error);
        return errorMessages['login.unknownError'];
    }
}

export default function FormLogin({ handleFormChange, authInfo }) {
    const { openAlert } = useAlert();
    const { trackEvent } = useAnalytics();
    const [clientErrors, setClientErrors] = React.useState({});
    const [loading, setLoading] = React.useState(false);

    const [values, setValues] = React.useState({
        email: '',
        password: '',
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleServerError = (reason) => {
        openAlert({
            message: reason,
            severity: 'error',
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errorsVal = validate(values);
        setClientErrors(errorsVal);
        if (Object.keys(errorsVal).length === 0) {
            // no client side errors - do login
            const tagManagerArgs = {
                dataLayer: {
                    event: 'login',
                },
            };
            trackEvent(tagManagerArgs);
            setLoading(true);
            const error = await login(values.email, values.password);
            if (error) {
                handleServerError(error);
                setLoading(false);
            }
        }
    };

    const isShowAdditionalLogin = !!authInfo.login_link_url && !!authInfo.login_link_label;
    const isKupoMode = authInfo.auth_mode === 'kupo';
    const loginBtnTitle = isKupoMode ? 'LOG IN' : 'LOG IN ALS MITARBEITER';
    const inputPlaceholder = isKupoMode
        ? {
              email: 'Benutzername',
              password: 'Kennwort',
          }
        : {
              email: 'Zeppelin Kürzel',
              password: 'Windows Passwort',
          };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <FormInput
                label={inputPlaceholder.email}
                elem="email"
                key="input_email"
                value={values.email}
                error={clientErrors.email}
                handleChange={handleChange}
            />
            <FormInput
                label={inputPlaceholder.password}
                elem="password"
                key="input_password"
                value={values.password}
                error={clientErrors.password}
                handleChange={handleChange}
            />
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
                {isKupoMode && (
                    <Button
                        href="https://kundenportal.zeppelin.com/web/guest/home?p_p_id=com_liferay_login_web_portlet_LoginPortlet&p_p_lifecycle=0&p_p_state=maximized&p_p_mode=view&_com_liferay_login_web_portlet_LoginPortlet_mvcRenderCommandName=%2Flogin%2Fforgot_password"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                            fontSize: 12,
                            letterSpacing: 0,
                            lineHeight: '1.5px',
                            background: 'transparent',
                            color: (theme) => theme.color.black[84],
                            p: 0,
                        }}
                    >
                        Kennwort vergessen?
                    </Button>
                )}
            </Box>

            <Box mb={2} mt={4} sx={{ width: '100%', textAlign: 'center' }}>
                {loading ? (
                    <CircularProgress sx={{ '& svg': { width: 'auto' } }} />
                ) : (
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        id="login"
                    >
                        {loginBtnTitle}
                    </Button>
                )}
            </Box>

            {isKupoMode && (
                <Box sx={{ display: 'flex', mb: 1 }}>
                    <Typography sx={{ pr: 1, fontSize: 14 }}>Kein Account?</Typography>
                    <Button
                        onClick={handleFormChange}
                        sx={{
                            fontSize: 14,
                            letterSpacing: 0,
                            lineHeight: '1.5px',
                            background: 'transparent',
                            color: (theme) => theme.color.black[84],
                            textTransform: 'capitalize',
                            textDecoration: 'underline',
                            p: 0,
                            minWidth: 10,
                        }}
                    >
                        Kontaktiere uns
                    </Button>
                </Box>
            )}

            {isShowAdditionalLogin && (
                <Box mt={2} sx={{ width: '100%', textAlign: 'center' }}>
                    <Button
                        href={authInfo.login_link_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        fullWidth
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ mb: 2 }}
                    >
                        {authInfo.login_link_label}
                    </Button>
                </Box>
            )}
        </Box>
    );
}

FormLogin.propTypes = {
    handleFormChange: PropTypes.func.isRequired,
};
