import * as React from 'react';
import { Box } from '@mui/material';
import { Timeline } from '@mui/lab';
import ErrorIcon from '@mui/icons-material/Error';
import { Element } from 'react-scroll';

import InspectionCard from './InspectionCard';
import TimelineDate from '../timeline/TimelineDate';
import TimelineElement from '../timeline/TimelineElement';

export default function InspectionTimeline({ data }) {
    const noMaintenanceTitle = 'Zukünftige Inspektionen (≥ 26 Tage)';
    const titles = [
        'Überfällige Inspektionen',
        'Bevorstehende Inspektionen (≤ 25 Tage)',
        noMaintenanceTitle
    ];

    const colors = ['dkRed', 'dkYellow', 'dkBlue'];

    return (
        <>
            {[0, 1, 2].map((elem) => (
                <Box
                    key={elem}
                    sx={{
                        mb: { xs: 2, md: 4 },
                        '& .sticky': {
                            width: '100%',
                            position: 'sticky',
                            zIndex: 3,
                            top: { xs: '112px', sm: '120px', md: '200px', lg: '216px' },
                            background: (theme) => theme.other.gray[200],
                        },
                    }}
                >
                    {data[elem].length > 0 && (
                        <Element name={`inspection${elem}`}>
                            <Timeline sx={{ p: 0, m: 0 }}>
                                <Box className="sticky">
                                    <TimelineDate
                                        icon={
                                            <ErrorIcon
                                                sx={{
                                                    color: (theme) =>
                                                        theme.other[`inspection${elem}`].main,
                                                }}
                                            />
                                        }
                                    >
                                        {titles[elem]}
                                    </TimelineDate>
                                </Box>
                                {data[elem].map((item) => (
                                    <TimelineElement
                                        customColor={colors[elem]}
                                        key={`${item.variant}${item.serialNumber}`}
                                    >
                                        <InspectionCard machine={item} is_overdue={titles[elem] !== noMaintenanceTitle}/>
                                    </TimelineElement>
                                ))}
                            </Timeline>
                        </Element>
                    )}
                </Box>
            ))}
        </>
    );
}
