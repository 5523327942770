import * as React from 'react';
import { SvgIcon } from '@mui/material';

export default function UnknownIcon(props) {
    return (
        <SvgIcon {...props} titleAccess="error" viewBox="0 0 44 44">
            <g
                id="icon-/-alert-/-large-/-unknown"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <path
                    d="M21.9827735,1.98275862 C10.9465924,1.98275862 2,10.9370636 2,21.9827586 C2,33.0284536 10.9465924,41.9827586 21.9827735,41.9827586 C33.0189545,41.9827586 41.9655469,33.0284536 41.9655469,21.9827586 C41.9536158,10.9420137 33.0140087,1.99470003 21.9827735,1.98275862 Z M21.9827735,33.6494253 C20.6032508,33.6494253 19.4849268,32.5301372 19.4849268,31.1494253 C19.4849268,29.7687134 20.6032508,28.6494253 21.9827735,28.6494253 C23.3622961,28.6494253 24.4806202,29.7687134 24.4806202,31.1494253 C24.4806202,32.5301372 23.3622961,33.6494253 21.9827735,33.6494253 Z M24.6471433,23.516092 C24.0400198,23.7812907 23.6476834,24.3814284 23.6480046,25.0444253 C23.6480046,25.9648999 22.9024552,26.711092 21.9827735,26.711092 C21.0630917,26.711092 20.3175423,25.9648999 20.3175423,25.0444253 C20.3172413,23.0560394 21.4941423,21.2564463 23.3149584,20.461092 C24.8396744,19.7950774 25.6395024,18.1051278 25.1885308,16.5024369 C24.7375593,14.899746 23.1741208,13.8759287 21.5262242,14.1041763 C19.8783277,14.332424 18.6514543,15.7427231 18.6523112,17.4077586 C18.6523112,18.3282332 17.9067619,19.0744253 16.9870801,19.0744253 C16.0673983,19.0744253 15.321849,18.3282332 15.321849,17.4077586 C15.321849,14.0781092 17.7763482,11.2589606 21.0718753,10.8036227 C24.3674025,10.3482848 27.4932954,12.3963923 28.3945901,15.6015182 C29.2958848,18.806644 27.6961889,22.1858989 24.6471433,23.5177586 L24.6471433,23.516092 Z"
                    id="Shape-Copy"
                    fill="currentColor"
                    fillRule="nonzero"
                />
            </g>
        </SvgIcon>
    );
}
