import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AnalyticsContextProvider } from './pages/app/contexts/AnalyticsContext';
import { ThemeProvider } from '@mui/material/styles';
import { routeConfig } from './routes';
import theme from './theme';
import './fonts.css';
import './App.css';

import ComoApp from './pages/app';
import LoginPage from './pages/login';
import CookiePage from './pages/cookie';
import DataPrivacyPage from './pages/data-privacy';
import ImprintPage from './pages/imprint';
import ServicePage from './pages/service';
import TermsConditionsPage from './pages/term-condition';
import NotFoundPage from './pages/not-found';
import { AlertContextProvider } from './pages/app/contexts/AlertContext';

export default function App() {
    return (
        <AlertContextProvider>
            <AnalyticsContextProvider>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="*" element={<NotFoundPage />} />
                            <Route index element={<Navigate to={routeConfig.app.index} />} />
                            <Route path={`${routeConfig.app.index}/*`} element={<ComoApp />} />
                            <Route path={routeConfig.login} element={<LoginPage />} />
                            <Route path={routeConfig.legal} element={<TermsConditionsPage />} />
                            <Route path={routeConfig.dataPrivacy} element={<DataPrivacyPage />} />
                            <Route path={routeConfig.imprint} element={<ImprintPage />} />
                            <Route path={routeConfig.service} element={<ServicePage />} />
                            <Route path={routeConfig.cookie} element={<CookiePage />} />
                            <Route path={routeConfig.notFound} element={<NotFoundPage />} />
                        </Routes>
                    </BrowserRouter>
                </ThemeProvider>
            </AnalyticsContextProvider>
        </AlertContextProvider>
    );
}
