import * as React from 'react';
import { SvgIcon } from '@mui/material';

export default function SuccessCircleIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <g
                id="icon-/-alert-/-circle-/-success"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <path
                    d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M15.6205465,7.91072007 C15.2491839,7.65075421 14.7373911,7.74105909 14.4774252,8.11242167 L14.4774252,8.11242167 L10.2125889,14.2032809 L9.24007991,12.9059455 L9.18256175,12.8372912 C8.90014713,12.5350526 8.42941389,12.4879821 8.09095627,12.7418583 C7.72832311,13.0138686 7.65485876,13.5283488 7.92686906,13.890982 L7.92686906,13.890982 L9.04723636,15.3846105 L9.1213896,15.4756095 C9.19897397,15.5634877 9.28668107,15.6418087 9.38297504,15.7088174 C10.0459155,16.1702824 10.9575703,16.0067892 11.4190701,15.3437988 L11.4190701,15.3437988 L15.8222481,9.05384134 L15.8695915,8.97781264 C16.0689242,8.61535895 15.9671516,8.15335488 15.6205465,7.91072007 Z"
                    id="Combined-Shape"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
