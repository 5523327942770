import * as React from 'react';
import { Checkbox, Box, FormControlLabel } from '@mui/material';

export default function ReportCheckbox({ checked, label, onChange, id }) {
    const handleChange = (event) => {
        onChange(event.target.checked, id);
    };

    return (
        <Box sx={{ ml: 2 }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': label }}
                    />
                }
                label={label}
            />
        </Box>
    );
}
