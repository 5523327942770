import * as React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { Box, Pagination } from '@mui/material';

import MachineCard from './MachineCard';
import { useLayout } from '../../contexts/LayoutContext';

function FlexBox() {
    return (
        <Box
            sx={{
                flexBasis: { xs: '100%', md: '48%', lg: '30%', xl: '23%' },
            }}
        />
    );
}

export default function FleetGridView({ data }) {
    const { breakpointMd, breakpointLg, breakpointXl } = useLayout();
    const [page, setPage] = React.useState(1);

    const rowsPerPage = breakpointXl ? 16 : breakpointLg ? 9 : 8;

    const addOne = breakpointXl
        ? (data?.length / 4).toString().split('.')[1] === '75'
        : breakpointMd
        ? data?.length % 2 !== 0
        : false;

    const addTwo = breakpointXl
        ? (data?.length / 4).toString().split('.')[1] === '5'
        : breakpointLg
        ? (data?.length / 3).toString().split('.')[1]?.includes('3')
        : false;

    const addThree = breakpointLg ? (data?.length / 4).toString().split('.')[1] === '25' : false;

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <>
                    {data?.length > 0 &&
                        data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((elem) => (
                                <Box
                                    key={elem._key}
                                    sx={{
                                        flexBasis: {
                                            xs: '100%',
                                            md: '48%',
                                            lg: '30%',
                                            xl: '23%',
                                        },
                                    }}
                                >
                                    <LazyLoad>
                                        <MachineCard machine={elem} />
                                    </LazyLoad>
                                </Box>
                            ))}
                    {addOne ? (
                        <FlexBox />
                    ) : addTwo ? (
                        <>
                            <FlexBox />
                            <FlexBox />
                        </>
                    ) : addThree ? (
                        <>
                            <FlexBox />
                            <FlexBox />
                            <FlexBox />
                        </>
                    ) : (
                        <></>
                    )}
                </>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination
                    count={!data ? 0 : Math.floor(data?.length / rowsPerPage)}
                    shape="rounded"
                    size="large"
                    page={page}
                    onChange={handleChange}
                    sx={{
                        '& .MuiPaginationItem-root.Mui-selected': {
                            background: (theme) => theme.other.black,
                            color: (theme) => theme.other.white,
                        },
                    }}
                />
            </Box>
        </>
    );
}

FleetGridView.propTypes = {
    data: PropTypes.array.isRequired,
};
