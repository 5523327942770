import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ReactMarkdown from 'markdown-to-jsx';
import dataPrivacy1 from './datenschutzerklarung_part1.md';
import dataPrivacy2 from './datenschutzerklarung_part2.md';
import dataPrivacy3 from './datenschutzerklarung_part3.md';
import dataPrivacy4 from './datenschutzerklarung_part4.md';

export default function DataPrivacy() {
    const [mdText, setMdText] = React.useState('');
    const [mdText2, setMdText2] = React.useState('');
    const [mdText3, setMdText3] = React.useState('');
    const [mdText4, setMdText4] = React.useState('');

    React.useEffect(() => {
        fetch(dataPrivacy1)
            .then((response) => {
                if (response.ok) return response.text();
                return Promise.reject(new Error("Didn't fetch text correctly"));
            })
            .then((text) => {
                setMdText(text);
            })
            .catch((error) => console.error(error));
        fetch(dataPrivacy2)
            .then((response) => {
                if (response.ok) return response.text();
                return Promise.reject(new Error("Didn't fetch text correctly"));
            })
            .then((text) => {
                setMdText2(text);
            })
            .catch((error) => console.error(error));
        fetch(dataPrivacy3)
            .then((response) => {
                if (response.ok) return response.text();
                return Promise.reject(new Error("Didn't fetch text correctly"));
            })
            .then((text) => {
                setMdText3(text);
            })
            .catch((error) => console.error(error));
        fetch(dataPrivacy4)
            .then((response) => {
                if (response.ok) return response.text();
                return Promise.reject(new Error("Didn't fetch text correctly"));
            })
            .then((text) => {
                setMdText4(text);
            })
            .catch((error) => console.error(error));
    });

    return (
        <Container maxWidth="xl" sx={{ pt: 2, pb: 4 }}>
            <Typography
                variant="zep-typo--display-6"
                component="p"
                sx={{ mb: 2, display: { xs: 'block', sm: 'none' } }}
            >
                Datenschutzerklärung
            </Typography>
            <ReactMarkdown children={mdText} />
            <ReactMarkdown children={mdText2} />
            <ReactMarkdown children={mdText3} />
            <ReactMarkdown children={mdText4} />
        </Container>
    );
}
