import * as React from 'react';
import PropTypes from 'prop-types';
import deLocale from 'date-fns/locale/de';
import { Box, Grow, IconButton, Paper, Popper, TextField } from '@mui/material';
import CalendarIcon from '@zlab-de/zel-react-icons/ComoCalendar';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { FILTER_END_DATE, FILTER_START_DATE } from '../../constants/inspection';
import { useLayout } from '../../contexts/LayoutContext';

export default function InspectionDateFilter({ onDateChange, dateFilter }) {
    const { breakpointSm: desktop } = useLayout();
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <IconButton
                ref={anchorRef}
                aria-controls={open ? 'date-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                aria-label="date range"
            >
                <CalendarIcon sx={{ color: (theme) => theme.color.font }} />
            </IconButton>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: 2 }}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'center bottom',
                        }}
                    >
                        <Paper sx={{ p: 2 }}>
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={deLocale}
                                localeText={{
                                    start: 'Anfangsdatum',
                                    end: 'Enddatum',
                                    cancel: 'Abbrechen',
                                    today: 'Heute',
                                }}
                            >
                                {desktop ? (
                                    <DesktopDateRangePicker
                                        toolbarTitle=""
                                        minDate={FILTER_START_DATE}
                                        maxDate={FILTER_END_DATE}
                                        mask="__.__.____"
                                        value={dateFilter}
                                        onAccept={() => setOpen(false)}
                                        onChange={onDateChange}
                                        renderInput={(startProps, endProps) => (
                                            <>
                                                <TextField {...startProps} size="small" />
                                                <Box sx={{ mx: 2 }}> bis </Box>
                                                <TextField {...endProps} size="small" />
                                            </>
                                        )}
                                    />
                                ) : (
                                    <MobileDateRangePicker
                                        toolbarTitle=""
                                        minDate={FILTER_START_DATE}
                                        maxDate={FILTER_END_DATE}
                                        mask="__.__.____"
                                        value={dateFilter}
                                        onAccept={() => setOpen(false)}
                                        onChange={onDateChange}
                                        renderInput={(startProps, endProps) => (
                                            <>
                                                <TextField {...startProps} size="small" />
                                                <Box sx={{ mx: 2 }}> bis </Box>
                                                <TextField {...endProps} size="small" />
                                            </>
                                        )}
                                    />
                                )}
                            </LocalizationProvider>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

InspectionDateFilter.propTypes = {
    onDateChange: PropTypes.func.isRequired,
    dateFilter: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
};
