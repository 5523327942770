const ROLE = {
    ADMIN: 'admin',
    DEVELOPER: 'developer',
    USER: 'user',
};

export const isAdmin = (role) => [ROLE.ADMIN].includes(role);
export const isDeveloper = (role) => [ROLE.DEVELOPER].includes(role);
export const isManager = (role) => [ROLE.ADMIN, ROLE.DEVELOPER].includes(role);
export const isUser = (role) => [ROLE.USER].includes(role);
export const isUserOrAdmin = (role) => [ROLE.USER, ROLE.ADMIN].includes(role);
