import { Box } from '@mui/material';
import { differenceInCalendarDays } from 'date-fns';
import React, { useState } from 'react';
import { pageTitle } from '../../../../routes';
import {
    ALL,
    FILTER_END_DATE,
    FILTER_START_DATE,
    MAINTENANCE,
    STATUS_UNKNOWN,
    UNKNOWN_WITH_METER_USAGE_DURATION,
    UPCOMING_DATE_END,
    UPCOMING_DATE_START,
    UVV,
} from '../../constants/inspection';
import { useMachine } from '../../contexts/DashboardDataContext';
import { useLayout } from '../../contexts/LayoutContext';
import Layout from '../layout/Layout';
import InspectionDateFilter from './InspectionDateFilter';
import InspectionFilter from './InspectionFilter';
import InspectionSummaryCard from './InspectionSummaryCard';
import InspectionTimeline from './InspectionTimeline';

export default function InspectionPage() {
    const { sidebarOpen } = useLayout();
    const { machines } = useMachine();

    const [currentFilter, setCurrentFilter] = useState(ALL);
    const [dateFilter, setDateFilter] = useState([FILTER_START_DATE, FILTER_END_DATE]);

    const calculateUVVDuration = (machine) => {
        if (machine.nextUvv == null) return STATUS_UNKNOWN;
        return differenceInCalendarDays(new Date(machine.nextUvv), new Date());
    };

    const calculateMaintenanceDuration = (machine) => {
        if (
            // return 0 day if machine has no next inspection but having meterUsageToNextInspection
            machine.nextInspection == null &&
            machine.meterUsageToNextInspection != null
        ) {
            return UNKNOWN_WITH_METER_USAGE_DURATION;
        }

        if (machine.nextInspection == null) return STATUS_UNKNOWN;
        return differenceInCalendarDays(new Date(machine.nextInspection), new Date());
    };

    const checkMachineInDateRange = (machine, filterType) => {
        const nextDay = machine[filterType === UVV ? 'nextUvv' : 'nextInspection'];
        return new Date(nextDay) > dateFilter[0] && new Date(nextDay) < dateFilter[1];
    };

    const overdueFilterCallback = (filterType) => (machine) => {
        const duration = machine[filterType];

        if (
            duration === UNKNOWN_WITH_METER_USAGE_DURATION &&
            machine.meterUsageToNextInspection <= 0
        ) {
            return true; // show machines with negative meter usage in overdue list
        }

        if (duration !== UNKNOWN_WITH_METER_USAGE_DURATION && duration === STATUS_UNKNOWN) {
            return true; // show unknown machines in overdue list
        }

        return duration <= UPCOMING_DATE_START && checkMachineInDateRange(machine, filterType);
    };

    const upcomingFilterCallback = (filterType) => (machine) => {
        const duration = machine[filterType];

        if (
            duration === UNKNOWN_WITH_METER_USAGE_DURATION &&
            machine.meterUsageToNextInspection > 0
        ) {
            return true; // show machines with positive meter usage in upcoming list
        }

        return (
            duration !== STATUS_UNKNOWN &&
            duration > UPCOMING_DATE_START &&
            duration <= UPCOMING_DATE_END &&
            checkMachineInDateRange(machine, filterType)
        );
    };

    const futureFilterCallback = (filterType) => (machine) => {
        const duration = machine[filterType];
        return duration > UPCOMING_DATE_END && checkMachineInDateRange(machine, filterType);
    };

    const getDetailMachines = () => {
        return machines.map((machine) => {
            const detailMachine = { ...machine };
            detailMachine[UVV] = calculateUVVDuration(machine);
            detailMachine[MAINTENANCE] = calculateMaintenanceDuration(machine);
            return detailMachine;
        });
    };

    const filter = (callback) => {
        const detailMachines = getDetailMachines();

        if (currentFilter !== ALL) {
            const filteredList = detailMachines.filter(callback(currentFilter));
            return filteredList.map((machine) => ({
                ...machine,
                days: machine[currentFilter],
                variant: currentFilter,
            }));
        }

        const uvvMachines = detailMachines.filter(callback(UVV)).map((machine) => ({
            ...machine,
            days: calculateUVVDuration(machine),
            variant: UVV,
        }));

        const maintenanceMachines = detailMachines.filter(callback(MAINTENANCE)).map((machine) => ({
            ...machine,
            days: calculateMaintenanceDuration(machine),
            variant: MAINTENANCE,
        }));

        const combinedList = [...uvvMachines, ...maintenanceMachines];
        return combinedList;
    };

    const sort = (machineList) => {
        return machineList.sort((a, b) => {
            if (a.days === STATUS_UNKNOWN && b.days === STATUS_UNKNOWN) return 0;
            if (a.days === STATUS_UNKNOWN) return 1;
            if (b.days === STATUS_UNKNOWN) return -1;
            return a.days - b.days;
        });
    };

    const getData = () => {
        const overdueMachines = sort(filter(overdueFilterCallback)).map((machine) => ({
            ...machine,
            isOverdue: true,
        }));
        const upcomingMachines = sort(filter(upcomingFilterCallback));
        const futureMachines = sort(filter(futureFilterCallback));

        return [overdueMachines, upcomingMachines, futureMachines];
    };

    // event handler
    const handleFilterChange = (newValue) => setCurrentFilter(newValue);
    const handleDateChange = (newDate) => setDateFilter(newDate);

    const data = getData();

    return (
        <Layout pageTitle={pageTitle.app.dashboard.inspection}>
            <Box
                sx={{
                    position: 'fixed',
                    top: { xs: 72, md: 96 },
                    background: (theme) => theme.other.gray[200],
                    zIndex: 4,
                    width: {
                        xs: 'calc(100vW - 32px)',
                        sm: sidebarOpen ? 'calc(100vW - 298px)' : 'calc(100vW - 112px)',
                        lg: sidebarOpen
                            ? 'min(1200px, 100vW - 298px)'
                            : 'min(1152px, 100vW - 112px)',
                        xl: sidebarOpen
                            ? 'min(1200px, 100vW - 298px)'
                            : 'min(1200px, 100vW - 112px)',
                    },
                    pt: { xs: 2, md: 4, lg: 6 },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: { xs: 'wrap', md: 'nowrap' },
                        pb: 1,
                    }}
                >
                    {['0', '1', '2'].map((elem) => (
                        <InspectionSummaryCard data={data[elem]} key={elem} id={elem} />
                    ))}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', position: 'sticky' }}>
                    <InspectionDateFilter onDateChange={handleDateChange} dateFilter={dateFilter} />
                    <InspectionFilter onChange={handleFilterChange} />
                </Box>
            </Box>

            <Box sx={{ mt: { xs: '112px', sm: '120px', md: '200px', lg: '216px' } }}>
                <InspectionTimeline data={data} />
            </Box>
        </Layout>
    );
}
