import { Box, Button, Container } from '@mui/material';
import CloseIcon from '@zlab-de/zel-react-icons/EssentialClose';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo/como_brand_black.png';

export default function DetailPageHeader() {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    return (
        <Box
            sx={{
                background: (theme) => theme.palette.primary.main,
            }}
        >
            <Container
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    background: (theme) => theme.palette.primary.main,
                }}
            >
                <Button onClick={goBack}>
                    <Box component="img" src={logo} alt="logo" sx={{ width: 75 }} />
                </Button>
                <Button
                    sx={{
                        textTransform: 'capitalize',
                        fontSize: 14,
                        fontWeight: 400,
                        letterSpacing: 0,
                        color: (theme) => theme.color.font,
                    }}
                    startIcon={
                        <CloseIcon
                            style={{
                                color: (theme) => theme.color.font,
                                width: 14,
                                height: 14,
                            }}
                        />
                    }
                    onClick={goBack}
                >
                    schließen
                </Button>
            </Container>
        </Box>
    );
}
