import { Timeline } from '@mui/lab';
import { Box, Divider, IconButton } from '@mui/material';
import CheckIcon from '@zlab-de/zel-react-icons/EssentialCheckSmall';
import SortIcon from '@zlab-de/zel-react-icons/EssentialSort';
import { Fragment, useEffect } from 'react';
import { useCallback, useState } from 'react';
import { useMachineDetail } from '../../contexts/MachineDetailContext';
import formatDate from '../../utils/formatDate';
import TimelineDate from '../timeline/TimelineDate';
import DateFilter from './DateFilter';
import EventFilter from './EventFilter';
import HistoryTimeline from './HistoryTimeline';
import Spinner from '../../common/Spinner';
import FetchError from '../../common/FetchError';

const startDate = new Date(new Date().setMonth(new Date().getMonth() - 6));
const endDate = new Date();

// TODO: no need to create a hook, refactor later
const useEventFilter = (events, sort, dateFilter, prioFilter) => {
    const [filteredEvents, setFilteredEvents] = useState([]);

    const groupByMonth = (eventList) => {
        const uniqueMonths = [
            ...new Set(eventList.map((elem) => formatDate(elem._time, 'MMMM yyyy'))),
        ];
        const grouped = [];
        uniqueMonths.forEach((month) => {
            const monthValues = eventList.filter(
                (item) => formatDate(item._time, 'MMMM yyyy') === month
            );
            grouped.push({ data: monthValues, date: month });
        });
        return grouped;
    };

    const filterAndSort = useCallback(
        (eventList) => {
            const filteredByDate = [...(eventList || [])].filter(
                (elem) =>
                    new Date(elem._time) > dateFilter[0] && new Date(elem._time) < dateFilter[1]
            );
            const filteredByPrio =
                prioFilter === 'all'
                    ? filteredByDate
                    : filteredByDate.filter((elem) => elem.prio === prioFilter);
            return filteredByPrio.sort(
                sort === 'up'
                    ? (a, b) => new Date(a._time) - new Date(b._time)
                    : (a, b) => new Date(b._time) - new Date(a._time)
            );
        },
        [dateFilter, prioFilter, sort]
    );

    useEffect(() => {
        setFilteredEvents(groupByMonth(filterAndSort(events)));
    }, [events, sort, dateFilter, prioFilter, filterAndSort]);

    return [filteredEvents];
};

const HistoryTimeLine = ({ eventsHistory, sort, dateFilter, prioFilter }) => {
    const [filteredEvents] = useEventFilter(eventsHistory, sort, dateFilter, prioFilter);

    return (
        <Timeline sx={{ flex: 0, pt: 0, pl: 0, pr: 0, pb: '70vH' }}>
            {filteredEvents.map((elem) => (
                <Fragment key={elem.date}>
                    <TimelineDate
                        variant="outlined"
                        icon={<CheckIcon sx={{ color: (theme) => theme.other.gray[400] }} />}
                    >
                        {elem.date}
                    </TimelineDate>
                    <HistoryTimeline data={elem.data} />
                </Fragment>
            ))}
        </Timeline>
    );
};

function HistorySection({ machineEventHistory }) {
    const [sort, setSort] = useState('down');
    const [dateFilter, setDateFilter] = useState([startDate, endDate]);
    const [prioFilter, setPrioFilter] = useState('all');

    const handleSort = () => {
        setSort(sort === 'up' ? 'down' : 'up');
    };

    const handleFilterChange = (newValue) => {
        setPrioFilter(newValue);
    };

    const handleDateChange = (newDate) => {
        setDateFilter(newDate);
    };

    return (
        <>
            <Box maxWidth="xl" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <IconButton onClick={handleSort}>
                    <SortIcon style={{ width: 24 }} />
                </IconButton>
                <Box sx={{ display: 'flex', zIndex: 2000 }}>
                    <DateFilter onChange={handleDateChange} />
                    <EventFilter onChange={handleFilterChange} />
                </Box>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Box>
                <HistoryTimeLine
                    dateFilter={dateFilter}
                    prioFilter={prioFilter}
                    sort={sort}
                    eventsHistory={machineEventHistory}
                />
            </Box>
        </>
    );
}

export default function HistorySectionWithData() {
    const { fetchMachineEventHistoryResponse } = useMachineDetail();
    const [fetchData, { data, error, isLoading }] = fetchMachineEventHistoryResponse;

    useEffect(() => {
        if (!data) fetchData();
    }, [fetchData, data]);

    if (isLoading) return <Spinner />;
    if (error) return <FetchError message={error.message} />;
    if (!data) return null;

    return <HistorySection machineEventHistory={data.result.events} />;
}
