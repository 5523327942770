import { Box, Container, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { pageTitle } from '../../../../routes';
import backgroundImg from '../../assets/industrie-pdp-background.jpeg';
import {
    MachineDetailContextProvider,
    useMachineDetail
} from '../../contexts/MachineDetailContext';
import HistorySection from '../history/HistorySection';
import Seo from '../../../../components/layout/Seo';
import MapLoader from '../map/MapLoader';
import OilAnalysis from '../oilAnalysis/OilAnalysis';
import PrioSection from '../prio/PrioSection';
import DetailPageEventsSection from './DetailPageEventsSection';
import DetailPageHeader from './DetailPageHeader';
import MachineOverview from './MachineOverview';
import Alerts from '../layout/Alerts';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const getAggregatedEvents = (events) => {
    const uniqueCodes = [...new Set(events.map((elem) => elem.unique_code))];
    const tempData = [];
    uniqueCodes.forEach((elem) => {
        const filteredEvents = events.filter((item) => item.unique_code === elem);
        tempData.push({ data: filteredEvents, prio: filteredEvents[0].prio });
    });
    return tempData;
};

function MachineDetail() {
    const { machineDetail, machineEvents } = useMachineDetail();
    const [selectedTab, setSelectedTab] = useState(0);

    const aggregatedEvents = getAggregatedEvents(machineEvents);
    const { serialNumber } = machineDetail;

    const handleTabChange = (_event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <Seo title={`${pageTitle.app.machineDetail} ${serialNumber}`} />
            <Alerts />
            <DetailPageHeader />
            <Box
                sx={{
                    pb: 1,
                    backgroundImage: `url(${backgroundImg})`,
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Container>
                    <MachineOverview />
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        aria-label="detail page tabs"
                        variant="scrollable"
                        sx={{
                            borderBottom: (theme) => `1px solid ${theme.other.gray[100]}`,
                            zIndex: 200,
                            position: 'sticky',
                            top: 0,
                            background: '#fff',
                        }}
                    >
                        <Tab label="Event-/Diagnosecodes" {...a11yProps(0)} />
                        <Tab label="Historie" {...a11yProps(1)} />
                        <Tab label="Karte" {...a11yProps(2)} />
                        <Tab label="Ölanalysen" {...a11yProps(3)} />
                    </Tabs>
                    <TabPanel value={selectedTab} index={0}>
                        <Box sx={{ my: 4 }}>
                            <PrioSection data={aggregatedEvents} variant="small" />
                        </Box>
                        <DetailPageEventsSection data={aggregatedEvents} />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <HistorySection />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                        <Box sx={{ height: '100vH', width: '100%' }}>
                            <MapLoader
                                machines={machineDetail ? [machineDetail] : []}
                                selectedMachine={machineDetail}
                                isDetailPage
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={3}>
                        <OilAnalysis />
                    </TabPanel>
                </Container>
            </Box>
        </>
    );
}

export default function MachineDetailPage() {
    return (
        <MachineDetailContextProvider>
            <MachineDetail />
        </MachineDetailContextProvider>
    );
}
